import {DesktopDatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Autocomplete, Box, Button, Grid, Paper, TextField, Typography,} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams} from 'react-router-dom';
import Constants, {ONLY_BLANK_SPACES, ROUTE_ACTIONS,} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorRequest, getVendorsForOptionsRequest,} from '../../../Vendors/VendorApiActions';
import {addSurveyRequest, getSurveyRequest, updateSurveyRequest,} from '../../SurveyApiActions';

/**
 * function to render event form
 * @param {*} history: history object
 * @param addSurvey
 * @param getSurvey
 * @param updateSurvey
 * @param getVendorsForOptions
 * @param getVendor
 * @returns
 */
const SurveyForm = ({
                      history,
                      addSurvey,
                      getSurvey,
                      updateSurvey,
                      getVendorsForOptions,
                      getVendor,
                    }) => {
  const {surveyId, vendorId} = useParams();

  const [surveyInfo, setSurveyInfo] = useState({
    name: '',
    vendor: null,
    startDate: null,
    endDate: null,
  });

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [vendorList, setVendorList] = useState(null);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    getVendorsForOptionList().then(r => {});

  }, []);

  const getVendorDetails = async (id) => {
    const {data} = await getVendor(id);
    setSurveyInfo({
      ...surveyInfo,
      vendor: data,
    });
  };

  useEffect(() => {
    if (vendorId) {
      getVendorDetails(vendorId);
    }

  }, [vendorId]);

  const getSurveyDetails = async (surveyId) => {
    const {data} = await getSurvey(surveyId);

    setSurveyInfo({
      name: data.name,
      vendor: data.Vendor,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    });
  };

  useEffect(() => {
    if (surveyId) {
      getSurveyDetails(surveyId).then(r => {});
    }


  }, [surveyId]);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, surveyInfo);
    data[event.target.name] = event.target.value;
    setSurveyInfo(data);
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, surveyInfo);
    data[name] = value;
    setSurveyInfo(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, surveyInfo);
    data[elementName] = selectedvalue;
    setSurveyInfo(data);
  };

  /**
   * function to handle validation
   */
  const handleValidation = () => {
    if (surveyInfo.startDate === null) {
      setStartDateError(I18n.t('error_messages.field_required'));
    }
    if (surveyInfo.endDate === null) {
      setEndDateError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form submit
   */
  const onSubmit = async () => {
    handleValidation();

    if (
      surveyInfo.startDate !== null &&
      surveyInfo.endDate !== null &&
      startDateError === '' &&
      endDateError === ''
    ) {
      const data = {
        ...surveyInfo,
        startDate:
          typeof surveyInfo?.startDate === 'string'
            ? surveyInfo?.startDate
            : moment(surveyInfo?.startDate).format('YYYY-MM-DD'),
        endDate:
          typeof surveyInfo?.endDate === 'string'
            ? surveyInfo?.endDate
            : moment(surveyInfo?.endDate).format('YYYY-MM-DD'),
        VendorId: surveyInfo.vendor?.id ? surveyInfo.vendor?.id : '',
      };

      delete data['vendor'];

      if (surveyId) {
        await updateSurvey(surveyId, UtilHelper.trimObject(data));
        history.goBack();
      } else {
        const res = await addSurvey(UtilHelper.trimObject(data));

        if (vendorId) {
          history.push(`${ROUTE_ACTIONS.VENDORS}/${vendorId}/surveys/${res.data.id}`);
        } else {
          history.push(`${ROUTE_ACTIONS.SURVEYS}/${res.data.id}`);
        }
      }
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleValidation();
  };

  return (
    <Paper component={Box} p={3}>
      <Box marginBottom="1rem">
        <Typography variant="h6">
          {surveyId
            ? I18n.t('survey.survey_form.form_title_update_label')
            : I18n.t('survey.survey_form.form_title_add_label')}
        </Typography>
      </Box>

      <Box>
        <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant="standard"
                fullWidth
                required
                size="small"
                label={I18n.t('survey.survey_form.form_field_name_label')}
                onChange={handleChangeInput}
                helperText={`${surveyInfo.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`}
                name="name"
                value={surveyInfo.name}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                  Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                  `matchRegexp:${Constants.REGEX.LETTERS_SPACE_NUMBERS}`,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                  I18n.t('error_messages.only_letters_numbers'),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                disabled={!!vendorId}
                size="small"
                name="vendor"
                required
                getOptionLabel={(option) => (option ? option.name : '')}
                options={vendorList ? vendorList : []}
                onChange={(e, value) =>
                  handleChangeAutocomplete('vendor', value)
                }
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                value={surveyInfo.vendor}
                renderInput={(params) => {
                  return (
                    <TextValidator
                      required
                      variant="standard"
                      name="vendor"
                      {...params}
                      fullWidth
                      label={I18n.t(
                        'survey.survey_form.form_field_vendor_label'
                      )}
                      value={surveyInfo.vendor}
                      validators={['required']}
                      errorMessages={[I18n.t('error_messages.field_required')]}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableCloseOnSelect
                  label={I18n.t(
                    'survey.survey_form.form_field_start_date_label'
                  )}
                  minDate={new Date()}
                  maxDate={surveyInfo.endDate || null}
                  inputFormat="dd/MM/yyyy"
                  value={surveyInfo.startDate}
                  onChange={(date) =>
                    handleDateTimeInputChange('startDate', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      fullWidth
                      required
                      {...params}
                      helperText={startDateError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setStartDateError(I18n.t('error_messages.invalid_date'));
                    }

                    if (reason === 'minDate') {
                      setStartDateError(I18n.t('error_messages.past_date'));
                    }

                    if (reason === null) {
                      setStartDateError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableCloseOnSelect
                  label={I18n.t('survey.survey_form.form_field_end_date_label')}
                  minDate={surveyInfo.startDate || new Date()}
                  inputFormat="dd/MM/yyyy"
                  value={surveyInfo.endDate}
                  onChange={(date) =>
                    handleDateTimeInputChange('endDate', date)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      fullWidth
                      required
                      {...params}
                      helperText={endDateError}
                      FormHelperTextProps={{
                        error: true,
                      }}
                    />
                  )}
                  onError={(reason, value) => {
                    if (reason === 'invalidDate') {
                      setEndDateError(I18n.t('error_messages.invalid_date'));
                    }

                    if (reason === 'minDate') {
                      setEndDateError('Can not be before start date');
                    }

                    if (reason === null) {
                      setEndDateError('');
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => history.goBack()}
                >
                  {I18n.t('global.button_labels.cancel')}
                </Button>
                <Box ml={1}>
                  <Button type="submit" color="secondary" variant="contained">
                    {I18n.t('global.button_labels.save')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Paper>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    addSurvey: async (data) => {
      try {
        return await dispatch(addSurveyRequest(data));
      } catch (error) {
        throw error;
      }
    },

    getVendor: async (id) => {
      try {
        return await dispatch(getVendorRequest(id));
      } catch (error) {
        throw error;
      }
    },

    getSurvey: async (id) => {
      try {
        return await dispatch(getSurveyRequest(id));
      } catch (error) {
        throw error;
      }
    },

    updateSurvey: async (id, data) => {
      try {
        return await dispatch(updateSurveyRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyForm);
