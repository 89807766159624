import {Box, Button, Icon, Paper, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {CustomPagination, MessageModal} from '../../../../utils/components';
import {InputFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import {
  activateDeactivateSpiritRequest,
  getCuisineForOptionsRequest,
  getSpiritConstantsRequest,
  getSpiritsRequest,
} from '../../SpiritsApiActions';
import {SpiritCard, SpiritForm} from '../index';
import {useStyles} from './styles';

/**
 * function to render whiskey tube list
 * @param {*} getSpirits: function to get whiskey tubes
 * @param getSpiritConstants
 * @param getCuisineForOptions
 * @param activateDeactivateSpirit
 * @returns
 */
const SpiritList = ({
                      getSpirits,
                      getSpiritConstants,
                      getCuisineForOptions,
                      activateDeactivateSpirit,
                    }) => {
  const classes = useStyles();
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      name: '',
      cost: null,
    },
  });
  const [spiritList, setSpiritList] = useState(null);
  const [showSpiritForm, setShowSpiritForm] = useState(false);
  const [selectedSpirit, setSelectedSpirit] = useState(null);
  const modalRef = useRef();
  const [refreshList, setRefreshList] = useState(false);

  const [recommendedWeatherList, setRecommendedWeatherList] = useState(null);
  const [recommendedTimingList, setRecommendedTimingList] = useState(null);
  const [recommendedForMoodList, setRecommendedForMoodList] = useState(null);
  const [recommendedCuisineList, setRecommendedCuisineList] = useState(null);

  const getDataForList = async () => {
    const resSpiritConstants = await getSpiritConstants();
    const resCuisineList = await getCuisineForOptions();
    setRecommendedWeatherList(resSpiritConstants.weather);
    setRecommendedTimingList(resSpiritConstants.timing);
    setRecommendedForMoodList(resSpiritConstants.mood);
    setRecommendedCuisineList(resCuisineList);
  };

  useEffect(() => {
    getDataForList().then(r => {});

  }, []);

  /**
   * function to get whiskey tube list
   * @param {*} requestData: request data
   */
  const getSpiritList = async (requestData) => {
    try {
      const {data} = await getSpirits(requestData);
      setSpiritList(JSON.parse(JSON.stringify(data)));
    } catch (error) {}
  };

  useEffect(() => {
    getSpiritList(paginationParams).then(r => {});

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getSpiritList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle video form open
   */
  const handleSpiritFormOpen = () => {
    setShowSpiritForm(true);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle edit click
   * @param {*} id: id
   */
  const handleCardEditButtonClick = (id) => {
    setShowSpiritForm(true);
    const clickedSpiritData = spiritList?.rows.find(
      (spirit) => spirit.id === id
    );
    setSelectedSpirit(clickedSpiritData);
  };

  /**
   * function to handle activate / deactivate a user
   * @param spiritId
   * @param {*} enabled: active status boolean
   * @param {*} name: user name
   */
  const onActivateDeactivate = (spiritId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} Spirit`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          enabled: !enabled,
        };
        await activateDeactivateSpirit(spiritId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Box>
        <Box
          component={Paper}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          color="#fff"
          p={1.5}
          pt={1}
        >
          <Box display="flex" alignItems="flex-end">
            <Box width="220px" mr={2}>
              <InputFilter
                filterName=""
                placeholder={I18n.t('spirit.spirit_list.filter_name_label')}
                onChangeFilter={(value) => handleChangeFilter('name', value)}
              />
            </Box>

            <Box>
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={handleSpiritFormOpen}
            >
              {I18n.t('spirit.spirit_list.add_spirit_label')}
            </Button>
          </Box>
        </Box>

        <Box>
          {spiritList?.rows.length === 0 && (
            <Typography variant="subtitle1" className={classes.noDataAvailable}>
              No data available
            </Typography>
          )}

          {spiritList?.rows.map((spirit, index) => (
            <Box key={index}>
              <SpiritCard
                spirit={spirit}
                handleCardEditButtonClick={(id) =>
                  handleCardEditButtonClick(id)
                }
                onActivateDeactivate={onActivateDeactivate}
                recommendedWeatherList={recommendedWeatherList}
                recommendedTimingList={recommendedTimingList}
                recommendedForMoodList={recommendedForMoodList}
                recommendedCuisineList={recommendedCuisineList}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <CustomPagination
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          count={spiritList?.count}
          rowsPerPage={paginationParams.rowsPerPage}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </Box>

      {showSpiritForm && (
        <SpiritForm
          open={showSpiritForm}
          setOpen={setShowSpiritForm}
          selectedSpirit={selectedSpirit}
          setSelectedSpirit={setSelectedSpirit}
          setRefreshList={setRefreshList}
        />
      )}

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSpiritConstants: async () => {
      try {
        return await dispatch(getSpiritConstantsRequest());
      } catch (error) {
        throw error;
      }
    },

    getCuisineForOptions: async () => {
      try {
        return await dispatch(getCuisineForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getSpirits: async (data) => {
      try {
        return await dispatch(getSpiritsRequest(data));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateSpirit: async (id, data) => {
      try {
        return await dispatch(activateDeactivateSpiritRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiritList);
