import {Box, Button, Grid, Icon, Paper, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {CustomPagination, MessageModal} from '../../../../utils/components';
import {DateRangeFilter, InputFilter, MultiselectFilter, RadioButtonFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsForOptionsRequest} from '../../../Vendors/VendorApiActions';
import {cancelOrderRequest, getOrdersRequest} from '../../OrderApiActions';
import {ExportForm, OrderCard, OrderStatusForm} from '../index';
import {useStyles} from './styles';

/**
 * function to render order list
 * @param {*} getVendorsForOptions: function to get vendor list for options
 * @param {*} getOrders: function to get orders
 * @param {*} cancelOrder: function to cancel order
 * @param isVendor: is vendor logged in
 * @returns
 */
const OrderList = ({getVendorsForOptions, getOrders, cancelOrder, isVendor = false, loggedInUser}) => {
  const classes = useStyles();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      orderNo: '',
      isPaymentMadeToVendor: '',
      orderDate: null,
      orderStatus: [],
      vendors: loggedInUser?.userRoles?.isSystemSuperAdmin ? [] : [loggedInUser.vendorId],
    },
  });
  const [vendorList, setVendorList] = useState(null);
  const [orderList, setOrderList] = useState(null);
  const [showOrderStatusForm, setShowOrderStatusForm] = useState(false);
  const [showExportForm, setShowExportForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const modalRef = useRef();
  const [refreshList, setRefreshList] = useState(false);
  const [clickedProductList, setClickedProductList] = useState('');
  const [formSection, setFormSection] = useState('');

  /**
   * function to get vendor option list
   */
  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };

  useEffect(() => {
    if (!isVendor) {
      getVendorsForOptionList().then(r => {
      });
    }
  }, []);

  /**
   * function to get order list
   * @param {*} requestData: request data
   */
  const getOrderList = async (requestData) => {
    try {
      const {data} = await getOrders(requestData);
      setOrderList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getOrderList(paginationParams).then(r => {});

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getOrderList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to handle edit click
   * @param {*} id: id
   * @param {*} section: section
   */
  const handleCardEditButtonClick = (id, section) => {
    setFormSection(section);

    setShowOrderStatusForm(true);
    const clickedVideoData = orderList?.rows?.find(
      (video) => video.id === id
    );
    setSelectedOrder(clickedVideoData);
  };

  const handleProductListClick = (id) => {
    setClickedProductList(id);
  };

  /**
   * function to handle archive or unarchive
   * @param {*} orderId: order Id
   * @param {*} orderNo: order no
   */
  const onCancelOrder = (orderId, orderNo) => {
    const title = `Cancel Order`;
    const message = `Are you sure you want to cancel order no ${orderNo} `;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async (note) => {
        await cancelOrder(orderId);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <Box width="220px" mr={1}>
                <InputFilter
                  minimumSearchCharacter={1}
                  filterName=""
                  placeholder={I18n.t('orders.order_list.filter.order_no_label')}
                  onChangeFilter={(value) =>
                    handleChangeDebounceFilter('orderNo', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t('orders.order_list.filter.order_date_label')}
                title={I18n.t('orders.order_list.filter.order_date_label')}
                value={paginationParams.searchData.orderDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('orderDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            {!isVendor && (
              <Grid item classes={{item: classes.customGridItem}}>
                <MultiselectFilter
                    arrayList={vendorList ? vendorList : []}
                    filterName={I18n.t('orders.order_list.filter.vendor_label')}
                    title={I18n.t('orders.order_list.filter.vendor_label')}
                    filterOn="name"
                    saveButtonText={I18n.t('global.button_labels.apply')}
                    value={paginationParams.searchData.vendors}
                    onChangeFilter={(value) => handleChangeFilter('vendors', value)}
                    clearButtonText={I18n.t('global.button_labels.clear')}
                />
              </Grid>
            )}

            <Grid item classes={{item: classes.customGridItem}}>
              <MultiselectFilter
                arrayList={Constants.ORDER_STATUS_OPTIONS}
                filterName={I18n.t('orders.order_list.filter.order_status_label')}
                title={I18n.t('orders.order_list.filter.order_status_label')}
                filterOn="name"
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.orderStatus}
                onChangeFilter={(value) =>
                  handleChangeFilter('orderStatus', value)
                }
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <RadioButtonFilter
                arrayList={
                  Constants.PAYMENT_TO_VENDOR_STATUS_OPTIONS_FOR_FILTER
                }
                filterName={I18n.t('orders.order_list.filter.vendor_payment_made_label')}
                title={I18n.t('orders.order_list.filter.vendor_payment_made_label')}
                value={paginationParams.searchData.isPaymentMadeToVendor}
                onChangeFilter={(value) =>
                  handleChangeFilter('isPaymentMadeToVendor', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>get_app</Icon>}
              color="secondary"
              onClick={() => setShowExportForm(true)}
            >
              {I18n.t('global.button_labels.export')}
            </Button>
          </Box>
        </Box>

        <Box>
          {orderList?.rows.length === 0 && (
            <Typography variant="subtitle1" className={classes.noDataAvailable}>
              No data available
            </Typography>
          )}

          {orderList?.rows?.map((order, index) => (
            <Box key={order?.id}>
              <OrderCard
                order={order}
                handleCardEditButtonClick={(id, section) =>
                  handleCardEditButtonClick(id, section)
                }
                onCancelOrder={onCancelOrder}
                handleProductListClick={handleProductListClick}
                clickedProductList={clickedProductList}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <CustomPagination
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          count={orderList?.count}
          rowsPerPage={paginationParams.rowsPerPage}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams, {
              page,
            });
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams, {
              page: 0,
              rowsPerPage: rowsPerPage,
            });
            setPaginationParams(pagination);
          }}
        />
      </Box>

      {showOrderStatusForm && (
        <OrderStatusForm
          formSection={formSection}
          open={showOrderStatusForm}
          setOpen={setShowOrderStatusForm}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setRefreshList={setRefreshList}
        />
      )}

      {showExportForm && (
        <ExportForm open={showExportForm} setOpen={setShowExportForm}/>
      )}

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getOrders: async (data) => {
      try {
        return await dispatch(getOrdersRequest(data));
      } catch (error) {
        throw error;
      }
    },

    cancelOrder: async (id) => {
      try {
        return await dispatch(cancelOrderRequest(id));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
