import {
  Box,
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableContainer,
  Typography,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {AvatarCell, CustomTable, MessageModal,} from '../../../../utils/components';
import {InputFilter} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getCuisinesRequest} from '../../CuisinesApiActions';
import {CuisineForm} from '../index';

/**
 * function to render cuisines list
 * @param {*} getCuisines: function to get cuisines
 * @returns
 */
const CuisineList = ({getCuisines}) => {
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      name: '',
    },
  });
  const [cuisineList, setCuisineList] = useState(null);
  const [showCuisineForm, setShowCuisineForm] = useState(false);
  const [selectedCuisine, setSelectedCuisine] = useState(null);
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  /**
   * function to get super admin list
   * @param {*} requestData: request data
   */
  const getCuisineList = async (requestData) => {
    try {
      const {data} = await getCuisines(requestData);
      setCuisineList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getCuisineList(paginationParams).then(r => {});

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getCuisineList(paginationParams).then(r => {});
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * handle edit menu item click
   * @param {*} selectedUserData: selected user data
   */
  const handleEditMenuItemClick = (selectedUserData) => {
    setSelectedCuisine(selectedUserData);
    setShowCuisineForm(true);
  };

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  const columns = [
    {
      title: I18n.t('cuisines.cuisine_table.table_column_name_label'),
      field: 'name',
      render: ({name, imageUrl}) => {
        return <AvatarCell heading={name ? name : ''} image={imageUrl}/>;
      },
    },
    {
      title: I18n.t('cuisines.cuisine_table.table_column_description_label'),
      field: 'description',
      sorting: false,
      width: '60%',
      render: ({description}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(description ? description : '', 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('cuisines.cuisine_table.table_column_action_label'),
      field: '-',
      sorting: false,
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <IconButton
              color="inherit"
              aria-label="option"
              aria-controls={`customized-menu-${props.id}`}
              aria-haspopup="true"
              onClick={(event) => {
                menuRef.current = props.id;
                handleMenuClick(event);
              }}
              className="option-menu"
            >
              <Icon fontSize="small">more_vert_rounded</Icon>
            </IconButton>
            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleEditMenuItemClick(props);
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="small">edit_rounded</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t(
                    'cuisines.cuisine_table.table_column_action_edit_label'
                  )}
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to render table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box p={2} mt={-1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box display="flex" alignItems="flex-end">
            <Box width="220px" mr={1}>
              <InputFilter
                filterName=""
                placeholder={I18n.t(
                  'cuisines.cuisine_table.table_column_name_label'
                )}
                onChangeFilter={(value) =>
                  handleChangeDebounceFilter('name', value)
                }
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => setShowCuisineForm(true)}
            >
              {I18n.t('cuisines.cuisine_table.add_cuisine_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={cuisineList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={cuisineList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowsPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 0;
          pagination.rowsPerPage = rowsPerPage;
          setPaginationParams(pagination);
        }}
      />

      {showCuisineForm && (
        <CuisineForm
          open={showCuisineForm}
          setOpen={setShowCuisineForm}
          selectedCuisine={selectedCuisine}
          setSelectedCuisine={setSelectedCuisine}
          setRefreshList={setRefreshList}
        />
      )}

      <MessageModal ref={modalRef}/>
    </TableContainer>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getCuisines: async (data) => {
      try {
        return await dispatch(getCuisinesRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CuisineList);
