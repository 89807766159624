import {Icon, Tooltip, Typography} from '@mui/material';
import React from 'react';
import './Preview.css';

const Preview = React.memo(({media, totalCount, page, handleChange}) => {
  const documentRenderBasedOnType = (type, fileUrl) => {
    type = type.toLowerCase();
    switch (type) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return (
          <div className="gallery-media-container">
            <img className="gallery-media" alt="gallery" src={fileUrl}/>
            <Tooltip title="Download">
              <a href={fileUrl} download>
                <Icon className="download-image-file-icon">
                  get_app_rounded
                </Icon>
              </a>
            </Tooltip>
          </div>
        );

      case 'mp4':
        return (
          <video className="gallery-media" controls>
            <source src={fileUrl} type="video/mp4"/>
          </video>
        );

      case 'pdf':
        return (
          <div className="grid-download-icon-label">
            <div className="download-container">
              <a href={fileUrl} download>
                <Icon className="download-icon">get_app_rounded</Icon>
                <Typography>Download File</Typography>
              </a>
            </div>
          </div>
        );

      default:
        return (
          <div className="grid-download-icon-label">
            <div className="download-container">
              <a href={fileUrl} download>
                <Icon className="download-icon">get_app_rounded</Icon>
                <Typography>Download File</Typography>
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {page !== 1 ? (
        <Icon
          className="left-click-button"
          onClick={() => handleChange(page - 1)}
        >
          chevron_left_rounded
        </Icon>
      ) : (
        <></>
      )}

      {documentRenderBasedOnType(media?.type, media?.url)}

      {page < totalCount ? (
        <Icon
          className="right-click-button"
          onClick={() => handleChange(page + 1)}
        >
          chevron_right_rounded
        </Icon>
      ) : (
        <></>
      )}
    </>
  );
});

export default Preview;
