const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      height: '44px',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Lato',
      '&.MuiButton-sizeSmall': {
        height: '32px',
      },
    },
  },
};

export default MuiButton;
