import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    noDataAvailable: {
      textAlign: 'center !important',
      padding: '20px 0px 0px 0px !important',
    },
  };
});
