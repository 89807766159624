import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams} from 'react-router-dom';
import {MessageModal, ProfileViewVendor} from '../../../../utils/components';
import {showSnackbar} from '../../../../utils/components/AppLoader/AppLoaderActions';
import {SNACKBAR_VARIAINT} from '../../../../utils/Constants';
import {ROUTE_ACTIONS} from '../../../../utils/Constants';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {EventList} from '../../../Events/components';
import {ProductList} from '../../../Product/components';
import {SurveyList} from '../../../Survey/components';
import {VendorAdminList} from '../../../UserManagement/components';
import {handleTabChange} from '../../VendorActions';
import {
  activateDeactivateVendorRequest,
  getSignedUrlRequest,
  getVendorRequest,
  publishUnpublishVendorRequest,
  updateVendorRequest,
  uploadToS3Request,
} from '../../VendorApiActions';
import {UpdateVendor} from '../index';

import './VendorDetails.css';

/**
 * function to render vendor detailed view
 * @param {*} history: history object
 * @param {*} match: match object
 * @param {*} getVendorDetails: function to get vendor details
 * @param {*} activateDeactivateVendor: function to activate deactivate vendor
 * @param {*} getSignedUrl: function to get signed url
 * @param {*} uploadToS3: function to upload to signed url
 * @param {*} updateVendor: function to update vendor
 * @param {*} showSnackbarMessage: function to show snackbar message
 * @returns
 */
const VendorDetails = ({
                         history,
                         getVendorDetails,
                         activateDeactivateVendor,
                         getSignedUrl,
                         uploadToS3,
                         updateVendor,
                         showSnackbarMessage,
                         handleTabChange,
                         value,
                       }) => {
  const {vendorId} = useParams();
  const [vendor, setVendor] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshDetails, setRefreshDetails] = useState(false);

  /**
   * function to handle tab change
   * @param {*} event: event object
   * @param {*} newTabValue: new tab value
   */
  const handleChange = (event, newTabValue) => {
    handleTabChange(newTabValue);
  };

  /**
   * function to render tab content
   * @param {*} tabValue: tab value
   * @returns
   */
  const renderTabPanel = (tabValue) => {
    switch (tabValue) {
      case '1':
        return (
          <ProductList
            history={history}
            routeFrom="/products"
            vendorInfo={vendor}
          />
        );
      case '2':
        return (
          <SurveyList
            history={history}
            routeFrom="/surveys"
            vendorInfo={vendor}
          />
        );
      case '3':
        return (
          <EventList
            history={history}
            routeFrom="/events"
            vendorInfo={vendor}
          />
        );
      case '4':
        return <VendorAdminList history={history} vendorInfo={vendor}/>;
      default:
        return <></>;
    }
  };

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to close menu
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  /**
   * function to get vendor details
   * @param {*} id: id
   */
  const getVendorDetailsById = async (id) => {
    const {data} = await getVendorDetails(id);
    setVendor(data);
  };

  useEffect(() => {
    getVendorDetailsById(vendorId);

  }, [vendorId]);

  useEffect(() => {
    if (refreshDetails) {
      getVendorDetailsById(vendorId);
      setRefreshDetails(false);
    }

  }, [refreshDetails]);

  /**
   * function to close form dialog
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * function to activate deactivate vendor
   * @param {*} vendorId: vendor id
   * @param {*} enabled: enabled
   * @param {*} name: name
   */
  const onActivateDeactivate = (vendorId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} Vendor`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;

    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          enabled: !enabled,
        };
        await activateDeactivateVendor(vendorId, data);
        getVendorDetailsById(vendorId);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle file change
   * @param {*} event: event object
   * @param {*} elementName: element name
   */
  const onFileChange = async (event, elementName) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const extensionList =
      Constants.REACT_APP_PROFILE_IMAGE_SUPPORTED.split(',');

    const checkFileSize =
      file?.size < Constants.REACT_APP_ALLOWED_MAX_ATTACHMENT_SIZE_IN_BYTES;
    const checkFileExtension = UtilHelper.checkAllowedExtension(
      fileName,
      extensionList
    );

    if (checkFileExtension && checkFileSize) {
      const fileData = {
        fileName: fileName,
        fileType: '.' + fileExtension,
      };

      const resSignedUrl = await getSignedUrl(fileData);
      await uploadToS3(resSignedUrl.urls, file);

      const requestData = {};

      if (elementName === 'coverImgUrl') {
        requestData.coverImgUrl = resSignedUrl.path;
      } else {
        requestData.profileImgUrl = resSignedUrl.path;
      }

      const resUpdatedData = await updateVendor(vendorId, requestData);

      setVendor({
        ...vendor,
        coverImgUrl: resUpdatedData.data.coverImgUrl
          ? resUpdatedData.data.coverImgUrl
          : vendor.coverImgUrl,
        profileImgUrl: resUpdatedData.data.profileImgUrl
          ? resUpdatedData.data.profileImgUrl
          : vendor.profileImgUrl,
      });
    } else {
      let message = '';
      if (!checkFileExtension) {
        message = I18n.t('error_messages.invalid_file_type');
        showSnackbarMessage(message, SNACKBAR_VARIAINT.ERROR);
      } else if (!checkFileSize) {
        message = 'Please select file size less than 5mb';
        showSnackbarMessage(message, SNACKBAR_VARIAINT.ERROR);
      }
    }
  };

  /**
   * function to handle edit click
   */
  const onEditVendor = () => {
    setOpenDialog(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className="vendor-account-card-container">
          <Tooltip title="Back">
            <Icon
              className="commuity-back-arrow-icon"
              onClick={() => {
                history.replace(`${ROUTE_ACTIONS.VENDORS}`);
              }}
            >
              arrow_back_rounded
            </Icon>
          </Tooltip>

          <ProfileViewVendor
            coverPhoto={vendor?.coverImgUrl}
            icon={vendor?.profileImgUrl}
            onFileChange={onFileChange}
          />
          <Grid container spacing={0}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}/>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              {vendor?.enabled ? (
                <Button
                  disableRipple
                  startIcon={<Icon>check_circle_rounded</Icon>}
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={{cursor: 'default'}}
                >
                  Enabled
                </Button>
              ) : (
                <Button
                  disableRipple
                  startIcon={<Icon>check_circle_rounded</Icon>}
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={{cursor: 'default'}}
                >
                  Disabled
                </Button>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid container spacing={0}>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant="body1">
                    <Tooltip title="Products">
                      <span className="stat-icon">
                        <Icon className="margin-rigth-5 stat-icon-one">
                          inventory_2
                        </Icon>{' '}
                        {vendor?.totalProduct}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant="body1">
                    <Tooltip title="Surveys">
                      <span className="stat-icon">
                        <Icon className="margin-rigth-5 stat-icon-one">
                          poll
                        </Icon>{' '}
                        {vendor?.totalSurvey}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant="body1">
                    <Tooltip title="Events">
                      <span className="stat-icon">
                        <Icon className="margin-rigth-5 stat-icon-two">
                          event
                        </Icon>{' '}
                        {vendor?.totalEvent}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography variant="body1">
                    <Tooltip title="Mentions">
                      <span className="stat-icon">
                        <Icon className="margin-rigth-5 stat-icon-three">
                          comment
                        </Icon>{' '}
                        {vendor?.totalMention}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Tooltip
                title={I18n.t(
                  'vendor.vendor_details.vendor_actions_tooltip_title'
                )}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  className="edit-vendor-icon"
                  onClick={(event) => handleMenuClick(event)}
                >
                  <Icon>more_horiz</Icon>
                </Button>
              </Tooltip>

              <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={`customized-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl}
                onClose={() => {
                  handleMenuClose();
                }}
              >
                <MenuItem
                  onClick={() => {
                    onEditVendor();
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize="small">edit_rounded</Icon>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={I18n.t(
                      'vendor.vendor_details.vendor_edit_tooltip_title'
                    )}
                  />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onActivateDeactivate(
                      vendor.id,
                      vendor.enabled,
                      vendor.name
                    );
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    {!vendor?.enabled ? (
                      <Icon fontSize="small">lock_open_rounded</Icon>
                    ) : (
                      <Icon fontSize="small">lock_rounded</Icon>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={vendor?.enabled ? 'Disable' : 'Enable'}
                  />
                </MenuItem>
              </Menu>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="margin-top-10"
            >
              <Typography variant="h5" className={`vendor-name`}>
                {vendor ? UtilHelper.ellipsisRender(vendor?.name, 150) : ''}
              </Typography>
              <Typography variant="h6" className="vendor-title">
                {vendor ? UtilHelper.ellipsisRender(vendor?.tagline, 150) : ''}
              </Typography>
              <Typography variant="body2" className="vendor-description">
                {vendor
                  ? UtilHelper.ellipsisRender(vendor?.description, 250)
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="primary"
              >
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_products_label')}
                  value="1"
                />
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_survey_label')}
                  value="2"
                />
                <Tab
                  label={I18n.t('vendor.vendor_details.tab_events_label')}
                  value="3"
                />
                <Tab
                  label={I18n.t(
                    'vendor.vendor_details.tab_vendor_admins_label'
                  )}
                  value="4"
                />
              </TabList>
            </Box>

            <TabPanel value={value} index={value}>
              {vendor && <>{renderTabPanel(value)}</>}
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>

      {openDialog && (
        <UpdateVendor
          openDialog={openDialog}
          vendorId={vendorId}
          vendor={vendor}
          setVendor={setVendor}
          handleCloseDialog={handleCloseDialog}
          setRefreshDetails={setRefreshDetails}
        />
      )}

      <MessageModal ref={modalRef}/>
    </Grid>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    value: state.vendors.tabValue,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorDetails: async (vendorId) => {
      try {
        return await dispatch(getVendorRequest(vendorId));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateVendor: async (vendorId, data) => {
      try {
        return await dispatch(activateDeactivateVendorRequest(vendorId, data));
      } catch (error) {
        throw error;
      }
    },

    updateVendor: async (vendorId, data) => {
      try {
        return await dispatch(updateVendorRequest(vendorId, data));
      } catch (error) {
        throw error;
      }
    },

    publishUnpublishVendor: async (vendorId, data) => {
      try {
        return await dispatch(
          dispatch(publishUnpublishVendorRequest(vendorId, data))
        );
      } catch (error) {
        throw error;
      }
    },

    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },

    showSnackbarMessage: (message, varient) => {
      dispatch(showSnackbar(message, varient));
    },

    handleTabChange: (tabValue) => {
      dispatch(handleTabChange(tabValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);
