import {AttachFile} from '@mui/icons-material';
import {withStyles} from '@mui/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import React, {useEffect, useState} from 'react';
import './FileUpload.css';

const styles = (theme) => ({
  smallDropzone: {
    minHeight: '100px',
    height: '120px',
    border: 'none',
    width: '120px',
    margin: 'auto',
    padding: '10px',
  },
  hideIcon: {
    display: 'none',
  },
  text: {
    margin: '0px !important',
  },
  textContainer: {
    padding: '0px !important',
  },
});

/**
 * function to render file upload
 */
const FileUpload = React.memo(
  ({classes, onUploadFileList, filesLimit, uploadText, uploadedFiles}) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
      if (uploadedFiles) {
        setFiles(uploadedFiles);
      }
    }, [uploadedFiles]);

    const uploadLabelRender = () => {
      return (
        <div className="add-file">
          <div className="content-display-center">
            {uploadText ? (
              uploadText
            ) : (
              <>
                <AttachFile/> <br/> Upload
              </>
            )}
          </div>
        </div>
      );
    };

    return (
      <DropzoneArea
        Icon={AttachFile}
        showPreviewsInDropzone={false}
        showFileNamesInPreview={false}
        useChipsForPreview={false}
        dropzoneText={uploadLabelRender()}
        showAlerts={['error']}
        filesLimit={filesLimit ? filesLimit : 3}
        initialFiles={files}
        onChange={(selectedFiles) => onUploadFileList(selectedFiles)}
        onDelete={() => onUploadFileList([])}
        classes={{
          root: classes.smallDropzone,
          icon: classes.hideIcon,
          text: classes.text,
          textContainer: classes.textContainer,
        }}
      />
    );
  }
);

export default withStyles(styles)(FileUpload);
