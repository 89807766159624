import {Alert, Container, Snackbar} from '@mui/material';
import clsx from 'clsx';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Redirect} from 'react-router-dom';
import Auth from '../../../../utils/Auth';
import {AppLoader, MessageModal} from '../../../../utils/components';
import {clearSnackbar} from '../../../../utils/components/AppLoader/AppLoaderActions';
import {ROUTE_ACTIONS} from '../../../../utils/Constants';
import {sessionExpiredHideMessage} from '../../../Authentication/AuthenticationActions';
import {logoutUserRequest} from '../../../Authentication/AuthenticationApiActions';
import DashboardRoutes from '../../routes';
import {SideDrawer} from '../index';
import {useStyles} from './styles';

/**
 * function to render dashboard layout
 * @param {*} match: match object
 * @param {*} history: history object
 * @param {*} menuName: menu name
 * @param {*} loggedInUser: logged in user
 * @param {*} clearSnackbar: function to handle clear snackbar
 * @param {*} showSnackbar: boolean to show snackbar
 * @param {*} snackbarVariant: snackbar variant
 * @param {*} snackbarMessage: snackbar message
 * @param {*} showSessionExpiredModal: boolean to show session expired modal
 * @param {*} sessionExpiredError: session expired error
 * @param {*} hideAuthenticationModal: function to handle hide authentication modal
 * @returns
 */
const DashboardLayout = ({
                           menuName,
                           loggedInUser,
                           clearSnackbar,
                           showSnackbar,
                           snackbarVariant,
                           snackbarMessage,
                           showSessionExpiredModal,
                           sessionExpiredError,
                           hideAuthenticationModal,
                         }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const modalRef = useRef();

  useEffect(() => {
    document.title = menuName;
  }, [menuName]);

  /**
   * function to handle drawer open close
   */
  const handleDrawerOpenClose = () => setOpen(!open);

  /**
   * function to log out user
   */
  const logoutUser = () => Auth.logoutAndNavigateToLogin();

  /**
   * function to handle close snackbar
   */
  const handleCloseSnackbar = () => clearSnackbar();

  useEffect(() => {
    if (showSessionExpiredModal) {
      modalRef.current.setState({
        showModal: showSessionExpiredModal,
        message: sessionExpiredError,
        title: I18n.t('global.message_labels.session_expired'),
        showOkButton: true,
        showCancelButton: false,
        handleOk: () => {
          hideAuthenticationModal();
          logoutUser();
        },
        okText: I18n.t('global.button_labels.ok'),
      });
    }

  }, [showSessionExpiredModal]);

  if (!loggedInUser) {
    return <Redirect to={ROUTE_ACTIONS.HOME}/>;
  } else if (loggedInUser && loggedInUser.isSystemGeneratedPassword) {
    return <Redirect to={ROUTE_ACTIONS.FORCE_RESET_PASSWORD}/>;
  }

  return (
    <Container maxWidth={false}>
      <SideDrawer
        openDrawer={open}
        handleDrawerOpenClose={handleDrawerOpenClose}
        logoutUser={logoutUser}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <DashboardRoutes/>
      </main>

      <MessageModal ref={modalRef}/>

      <AppLoader/>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Alert
          severity={snackbarVariant ? snackbarVariant : 'info'}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
    showSnackbar: state.showLoader.showSnackbar,
    snackbarVariant: state.showLoader.snackbarVariant,
    snackbarMessage: state.showLoader.snackbarMessage,
    showSessionExpiredModal: state.auth.showSessionExpiredModal,
    sessionExpiredError: state.auth.sessionExpiredError,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    clearSnackbar: () => {
      dispatch(clearSnackbar());
    },

    hideAuthenticationModal: () => {
      dispatch(sessionExpiredHideMessage());
    },

    logoutUserReq: async () => {
      try {
        return await dispatch(logoutUserRequest());
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
