import {Autocomplete, Box, Button, Dialog, DialogContent, FormHelperText, Grid, Icon, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Multiselect} from '../../../../utils/components';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getBrandsForOptionsRequest} from '../../../Brands/BrandApiActions';
import {
  addSpiritRequest,
  getCuisineForOptionsRequest,
  getSignedUrlRequest,
  getSpiritConstantsRequest,
  updateSpiritRequest,
  uploadToS3Request,
} from '../../SpiritsApiActions';
import {useStyles} from './styles';

/**
 * function to render spirit form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to change the open state
 * @param {*} selectedSpirit: selected spirit
 * @param {*} setSelectedSpirit: function to set the selected spirit
 * @param getSpiritConstants
 * @param getCuisineForOptions
 * @param getBrandsForOptions
 * @param addSpirit
 * @param updateSpirit
 * @param getSignedUrl
 * @param uploadToS3
 * @param setRefreshList
 * @returns
 */
const SpiritForm = ({
                      open,
                      setOpen,
                      selectedSpirit,
                      setSelectedSpirit,
                      getSpiritConstants,
                      getCuisineForOptions,
                      getBrandsForOptions,
                      addSpirit,
                      updateSpirit,
                      getSignedUrl,
                      uploadToS3,
                      setRefreshList,
                    }) => {
  const classes = useStyles();

  const [spirit, setSpirit] = useState({
    name: '',
    flavour: null,
    imageUrl: '',
    recommendedWeather: [],
    recommendedTiming: [],
    recommendedForMood: [],
    recommendedCuisines: [],
    brand: null,
  });
  const [images, setImages] = useState([]);

  const [recommendedWeatherError, setRecommendedWeatherError] = useState('');
  const [recommendedTimingError, setRecommendedTimingError] = useState('');
  const [recommendedCuisineError, setRecommendedCuisineError] = useState('');
  const [recommendedForMoodError, setRecommendedForMoodError] = useState('');
  const [imagesError, setImagesError] = useState('');

  const [brandList, setBrandList] = useState();
  const [flavourList, setFlavourList] = useState(null);
  const [recommendedWeatherList, setRecommendedWeatherList] = useState(null);
  const [recommendedTimingList, setRecommendedTimingList] = useState(null);
  const [recommendedForMoodList, setRecommendedForMoodList] = useState(null);
  const [recommendedCuisineList, setRecommendedCuisineList] = useState(null);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (spirit.recommendedWeather.length > 0) {
      setRecommendedWeatherError('');
    }

    if (spirit.recommendedTiming.length > 0) {
      setRecommendedTimingError('');
    }

    if (spirit.recommendedForMood.length > 0) {
      setRecommendedForMoodError('');
    }

    if (spirit.recommendedCuisines.length > 0) {
      setRecommendedCuisineError('');
    }
  }, [spirit]);

  const getSpiritConstantsData = async () => {
    const res = await getSpiritConstants();
    setFlavourList(res.flavours);
    setRecommendedWeatherList(res.weather);
    setRecommendedTimingList(res.timing);
    setRecommendedForMoodList(res.mood);
  };

  useEffect(() => {
    getSpiritConstantsData().then(r => {});

  }, []);

  const getCuisineForOptionList = async () => {
    const res = await getCuisineForOptions();
    setRecommendedCuisineList(res);
  };

  useEffect(() => {
    getCuisineForOptionList().then(r => {});

  }, []);

  const getBrandsForOptionList = async () => {
    const res = await getBrandsForOptions();
    setBrandList(res?.data?.rows);
  };

  useEffect(() => {
    getBrandsForOptionList().then(r => {});

  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setImagesError('');
    }
  }, [images]);

  useEffect(() => {
    if (selectedSpirit) {
      setSpirit({
        name: selectedSpirit?.name,
        flavour:
          selectedSpirit?.flavour?.length > 0
            ? {
              id: '-',
              name: selectedSpirit.flavour[0],
            }
            : null,
        imageUrl: selectedSpirit?.imageUrl,
        imagePath: selectedSpirit?.imagePath,
        recommendedWeather: selectedSpirit?.recommendedWeather,
        recommendedTiming: selectedSpirit?.recommendedTiming,
        recommendedCuisines: selectedSpirit?.recommendedCuisines,
        recommendedForMood: selectedSpirit?.recommendedForMood,
        brand: selectedSpirit?.brand,
      });
    }
  }, [selectedSpirit]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, spirit);
    data[event.target.name] = event.target.value;
    setSpirit(data);
  };

  /**
   * function to handle form validation
   */
  const handleFormValidation = () => {
    if (spirit.recommendedWeather.length === 0) {
      setRecommendedWeatherError(I18n.t('error_messages.field_required'));
    }

    if (spirit.recommendedTiming.length === 0) {
      setRecommendedTimingError(I18n.t('error_messages.field_required'));
    }

    if (spirit.recommendedForMood.length === 0) {
      setRecommendedForMoodError(I18n.t('error_messages.field_required'));
    }

    if (spirit.recommendedCuisines.length === 0) {
      setRecommendedCuisineError(I18n.t('error_messages.field_required'));
    }

    if (images.length === 0 && !spirit?.imageUrl) {
      setImagesError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    handleFormValidation();

    if (
      spirit.recommendedWeather.length > 0 &&
      spirit.recommendedTiming.length > 0 &&
      spirit.recommendedForMood.length > 0 &&
      spirit.recommendedCuisines.length > 0 &&
      (images.length > 0 || spirit?.imageUrl)
    ) {
      const data = {
        ...spirit,
        flavour: spirit?.flavour ? [spirit.flavour.name] : [],
        BrandId: spirit?.brand?.id,
      };

      delete data['brand'];

      if (images.length > 0) {
        const imageFileData = {
          fileName: images[0].file.name,
          fileType: `.${images[0].file.name.split('.').pop()}`,
        };
        const resSignedUrl = await getSignedUrl(imageFileData);
        await uploadToS3(resSignedUrl.urls, images[0].file);
        data.imageUrl = resSignedUrl.path;

        if (selectedSpirit) {
          delete data['imagePath'];

          await updateSpirit(selectedSpirit?.id, UtilHelper.trimObject(data));
        } else {
          await addSpirit(UtilHelper.trimObject(data));
        }

        handleSpiritFormClose();
        setRefreshList(true);
      } else {
        if (selectedSpirit) {
          const requestData = {
            ...data,
            imageUrl: spirit.imagePath,
          };

          delete requestData['imagePath'];

          await updateSpirit(
            selectedSpirit?.id,
            UtilHelper.trimObject(requestData)
          );
        } else {
          await addSpirit(UtilHelper.trimObject(data));
        }

        handleSpiritFormClose();
        setRefreshList(true);
      }
    }
  };

  /**
   * function to handle form close
   */
  const handleSpiritFormClose = () => {
    setOpen(false);

    if (selectedSpirit) {
      setSelectedSpirit(null);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleFormValidation();
  };

  const handleMultiselectChange = (event, value, name) => {
    const updatedSpirit = {...spirit};
    if (event.target.checked) {
      updatedSpirit[name].push(value);
    } else {
      const findIndex = updatedSpirit[name].findIndex(
        (selectedItem) => selectedItem === value
      );
      if (findIndex > -1) {
        updatedSpirit[name].splice(findIndex, 1);
      }
    }

    setSpirit(updatedSpirit);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, spirit);
    data[elementName] = selectedvalue;
    setSpirit(data);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {!selectedSpirit
                ? `${I18n.t('spirit.spirit_form.form_title_add_label')}`
                : `${I18n.t('spirit.spirit_form.form_title_update_label')}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${spirit.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`}
                    label={I18n.t('spirit.spirit_form.form_field_name_label')}
                    onChange={handleChangeInput}
                    name="name"
                    value={spirit.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`,
                      `matchRegexp:${Constants.REGEX.NAME}`
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_50_allowed_characters_for_text'
                      ),
                      I18n.t(
                        'error_messages.invalid_string'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <Autocomplete
                    size="small"
                    name="brand"
                    required
                    getOptionLabel={(option) => (option ? option.name : '')}
                    options={brandList ? brandList : []}
                    onChange={(e, value) =>
                      handleChangeAutocomplete('brand', value)
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    value={spirit.brand}
                    renderInput={(params) => {
                      return (
                        <TextValidator
                          required
                          variant="standard"
                          name="brand"
                          {...params}
                          fullWidth
                          label={`Brand`}
                          value={spirit.brand}
                          validators={['required']}
                          errorMessages={[
                            I18n.t('error_messages.field_required'),
                          ]}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                  <Autocomplete
                    size="small"
                    name="flavour"
                    getOptionLabel={(option) => (option ? option.name : '')}
                    options={flavourList ? flavourList : []}
                    onChange={(e, value) =>
                      handleChangeAutocomplete('flavour', value)
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    value={spirit.flavour}
                    renderInput={(params) => {
                      return (
                        <TextValidator
                          variant="standard"
                          name="flavour"
                          {...params}
                          fullWidth
                          label={`Flavour`}
                          value={spirit.flavour}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box minHeight="50px">
                    <Multiselect
                      label={`${I18n.t(
                        'spirit.spirit_form.form_field_recommended_weather_label'
                      )} *`}
                      name="recommendedWeather"
                      optionsList={
                        recommendedWeatherList ? recommendedWeatherList : []
                      }
                      value={spirit.recommendedWeather}
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) =>
                        handleMultiselectChange(event, value, name)
                      }
                    />
                  </Box>
                  {recommendedWeatherError && (
                    <FormHelperText error>
                      {recommendedWeatherError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box minHeight="50px">
                    <Multiselect
                      label={`${I18n.t(
                        'spirit.spirit_form.form_field_recommended_timing_label'
                      )} *`}
                      name="recommendedTiming"
                      optionsList={
                        recommendedTimingList ? recommendedTimingList : []
                      }
                      value={spirit.recommendedTiming}
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) =>
                        handleMultiselectChange(event, value, name)
                      }
                    />
                  </Box>
                  {recommendedTimingError && (
                    <FormHelperText error>
                      {recommendedTimingError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box minHeight="50px">
                    <Multiselect
                      label={`${I18n.t(
                        'spirit.spirit_form.form_field_recommended_cuisines_label'
                      )} *`}
                      name="recommendedCuisines"
                      optionsList={
                        recommendedCuisineList ? recommendedCuisineList : []
                      }
                      value={spirit.recommendedCuisines}
                      selectionBasedOn="id" //name or id
                      handleChange={(event, value, name) =>
                        handleMultiselectChange(event, value, name)
                      }
                    />
                  </Box>
                  {recommendedCuisineError && (
                    <FormHelperText error>
                      {recommendedCuisineError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box minHeight="50px">
                    <Multiselect
                      label={`${I18n.t(
                        'spirit.spirit_form.form_field_recommended_mood_label'
                      )} *`}
                      name="recommendedForMood"
                      optionsList={
                        recommendedForMoodList ? recommendedForMoodList : []
                      }
                      value={spirit.recommendedForMood}
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) =>
                        handleMultiselectChange(event, value, name)
                      }
                    />
                  </Box>
                  {recommendedForMoodError && (
                    <FormHelperText error>
                      {recommendedForMoodError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {images.length === 0 && !spirit?.imageUrl && (
                    <Box>
                      <ImageUploading
                        acceptType={['jpg', 'jpeg', 'png']}
                        maxFileSize={5242880}
                        value={images}
                        onChange={(imageFiles) => setImages(imageFiles)}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({
                            onImageUpload,
                            errors,
                          }) => (
                          <>
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={onImageUpload}
                            >
                              {I18n.t(
                                'spirit.spirit_form.form_add_image_label'
                              )}{' '}
                              *
                            </Button>

                            <Box>
                              {errors && (
                                <Box mt={0.5} ml={0.1}>
                                  {errors.acceptType && (
                                    <FormHelperText error>
                                      Please select only jpeg, jpg and png files
                                    </FormHelperText>
                                  )}

                                  {errors.maxFileSize && (
                                    <FormHelperText error>
                                      Please select max file size of 5MB
                                    </FormHelperText>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </ImageUploading>
                    </Box>
                  )}

                  {(images.length > 0 || spirit?.imageUrl) && (
                    <Box>
                      <Box display="inline-block" position="relative" mt={1}>
                        <Box position="absolute" right="-8px" top="-8px">
                          <Icon
                            fontSize="small"
                            classes={{root: classes.removeIcon}}
                            onClick={() => {
                              if (images.length > 0) {
                                setImages([]);
                              } else {
                                setSpirit({
                                  ...spirit,
                                  imageUrl: '',
                                  imagePath: '',
                                });
                              }
                            }}
                          >
                            cancel
                          </Icon>
                        </Box>
                        <Box>
                          <img
                            src={
                              images.length > 0
                                ? images[0].data_url
                                : spirit?.imageUrl
                            }
                            alt="file"
                            className={classes.spiritImage}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {imagesError && (
                    <FormHelperText
                      error
                      style={{marginTop: '7px', marginLeft: '14px'}}
                    >
                      {imagesError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Button
                          color="inherit"
                          variant="text"
                          onClick={handleSpiritFormClose}
                        >
                          {I18n.t('global.button_labels.cancel')}
                        </Button>
                      </Box>
                      <Box ml={1}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          {I18n.t('global.button_labels.save')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSpiritConstants: async () => {
      try {
        return await dispatch(getSpiritConstantsRequest());
      } catch (error) {
        throw error;
      }
    },

    getCuisineForOptions: async () => {
      try {
        return await dispatch(getCuisineForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getBrandsForOptions: async () => {
      try {
        return await dispatch(getBrandsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    addSpirit: async (data) => {
      try {
        return await dispatch(addSpiritRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateSpirit: async (id, data) => {
      try {
        return await dispatch(updateSpiritRequest(id, data));
      } catch (error) {
        throw error;
      }
    },

    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiritForm);
