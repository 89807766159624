const MuiTimelineOppositeContent = {
  styleOverrides: {
    root: {
      width: '100px',
      fontFamily: 'Lato',
      flex: -1,
      padding: '6px 5px 6px 0px',
    },
  },
};

export default MuiTimelineOppositeContent;
