import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get vendors
 * @param {*} data: data for server request
 * @returns
 */
export const getVendorsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.VENDORS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get vendors for options
 * @returns
 */
export const getVendorsForOptionsRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.VENDORS}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add vendor
 * @param {*} data: data
 * @returns
 */
export const addVendorRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.VENDORS}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Vendor details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              'Something went wrong, please try again',
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to get vendor
 * @param {*} id
 * @returns
 */
export const getVendorRequest = (id) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.VENDORS}/${id}`, 'get')
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

export const activateDeactivateVendorRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              `Vendor activated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `Vendor deactivated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            'Something went wrong, please try again',
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to update vendor
 * @param {*} vendorId: vendor id
 * @param {*} vendor: vendor
 * @returns
 */
export const updateVendorRequest = (vendorId, vendor) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}`,
      'patch',
      vendor
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            `Vendor details saved successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            'Something went wrong, please try again',
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to get signed url
 * @param {*} data
 * @returns
 */
export const getSignedUrlRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/files/signedURL`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to upload file to signed url
 * @param {*} data
 * @returns
 */
export const uploadToS3Request = (url, file) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const getAllVendorsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.VENDORS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * Server API call to get all vendors
 * - Dispatch getAllVendors action after successful response from server
 * @returns {function(*)}
 */
export const getAllVendorsWithoutPaginationRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.VENDORS}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        // dispatch(getAllVendors(res.data.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const publishUnpublishVendorRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.VENDORS}/${vendorId}/publishOrUnpublishAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.published) {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_publish_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('vendor.vendor_details.vendor_unpublish_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        // dispatch(activateDeactivateVendor(res.data.data));
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * Server API call to get all vendorActivities
 * @returns {function(*)}
 */
export const getAllActivitiesByVendorIdRequest = (data, vendorId) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  let startDate = searchData.searchDate
    ? moment(searchData.searchDate.startDate).format('YYYY-MM-DD')
    : null;
  let endDate = searchData.searchDate
    ? moment(searchData.searchDate.endDate).format('YYYY-MM-DD')
    : null;
  return (dispatch) => {
    dispatch(showLoader());
    let url;
    if (startDate && endDate) {
      url = `${API_ACTIONS.VENDORS}/activity/${vendorId}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
    } else {
      url = `${API_ACTIONS.VENDORS}/activity/${vendorId}?offset=${offset}&limit=${limit}`;
    }

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};
