import {
  Avatar,
  Box,
  Button,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {NavLink} from 'react-router-dom';
import {logo} from '../../../../assets';
import {ROUTE_ACTIONS, SIDE_MENU_SA, SIDE_MENU_VA} from '../../../../utils/Constants';
import {useStyles} from './styles';

/**
 * function to render side drawer
 * @param {*} openDrawer: boolean to open side drawer
 * @param {*} handleDrawerOpenClose: function to handle drawer open close
 * @param {*} logoutUser: function to logout user
 * @param {*} loggedInUser: logged in user
 * @returns
 */
const SideDrawer = ({
                      openDrawer,
                      handleDrawerOpenClose,
                      logoutUser,
                      loggedInUser,
                    }) => {
  const classes = useStyles();
  const [menuList, setMenuList] = useState([]);
  const [userMenuEl, setUserMenuEl] = useState(undefined);

  useEffect(() => {
    if (loggedInUser.userRoles.isSystemSuperAdmin) {
      setMenuList(SIDE_MENU_SA);
    } else {
      setMenuList(SIDE_MENU_VA);
    }
  }, [loggedInUser]);

  /**
   * function to handle profile click
   * @param {*} event: event object
   */
  const handleClick = (event) => {
    setUserMenuEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleClose = () => {
    setUserMenuEl(null);
  };

  /**
   * function to return menu icon
   * @param {*} menuName: menu name
   * @returns
   */
  const iconRender = (menuName) => {
    switch (menuName) {
      case I18n.t('side_menu.user_management'):
        return <Icon>supervisor_account</Icon>;
      case I18n.t('side_menu.vendor_management'):
        return <Icon>store</Icon>;
      case I18n.t('side_menu.whiskey_tube'):
        return <Icon>video_library</Icon>;
      case I18n.t('side_menu.products'):
        return <Icon>inventory_2</Icon>;
      case I18n.t('side_menu.events'):
        return <Icon>event</Icon>;
      case I18n.t('side_menu.surveys'):
        return <Icon>poll</Icon>;
      case I18n.t('side_menu.brands'):
        return <Icon>branding_watermark</Icon>;
      case I18n.t('side_menu.cuisines'):
        return <Icon>restaurant</Icon>;
      case I18n.t('side_menu.spirits'):
        return <Icon>liquor</Icon>;
      case I18n.t('side_menu.orders'):
        return <Icon>shopping_cart</Icon>;
      case I18n.t('side_menu.event_bookings'):
        return <Icon>event_available</Icon>;
      default:
        return <></>;
    }
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: openDrawer,
        [classes.drawerClose]: !openDrawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        }),
      }}
    >
      <Box className={classes.toolbar}>
        <List>
          <ListItem>
            <ListItemIcon>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpenClose}
                edge="start"
              >
                <Icon className={classes.menuIcon}>menu</Icon>
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={
                <Box className={classes.sideDrawerLogoImgDiv}>
                  <img
                    className={classes.sideDrawerLogoImg}
                    src={logo}
                    alt="logo"
                  />
                </Box>
              }
            />
          </ListItem>
        </List>
      </Box>

      <List>
        {menuList.map((singleSidemenu, index) => (
          <Box key={singleSidemenu.label}>
            {!singleSidemenu.hide ? (
              <ListItem className={classes.item} disableGutters>
                <Button
                  className={classes.button}
                  component={NavLink}
                  to={singleSidemenu.action}
                  activeClassName={classes.active}
                >
                  <Tooltip title={I18n.t(singleSidemenu.label)} placement="right">
                    <Box className={classes.icon}>
                      {iconRender(I18n.t(singleSidemenu.label))}
                    </Box>
                  </Tooltip>
                  {I18n.t(singleSidemenu.label)}
                </Button>
              </ListItem>
            ) : (
              <></>
            )}
          </Box>
        ))}
      </List>

      <Box className={classes.drawerFooter}>
        <Box className={classes.userProfileContainer}>
          <Avatar
            alt="profile"
            className={classes.avatar}
            src={loggedInUser.profileImageURL}
            onClick={handleClick}
            aria-controls="customized-menu"
            aria-haspopup="true"
          />
          <Typography
            className={clsx(classes.name, !openDrawer && classes.hide)}
            variant="h4"
          >
            {loggedInUser.displayName}
          </Typography>

          <Menu
            id="customized-menu"
            anchorEl={userMenuEl}
            keepMounted
            open={Boolean(userMenuEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem to={ROUTE_ACTIONS.CHANGE_PASSWORD} component={NavLink}>
              <ListItemIcon>
                <Icon fontSize="small">move_to_inbox</Icon>
              </ListItemIcon>
              <ListItemText disableTypography primary="Change Password"/>
            </MenuItem>

            <MenuItem onClick={() => logoutUser()}>
              <ListItemIcon>
                <Icon fontSize="small">exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText disableTypography primary="Logout"/>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Drawer>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
