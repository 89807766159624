const MuiAccordionSummary = {
  styleOverrides: {
    root: {
      padding: '0px !important',
      minHeight: '0px !important',
    },

    content: {
      margin: '0px !important',
    },
  },
};

export default MuiAccordionSummary;
