import {Box, Button, Grid, Icon, Paper, TableContainer, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {CustomTable} from '../../../../utils/components';
import {InputFilter, MultiselectFilter, RadioButtonFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorRequest, getVendorsForOptionsRequest,} from '../../../Vendors/VendorApiActions';
import {getProductCategoryRequest, getProductsRequest,} from '../../ProductApiActions';
import {useStyles} from './styles';

/**
 * function to render product list
 * @param {*} history: history list
 * @param {*} getProducts: function to get products
 * @param {*} routeFrom: string value of route for dynamic routing
 * @param getProductCategory
 * @param getVendorsForOptions
 * @param getVendor
 * @param vendorInfo
 * @returns
 */
const ProductList = ({
                       history,
                       getProducts,
                       routeFrom,
                       getProductCategory,
                       getVendorsForOptions,
                       getVendor,
                       vendorInfo,
                     }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const {vendorId} = useParams();
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      name: '',
      enabled: '',
      archive: '',
      categories: [],
      vendors: vendorInfo ? [vendorInfo?.name] : [],
    },
  });
  const [productList, setProductList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [vendorList, setVendorList] = useState(null);

  const getCategoryList = async () => {
    const data = await getProductCategory();
    setCategoryList(data);
  };

  useEffect(() => {
    getCategoryList().then(r => {});

  }, []);

  /**
   * function to get vendor option list
   */
  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    if (!vendorId) {
      getVendorsForOptionList().then(r => {});
    }

  }, [vendorId]);

  /**
   * function to get product list
   * @param {*} requestData: request data
   */
  const getProductList = async (requestData) => {
    try {
      const {data} = await getProducts(requestData);
      setProductList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getProductList(paginationParams).then(r => {});

  }, [paginationParams]);

  const columns = [
    {
      title: I18n.t('product.product_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({id, name}) => {
        return (
          <Box
            component={Link}
            to={`${match?.url}${routeFrom ? routeFrom : ''}/${id}`}
          >
            <Typography variant="body2">
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('product.product_table.table_column_category_label'),
      field: 'category',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({ProductCategory}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(ProductCategory?.name, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('product.product_table.table_column_vendor_label'),
      field: 'Vendor',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({Vendor}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(Vendor?.name ? Vendor?.name : '', 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('product.product_table.table_column_cost_label'),
      field: 'cost',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({cost}) => {
        return <Typography variant="body2">{cost}</Typography>;
      },
    },
    {
      title: I18n.t('product.product_table.table_column_items_sold_label'),
      field: 'itemsSold',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({itemsSold}) => {
        return (
          <Typography variant="body2">{itemsSold ? itemsSold : 0}</Typography>
        );
      },
    },
    {
      title: I18n.t('product.product_table.table_column_archive_status_label'),
      field: 'archive',
      sorting: false,
      width: '11%',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({archive}) => {
        return (
          <Typography
            variant="body2"
            className={!archive ? 'status-success' : 'status-danger'}
          >
            {!archive
              ? I18n.t(
                'product.product_table.table_column_unarchived_status_label'
              )
              : I18n.t('product.product_table.table_column_archived_label')}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('product.product_table.table_column_active_status_label'),
      field: 'enabled',
      sorting: false,
      width: '10%',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({enabled}) => {
        return (
          <Typography
            variant="body2"
            className={enabled ? 'status-success' : 'status-danger'}
          >
            {enabled
              ? I18n.t(
                'product.product_table.table_column_enabled_status_label'
              )
              : I18n.t('product.product_table.table_column_disabled_label')}
          </Typography>
        );
      },
    },
  ];

  /**
   * function to handle add product click
   */
  const onAddProduct = () => {
    const url = `${match?.url}${routeFrom ? routeFrom : ''}/add`;
    history.push(url);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <Box width="220px" mr={1}>
                <InputFilter
                  filterName=""
                  placeholder={I18n.t(
                    'product.product_table.table_filter_search_by_name'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeDebounceFilter('name', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'product.product_table.table_filter_product_active_status_label'
                )}
                title={I18n.t(
                  'product.product_table.table_filter_product_active_status_label'
                )}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <RadioButtonFilter
                arrayList={Constants.ARCHIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'product.product_table.table_filter_product_archive_status_label'
                )}
                title={I18n.t(
                  'product.product_table.table_filter_product_archive_status_label'
                )}
                value={paginationParams.searchData.archive}
                onChangeFilter={(value) => handleChangeFilter('archive', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <MultiselectFilter
                arrayList={categoryList ? categoryList : []}
                filterName={I18n.t(
                  'product.product_table.table_filter_product_category_label'
                )}
                title={I18n.t(
                  'product.product_table.table_filter_product_category_label'
                )}
                filterOn="name"
                value={paginationParams.searchData.categories}
                onChangeFilter={(value) =>
                  handleChangeFilter('categories', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            {!vendorId && (
              <Grid item classes={{item: classes.customGridItem}}>
                <MultiselectFilter
                  arrayList={vendorList ? vendorList : []}
                  filterName={I18n.t(
                    'product.product_table.table_filter_product_vendor_label'
                  )}
                  title={I18n.t(
                    'product.product_table.table_filter_product_vendor_label'
                  )}
                  filterOn="name"
                  value={paginationParams.searchData.vendors}
                  onChangeFilter={(value) =>
                    handleChangeFilter('vendors', value)
                  }
                  saveButtonText={I18n.t('global.button_labels.apply')}
                  clearButtonText={I18n.t('global.button_labels.clear')}
                />
              </Grid>
            )}
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => onAddProduct()}
            >
              {I18n.t('product.product_table.add_product_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={productList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={productList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowsPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 0;
          pagination.rowsPerPage = rowsPerPage;
          setPaginationParams(pagination);
        }}
      />
    </TableContainer>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */

const mapDispatchToProps = (dispatch) => {
  return {
    getProductCategory: async () => {
      try {
        return await dispatch(getProductCategoryRequest());
      } catch (error) {
        throw error;
      }
    },

    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getVendor: async (id) => {
      try {
        return await dispatch(getVendorRequest(id));
      } catch (error) {
        throw error;
      }
    },

    getProducts: async (data) => {
      try {
        return await dispatch(getProductsRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
