import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {CustomTable, MessageModal} from '../../../../utils/components';
import {DateRangeFilter, InputFilter, MultiselectFilter, NumberRangeFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsForOptionsRequest} from '../../../Vendors/VendorApiActions';
import {deleteSurveyRequest, getSurveysRequest} from '../../SurveyApiActions';
import {useStyles} from './styles';

/**
 * function to render survey list
 * @param {*} history: history list
 * @param {*} getProducts: function to get products
 * @param {*} routeFrom: string value of route for dynamic routing
 * @returns
 */
const SurveyList = ({
                      history,
                      getSurveys,
                      routeFrom,
                      deleteSurvey,
                      getVendorsForOptions,
                      vendorInfo,
                    }) => {
  const classes = useStyles();
  const {vendorId} = useParams();
  const match = useRouteMatch();
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      name: '',
      vendors: vendorInfo ? [vendorInfo?.name] : [],
      duration: null,
      responseCount: null,
    },
  });
  const [vendorList, setVendorList] = useState(null);
  const [surveyList, setSurveyList] = useState(null);

  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    if (!vendorId) {
      getVendorsForOptionList();
    }

  }, [vendorId]);

  /**
   * function to get survey list
   * @param {*} requestData: request data
   */
  const getSurveyList = async (requestData) => {
    try {
      const {data} = await getSurveys(requestData);
      setSurveyList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getSurveyList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getSurveyList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  /**
   * function to handle delete survey
   */
  const onDeleteSurvey = (surveyId, flag, name) => {
    const title = I18n.t('survey.confirmation_dialog.delete_survey');
    const message = `Are you sure you want to delete ${name}?`;

    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          flag: flag,
        };
        await deleteSurvey(surveyId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  const columns = [
    {
      title: I18n.t('survey.survey_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({id, name}) => {
        return (
          <Box
            component={Link}
            to={`${match?.url}${routeFrom ? routeFrom : ''}/${id}`}
          >
            <Typography variant="body2">
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_vendor_label'),
      field: 'Vendor',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({Vendor}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(Vendor?.name, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_duration_label'),
      field: 'startDate',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({startDate, endDate}) => {
        return (
          <Typography variant="body2">
            {`${moment(startDate).format('DD-MM-YYYY')} to
              ${moment(endDate).format('DD-MM-YYYY')}
            `}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_responses_label'),
      field: 'responseCount',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '10%',
      render: ({responseCount}) => {
        return <Typography variant="body2">{responseCount}</Typography>;
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_action_label'),
      field: '-',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <Tooltip title="Actions">
              <IconButton
                color="inherit"
                aria-label="option"
                aria-controls={`customized-menu-${props.id}`}
                aria-haspopup="true"
                onClick={(event) => {
                  menuRef.current = props.id;
                  handleMenuClick(event);
                }}
                className="option-menu"
              >
                <Icon fontSize="small">more_vert_rounded</Icon>
              </IconButton>
            </Tooltip>

            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onDeleteSurvey(props.id, true, props.name);
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="small">delete</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t('survey.survey_table.table_delete_tooltip')}
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle add product click
   */
  const onAddSurvey = () => {
    const url = `${match?.url}${routeFrom ? routeFrom : ''}/add`;
    history.push(url);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          pt={1}
          mb={1}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <Box width="220px" mr={1}>
                <InputFilter
                  filterName=""
                  placeholder={I18n.t(
                    'survey.survey_table.table_filter_search_by_name'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeDebounceFilter('name', value)
                  }
                />
              </Box>
            </Grid>

            {!vendorId && (
              <Grid item classes={{item: classes.customGridItem}}>
                <MultiselectFilter
                  arrayList={vendorList ? vendorList : []}
                  filterName={I18n.t(
                    'survey.survey_table.table_filter_vendor_label'
                  )}
                  title={I18n.t(
                    'survey.survey_table.table_filter_vendor_label'
                  )}
                  filterOn="name"
                  value={paginationParams.searchData.vendors}
                  onChangeFilter={(value) =>
                    handleChangeFilter('vendors', value)
                  }
                  saveButtonText={I18n.t('global.button_labels.apply')}
                  clearButtonText={I18n.t('global.button_labels.clear')}
                />
              </Grid>
            )}

            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t(
                  'survey.survey_table.table_filter_duration_label'
                )}
                title={I18n.t(
                  'survey.survey_table.table_filter_duration_label'
                )}
                value={paginationParams.searchData.duration}
                onChangeFilter={(value) =>
                  handleChangeFilter('duration', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'survey.survey_table.table_filter_response_label'
                )}
                title={I18n.t(
                  'survey.survey_table.table_filter_response_label'
                )}
                value={paginationParams.searchData.responseCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('responseCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => onAddSurvey()}
            >
              {I18n.t('survey.survey_table.add_survey_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <TableContainer>
        {renderTableTitle()}

        <CustomTable
          columns={columns}
          data={surveyList?.rows}
          hidePaging={false}
          hideBorderShadow={true}
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          rowsPerPage={paginationParams.rowsPerPage}
          count={surveyList?.count}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </TableContainer>

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getSurveys: async (data) => {
      try {
        return await dispatch(getSurveysRequest(data));
      } catch (error) {
        throw error;
      }
    },

    deleteSurvey: async (id, data) => {
      try {
        return await dispatch(deleteSurveyRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
