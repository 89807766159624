import palette from '../palette';

const MuiMenuItem = {
  styleOverrides: {
    root: {
      padding: '0.6rem 0.9rem 0.5rem 0.9rem',
      fontFamily: 'Lato',
      color: palette.text.primary,
      '&.Mui-selected': {
        backgroundColor: '#2a4f72',
      },

      '&:focus': {
        backgroundColor: palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: palette.text.primary,
        },
      },
    },
  },
};

export default MuiMenuItem;
