import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    headingText: {
      marginBottom: '18px !important',
    },

    spiritImage: {
      height: '80px',
      objectFit: 'contain',
      width: 'auto',
    },

    removeIcon: {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      borderRadius: '50%',
    },
  };
});
