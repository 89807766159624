import {Box, Chip, Grid, Icon, Paper, Tooltip, Typography,} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player/youtube';
import {I18n} from 'react-redux-i18n';
import ShowMore from 'react-show-more-button';
import {CommentList} from '../index';
import {useStyles} from './styles';

/**
 * function to render whiskey tube card
 * @param {*} video: video
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @param {*} onArchiveUnarchive: function to handle archive or unarchive
 * @param handleCommentListClick
 * @param clickedVideoCommentList
 * @returns
 */
const WhiskeyTubeCard = ({
                           video,
                           handleCardEditButtonClick,
                           onArchiveUnarchive,
                           handleCommentListClick,
                           clickedVideoCommentList,
                         }) => {
  const classes = useStyles();
  const htmlRef = useRef(null);

  const [updateUI, setUpdateUI] = useState(false);

  useEffect(() => {
    if (htmlRef?.current?.clientHeight > 105) {
      setUpdateUI(true);
    } else {
      setUpdateUI(false);
    }
  }, [video]);

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display="inline-block">
              <Typography classes={{root: classes.titleText}}>
                {video?.title}
              </Typography>
            </Box>
            <Box display="inline-block">
              {video?.tag?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag?.name}
                  size="small"
                  variant="outlined"
                  classes={{root: classes.tagChip}}
                />
              ))}
            </Box>
            <Box display="inline-block">
              <Typography>
                {I18n.t('whiskey_tube.whiskey_tube_card.vendor_label')}:{' '}
                {video.Vendor?.name ? video.Vendor?.name : 'NA'}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{textAlign: 'right'}}
          >
            <Tooltip
              title={I18n.t('whiskey_tube.whiskey_tube_card.edit_tooltip')}
              arrow
              placement="top"
            >
              <Icon
                classes={{root: classes.actionIcon}}
                onClick={() => handleCardEditButtonClick(video?.id)}
              >
                edit
              </Icon>
            </Tooltip>

            <Tooltip
              title={
                !video?.archive
                  ? I18n.t('whiskey_tube.whiskey_tube_card.archive_tooltip')
                  : 'Unarchive'
              }
              arrow
              placement="top"
            >
              <Icon
                classes={{root: classes.actionIcon}}
                onClick={() =>
                  onArchiveUnarchive(video?.id, video?.archive, video?.title)
                }
              >
                {video?.archive ? 'unarchive' : 'archive'}
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr={2}>
            <Icon>chat_bubble</Icon>{' '}
            <Typography classes={{root: classes.countText}}>
              {video?.commentCount}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mr={2}>
            <Icon>favorite</Icon>{' '}
            <Typography classes={{root: classes.countText}}>
              {video?.likeCount}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mr={2}>
            <Icon>bookmark</Icon>{' '}
            <Typography classes={{root: classes.countText}}>
              {video?.savedCount}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <ReactPlayer
              url={video?.link}
              controls={true}
              width="100%"
              height="145px"
            />
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <Box>
              <Typography classes={{root: classes.createdAndUpdatedByText}}>
                {I18n.t('whiskey_tube.whiskey_tube_card.created_by_label')}:{' '}
                <span className={classes.nameAndDate}>
                  {video?.createdBy ? video?.createdBy?.name : 'NA'} on{' '}
                  {moment(video?.createdAt).format(`DD-MM-YYYY, hh:mm a`)}
                </span>
              </Typography>
              <Typography classes={{root: classes.createdAndUpdatedByText}}>
                {I18n.t('whiskey_tube.whiskey_tube_card.last_updated_by_label')}
                :{' '}
                <span className={classes.nameAndDate}>
                  {video?.updatedBy ? video?.updatedBy?.name : 'NA'} on{' '}
                  {moment(video.updatedAt).format(`DD-MM-YYYY, hh:mm a`)}
                </span>
              </Typography>
            </Box>

            {updateUI ? (
              <Box>
                <ShowMore
                  maxHeight={105}
                  backgroundColor="trasparent"
                  classNameButton={classes.showMoreLessButton}
                  classNameButtonDiv={classes.showMoreLessButtonContainer}
                >
                  <div
                    ref={htmlRef}
                    dangerouslySetInnerHTML={{
                      __html: video?.description ? video?.description : '',
                    }}
                  />
                </ShowMore>
              </Box>
            ) : (
              <Box>
                <div
                  ref={htmlRef}
                  dangerouslySetInnerHTML={{
                    __html: video?.description ? video?.description : '',
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} onClick={() => handleCommentListClick(video?.id)}>
        <CommentList
          video={video}
          clickedVideoCommentList={clickedVideoCommentList}
        />
      </Box>
    </Paper>
  );
};

export default WhiskeyTubeCard;
