import ThemeOne from '../theme.json';

const MuiTab = {
  styleOverrides: {
    root: {
      borderRadius: '0px',
      fontSize: '15px',
      textTransform: 'capitalize',
      fontFamily: ThemeOne.fontFamily,
      '&.Mui-selected': {
        fontWeight: 'bold',
        color: ThemeOne.secondaryColor,
      },
    },

    textColorInherit: {
      // color: '#8b98a4',
      // fontWeight: 'bold',
    },
    textColorPrimary: {
      color: ThemeOne.fontColor,
      fontWeight: 'bold',
    },
    textColorSecondary: {
      // color: '#8b98a4',
      // fontWeight: 'bold',
    },
  },
};

export default MuiTab;
