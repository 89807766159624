import {Autocomplete, Button, Dialog, DialogContent, FormHelperText, Grid, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams} from 'react-router-dom';
import MobileNumber from '../../../../utils/components/MobileNumber/MobileNumber';
import Constants, {ONLY_BLANK_SPACES, USER_ROLES,} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsForOptionsRequest} from '../../../Vendors/VendorApiActions';
import {addUserRequest, updateUserRequest} from '../../UsersApiActions';

/**
 * function to render vendor admin form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to set the open state
 * @param {*} selectedUser: selected user
 * @param {*} setSelectedUser: function to set the selected user
 * @param addVendorAdmin
 * @param updateVendorAdmin
 * @param setRefreshList
 * @param getVendorPagesForOptions
 * @returns
 */
const VendorAdminForm = ({
                           open,
                           setOpen,
                           selectedUser,
                           setSelectedUser,
                           addVendorAdmin,
                           updateVendorAdmin,
                           setRefreshList,
                           getVendorPagesForOptions,
                         }) => {
  const {vendorId} = useParams();
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    countryCode: '',
    role: USER_ROLES.VENDOR,
    vendorPage: null,
  });
  const [phoneError, setPhoneError] = useState('');
  const [vendorPageList, setVendorPageList] = useState(null);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  /**
   * function to get vendor option list
   */
  const getVendorPagesForOptionList = async () => {
    const {data} = await getVendorPagesForOptions();
    setVendorPageList(data?.rows);
  };
  useEffect(() => {
    getVendorPagesForOptionList();

  }, []);

  useEffect(() => {
    if (selectedUser) {
      setUser({
        name: selectedUser?.displayName ? selectedUser?.displayName : '',
        email: selectedUser?.email,
        phone: selectedUser?.phone,
        countryCode: selectedUser?.countryCode ? selectedUser?.countryCode : '',
        role: USER_ROLES.VENDOR,
        vendorPage: selectedUser?.vendor?.hasOwnProperty('vendorId')
          ? {
            ...selectedUser.vendor,
            id: selectedUser?.vendor?.vendorId,
            name: selectedUser?.vendor?.vendorName,
          }
          : null,
      });
    }
  }, [selectedUser]);

  /**
   * function to clear the form
   */
  const clearForm = () => {
    setUser({
      name: '',
      email: '',
      phone: '',
      countryCode: '',
      role: USER_ROLES.VENDOR,
    });
  };

  /**
   * function to update mobile number
   * @param {*} phone: phone number
   * @param countryCode
   */
  const updateMobileNumber = (phone, countryCode) => {
    const data = Object.assign({}, user);
    data['phone'] = phone;
    data['countryCode'] = countryCode;
    setUser(data);

    if (!UtilHelper.validatePhone(phone)) {
      setPhoneError(I18n.t('error_messages.mobile_number_invalid_error'));
    } else {
      setPhoneError('');
    }
  };

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, user);
    data[elementName] = selectedvalue;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    if (!phoneError) {
      const data = {
        ...user,
        VendorId: vendorId ? vendorId : user.vendorPage?.id,
      };
      delete data['vendorPage'];

      try {
        if (selectedUser) {
          await updateVendorAdmin(
            selectedUser?.id,
            UtilHelper.trimObject(data)
          );
          handleSuperAdminFormClose();
          setRefreshList(true);
        } else {
          await addVendorAdmin(UtilHelper.trimObject(data));
          handleSuperAdminFormClose();
          setRefreshList(true);
        }
      } catch (error) {}
    }
  };

  /**
   * function to handle form close
   */
  const handleSuperAdminFormClose = () => {
    setOpen(false);
    clearForm();

    if (selectedUser) {
      setSelectedUser(null);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    if (user.phone.length === 0) {
      setPhoneError(I18n.t('error_messages.field_required'));
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogContent className="vendor-dialog-form-content">
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" className="vendor-dialog-title">
              {!selectedUser
                ? `${I18n.t(
                  'user.vendor_admin_table.form_heading_add_vendor_admin'
                )}`
                : `${I18n.t(
                  'user.vendor_admin_table.form_heading_update_vendor_admin'
                )}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${user.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                    label={I18n.t(
                      'user.vendor_admin_table.form_field_name_label'
                    )}
                    onChange={handleChangeInput}
                    name="name"
                    value={user.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'maxStringLength:' +
                      Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
                      `matchRegexp:${Constants.REGEX.NAME}`,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_allowed_characters_for_text_field'
                      ),
                      I18n.t('error_messages.invalid_string'),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    label={I18n.t(
                      'user.vendor_admin_table.form_field_email_label'
                    )}
                    onChange={handleChangeInput}
                    name="email"
                    value={user.email}
                    validators={[
                      'required',
                      'isEmail',
                      'maxStringLength:' +
                      Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.email_invalid_error'),
                      I18n.t(
                        'error_messages.maximum_100_allowed_characters_for_text'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MobileNumber
                    phone={user.phone}
                    updateMobileNumber={updateMobileNumber}
                  />

                  {phoneError && (
                    <FormHelperText
                      error
                      style={{marginTop: '7px', marginLeft: '14px'}}
                    >
                      {phoneError}
                    </FormHelperText>
                  )}
                </Grid>

                {!vendorId && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      size="small"
                      name="vendorPage"
                      required
                      getOptionLabel={(option) => (option ? option.name : '')}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id;
                      }}
                      options={vendorPageList ? vendorPageList : []}
                      onChange={(e, value) =>
                        handleChangeAutocomplete('vendorPage', value)
                      }
                      value={user.vendorPage}
                      renderInput={(params) => {
                        return (
                          <TextValidator
                            variant="standard"
                            name="vendorPage"
                            {...params}
                            fullWidth
                            label={`Vendor Page`}
                            value={user.vendorPage}
                            required
                            validators={['required']}
                            errorMessages={[
                              I18n.t('error_messages.field_required'),
                            ]}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleSuperAdminFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorPagesForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    addVendorAdmin: async (data) => {
      try {
        return await dispatch(addUserRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateVendorAdmin: async (userId, data) => {
      try {
        return await dispatch(updateUserRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAdminForm);
