import {Box, Button, Grid, Icon, Paper, TableContainer, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useRouteMatch} from 'react-router-dom';
import {AvatarCell, CustomTable} from '../../../../utils/components';
import {InputFilter, NumberRangeFilter, RadioButtonFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsRequest} from '../../VendorApiActions';
import {AddVendor} from '../index';
import {useStyles} from './styles';

/**
 * function to render vendor list
 * @param {*} history: history object
 * @param {*} getVendors: function to get vendors
 * @returns
 */
const VendorList = ({history, getVendors}) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      name: '',
      enabled: '',
      merchandisesCount: null,
      surveyCount: null,
      eventsCount: null,
      mentionsCount: null,
    },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [vendorList, setVendorList] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  /**
   * function to get vendor list
   * @param {*} requestData: request data
   */
  const getVendorList = async (requestData) => {
    try {
      const {data} = await getVendors(requestData);
      setVendorList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getVendorList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getVendorList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle vendor profile view
   * @param {*} vendorId: vendor id
   */
  const onViewVendor = (vendorId) => {
    const url = `${match.url}/${vendorId}`;
    history.push(url);
  };

  /**
   * function to handle form dialog close
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // getVendorList(paginationParams);
  };

  const columns = [
    {
      title: I18n.t('vendor.vendor_table.table_column_name_label'),
      field: 'name',
      width: '20%',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({id, name, profileImgUrl}) => {
        return (
          <Box
            onClick={() => onViewVendor(id)}
            className={classes.avatarContainer}
          >
            <AvatarCell
              image={profileImgUrl ? profileImgUrl : ''}
              heading={name}
            />
          </Box>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_merchandises'),
      field: 'totalProduct',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({totalProduct}) => {
        return (
          <Typography variant="body2">
            {totalProduct ? totalProduct : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_survey'),
      field: 'totalSurvey',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({totalSurvey}) => {
        return (
          <Typography variant="body2">
            {totalSurvey ? totalSurvey : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_events'),
      field: 'totalEvent',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({totalEvent}) => {
        return (
          <Typography variant="body2">{totalEvent ? totalEvent : 0}</Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_number_of_mentions'),
      field: 'totalMention',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({totalMention}) => {
        return (
          <Typography variant="body2">
            {totalMention ? totalMention : 0}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('vendor.vendor_table.table_column_activation_status_label'),
      field: 'enabled',
      sorting: false,
      width: '10%',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: ({enabled}) => {
        return (
          <Typography
            variant="body2"
            className={enabled ? 'status-success' : 'status-danger'}
          >
            {enabled
              ? I18n.t('vendor.vendor_table.table_column_enabled_status_label')
              : I18n.t('vendor.vendor_table.table_column_disabled_label')}
          </Typography>
        );
      },
    },
  ];

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <Box width="220px" mr={1}>
                <InputFilter
                  filterName=""
                  placeholder={I18n.t(
                    'vendor.vendor_table.table_filter_search_by_name'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeDebounceFilter('name', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'vendor.vendor_table.filter_active_status_label'
                )}
                title={I18n.t('vendor.vendor_table.filter_active_status_label')}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'vendor.vendor_table.filter_merchandises_label'
                )}
                title={I18n.t('vendor.vendor_table.filter_merchandises_label')}
                value={paginationParams.searchData.merchandisesCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('merchandisesCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_survey_label')}
                title={I18n.t('vendor.vendor_table.filter_survey_label')}
                value={paginationParams.searchData.surveyCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('surveyCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_events_label')}
                title={I18n.t('vendor.vendor_table.filter_events_label')}
                value={paginationParams.searchData.eventsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('eventsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t('vendor.vendor_table.filter_mentions_label')}
                title={I18n.t('vendor.vendor_table.filter_mentions_label')}
                value={paginationParams.searchData.mentionsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('mentionsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => setOpenDialog(true)}
            >
              {I18n.t('vendor.vendor_table.add_vendor_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={vendorList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={vendorList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowsPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 0;
          pagination.rowsPerPage = rowsPerPage;
          setPaginationParams(pagination);
        }}
      />

      {openDialog ? (
        <AddVendor
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          setRefreshList={setRefreshList}
        />
      ) : (
        <></>
      )}
    </TableContainer>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendors: async (data) => {
      try {
        return await dispatch(getVendorsRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
