import palette from '../palette';

const MuiInput = {
  styleOverrides: {
    root: {
      fontFamily: 'Lato',
      padding: '12px 0 11.5px 0',
      '&.Mui-focused': {
        color: palette.text.primary,
      },
      '&.MuiInput-underline::before': {
        borderBottom: '1px solid #334e68 !important',
      },
      '&.MuiInput-underline::after': {
        borderBottom: '1px solid #334e68 !important',
      },
    },

    input: {
      padding: '1px',
    },
  },
};

export default MuiInput;
