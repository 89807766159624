import {I18n} from 'react-redux-i18n';

export const SIGN_IN = 'SIGN_IN';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SESSION_EXPIRED_HIDE_MESSAGE = 'SESSION_EXPIRED_HIDE_MESSAGE';

/**
 * function to handle sign in
 * @param {*} data: data from server response
 * @returns
 */
export const signIn = (data) => {
  return {
    type: SIGN_IN,
    user: data.user,
    accessToken: data.access_token,
  };
};

/**
 * function to handle change password
 * @param {*} data: data for server request
 * @returns
 */
export const changePassword = (data) => {
  return {
    type: CHANGE_PASSWORD,
    data,
  };
};

/**
 * function to handle session expired
 * @param {*} error: error
 * @returns
 */
export const sessionExpired = (
  error = I18n.t('global.message_labels.session_expired')
) => {
  return {
    type: SESSION_EXPIRED,
    error,
  };
};

/**
 * function to handle session expired message hide
 * @returns
 */
export const sessionExpiredHideMessage = () => {
  return {
    type: SESSION_EXPIRED_HIDE_MESSAGE,
  };
};
