import {Accordion, AccordionDetails, AccordionSummary, Box, Icon, TableContainer, Typography,} from '@mui/material';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {CustomTable} from '../../../../utils/components';
import UtilHelper from '../../../../utils/UtilHelper';

/**
 * function to render product list
 * @returns
 */
const ProductList = ({order, clickedProductList}) => {
  const [customExpanded, setCustomExpanded] = useState(false);

  const columns = [
    {
      title: I18n.t('orders.product_list.name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({id, name}) => {
        return (
          <Box>
            <Typography variant="body2">
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('orders.product_list.quantity_label'),
      field: 'quantity',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '15%',
      render: ({quantity}) => {
        return (
          <Typography variant="body2">{quantity ? quantity : 0}</Typography>
        );
      },
    },
    {
      title: I18n.t('orders.product_list.cost_per_product_label'),
      field: 'cost',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '15%',
      render: ({cost}) => {
        return <Typography variant="body2">{cost ? cost : 0}</Typography>;
      },
    },
    {
      title: I18n.t('orders.product_list.tax_per_product_label'),
      field: 'tax',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '15%',
      render: ({tax}) => {
        return <Typography variant="body2">{tax ? tax : 0}</Typography>;
      },
    },
    {
      title: I18n.t('orders.product_list.total_cost_label'),
      field: 'total',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '15%',
      render: ({total}) => {
        return <Typography variant="body2">{total}</Typography>;
      },
    },
  ];

  return (
    <Box>
      <Accordion
        expanded={order?.id === clickedProductList && customExpanded}
        onChange={(e, expanded) => setCustomExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<Icon style={{color: '#bcccdc'}}>expand_more</Icon>}
        >
          <Typography>
            {I18n.t('orders.product_list.products_lable')} {`(${order?.product?.length || '0'})`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer>
            <CustomTable
              columns={columns}
              data={order?.product || []}
              hidePaging={true}
              hideBorderShadow={true}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
