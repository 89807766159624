import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get orders
 * @param {*} data: data for server request
 * @returns
 */
export const getOrdersRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.EVENT_BOOKINGS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to update order
 * @param {*} id: id
 * @param {*} data: data
 * @returns
 */
export const updateOrderRequest = (id, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.ORDER}/${id}`,
      'patch',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Order details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to download orders data
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const downloadOrdersRequest = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.ORDERS_EXPORT}?searchData={"orderDate":{"startDate":"${startDate}","endDate":"${endDate}"}}`;

    return new HttpRequest(dispatch, url, 'get').download().then(
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `orders_${startDate}_${endDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch(
          showSnackbar(
            'File downloaded successfully.',
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
      },
      (error) => {
        dispatch(hideLoader());
        if (error.response.data.error) {
          dispatch(
            showSnackbar(error.response.data.error, SNACKBAR_VARIAINT.ERROR)
          );
        } else {
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
        }
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to cancel order
 * @param {*} id: id
 * @param {*} data: data
 * @returns
 */
export const cancelOrderRequest = (id) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.ORDER}/${id}`, 'delete')
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Order Cancelled successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );

          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          if (error?.response?.data.error) {
            dispatch(
              showSnackbar(error?.response?.data.error, SNACKBAR_VARIAINT.ERROR)
            );
          } else {
            dispatch(
              showSnackbar(
                `Something went wrong, please try again`,
                SNACKBAR_VARIAINT.ERROR
              )
            );
          }
          return Promise.reject(error);
        }
      );
  };
};
