import ThemeOne from '../theme.json';

const MuiMenu = {
  styleOverrides: {
    paper: {
      margin: '2px 0 4px 0px !important',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25) !important',
      border: 'solid 0.5px #283745 !important',
      backgroundColor: `${ThemeOne.primaryColor} !important`,
      minWidth: '108px !importabt',
    },
    list: {
      padding: 0,
    },
  },
};

export default MuiMenu;
