import {makeStyles} from '@mui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 1,
      padding: '24px 0px 24px 57px',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth - 57,
    },
  };
});
