const MuiDivider = {
  styleOverrides: {
    root: {
      backgroundColor: '#3a434c',
      fontFamily: 'Lato',
    },
  },
};

export default MuiDivider;
