import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ForceResetPassword, ForgotPassword, ResetPassword, SignIn,} from './modules/Authentication/components';
import {DashboardLayout} from './modules/Dashboard/components';
import Home from './modules/Home/Home';
import {PageNotFound} from './utils/components';
import {ROUTE_ACTIONS} from './utils/Constants';

/**
 * function to render components based on different routes
 * @returns
 */
const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTE_ACTIONS.HOME} component={Home}/>

        <Route
          exact
          path={ROUTE_ACTIONS.SIGNIN}
          component={(props) => <SignIn {...props} />}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.FORGOT_PASSWORD}
          component={(props) => <ForgotPassword {...props} />}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.RESET_PASSWORD}
          component={(props) => <ResetPassword {...props} />}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.FORCE_RESET_PASSWORD}
          component={(props) => <ForceResetPassword {...props} />}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.CHANGE_PASSWORD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Change Password"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.USERS}
          component={(props) => <DashboardLayout {...props} menuName="Users"/>}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Vendors"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Product"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Product"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_PRODUCTS_PRODUCTS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Product Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_SURVEYS_SURVEYS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Survey"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_SURVEYS_SURVEYS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Survey"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_SURVEYS_SURVEYS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Survey Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Event"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Event"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_EVENTS_EVENTS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Event Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.VENDORS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Vendor Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.WHISKEY_TUBE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Whiskey Tube"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.PRODUCTS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Products"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.PRODUCTS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Product"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.PRODUCTS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Product"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.PRODUCTS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Product Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.EVENTS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Events"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.EVENTS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Event"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.EVENTS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Event"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.EVENTS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Event Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.SURVEYS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Surveys"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.SURVEYS_ADD}
          component={(props) => (
            <DashboardLayout {...props} menuName="Add Survey"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.SURVEYS_UPDATE}
          component={(props) => (
            <DashboardLayout {...props} menuName="Update Survey"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.SURVEYS_DETAILS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Survey Details"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.BRANDS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Brands"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.CUISINES}
          component={(props) => (
            <DashboardLayout {...props} menuName="Cuisines"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.SPIRITS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Spirits"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.ORDERS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Orders"/>
          )}
        />

        <Route
          exact
          path={ROUTE_ACTIONS.EVENT_BOOKINGS}
          component={(props) => (
            <DashboardLayout {...props} menuName="Event Bookings"/>
          )}
        />

        <Route
          path={ROUTE_ACTIONS.PUBLIC_POST}
          component={(props) => (
            <Home {...props} menuName="Public Post"/>
          )}
        />

        <Route
          path={ROUTE_ACTIONS.PUBLIC_TUBE}
          component={(props) => (
            <Home {...props} menuName="Public Tube"/>
          )}
        />

        <Route component={PageNotFound}/>
      </Switch>
    </Router>
  );
};

export default AppRoutes;
