import palette from '../palette';

const MuiListItemIcon = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
      minWidth: '35px !important',
    },
  },
};

export default MuiListItemIcon;
