const MuiInputBase = {
  styleOverrides: {
    input: {
      '&.Mui-disabled': {
        color: '#8b98a4 !important',
      },
    },
  },
};

export default MuiInputBase;
