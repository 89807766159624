import {Box, Grid, Icon, Paper, Tooltip, Typography} from '@mui/material';
import React from 'react';
import ModalImage from 'react-modal-image';
import {I18n} from 'react-redux-i18n';
import ShowMore from 'react-show-more-button';
import {Multiselect} from '../../../../utils/components';
import {useStyles} from './styles';

/**
 * function to render spirit card
 * @param {*} spirit: spirit
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @param {*} onActivateDeactivate: function to handle activate or deactivate
 * @param recommendedWeatherList
 * @param recommendedTimingList
 * @param recommendedForMoodList
 * @param recommendedCuisineList
 * @returns
 */
const SpiritCard = ({
                      spirit,
                      handleCardEditButtonClick,
                      onActivateDeactivate,
                      recommendedWeatherList,
                      recommendedTimingList,
                      recommendedForMoodList,
                      recommendedCuisineList,
                    }) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display="inline-block">
              <Typography classes={{root: classes.titleText}}>
                {spirit?.name}
              </Typography>
            </Box>

            <Box display="inline-block">
              <Typography classes={{root: classes.titleText}}>
                Flavour:{' '}
                {spirit?.flavour?.length > 0 ? spirit?.flavour[0] : 'NA'}
              </Typography>
            </Box>

            <Box display="inline-block">
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{textAlign: 'right'}}
          >
            <Tooltip
              title={I18n.t('spirit.spirit_card.edit_tooltip')}
              arrow
              placement="top"
            >
              <Icon
                classes={{root: classes.actionIcon}}
                onClick={() => handleCardEditButtonClick(spirit?.id)}
              >
                edit
              </Icon>
            </Tooltip>
            <Tooltip
              title={
                spirit?.enabled
                  ? I18n.t('spirit.spirit_card.disable_tooltip')
                  : I18n.t('spirit.spirit_card.enable_tooltip')
              }
              arrow
              placement="top"
            >
              <Icon
                classes={{root: classes.actionIcon}}
                onClick={() =>
                  onActivateDeactivate(
                    spirit?.id,
                    spirit?.enabled,
                    spirit?.name
                  )
                }
              >
                {!spirit?.enabled ? 'lock_open_rounded' : 'lock_rounded'}
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <ModalImage
              showRotate={false}
              hideDownload
              className={classes.modalImage}
              small={spirit?.imageUrl}
              medium={spirit?.imageUrl}
              large={spirit?.imageUrl}
              alt=""
            />
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <ShowMore
              maxHeight={150}
              backgroundColor="trasparent"
              classNameButton={classes.showMoreLessButton}
              classNameButtonDiv={classes.showMoreLessButtonContainer}
            >
              <Box>
                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{root: classes.recommendedTextLabel}}
                    >
                      {I18n.t('spirit.spirit_card.recommended_weather_label')}:
                    </Typography>
                  </Box>
                  <Box minHeight="38px">
                    <Multiselect
                      disabled={true}
                      label={``}
                      name="recommendedWeather"
                      optionsList={
                        recommendedWeatherList ? recommendedWeatherList : []
                      }
                      value={
                        spirit?.recommendedWeather
                          ? spirit?.recommendedWeather
                          : []
                      }
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) => {

                      }}
                    />
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{root: classes.recommendedTextLabel}}
                    >
                      {I18n.t('spirit.spirit_card.recommended_timing_label')}:
                    </Typography>
                  </Box>
                  <Box minHeight="38px">
                    <Multiselect
                      disabled={true}
                      label={``}
                      name="recommendedTiming"
                      optionsList={
                        recommendedTimingList ? recommendedTimingList : []
                      }
                      value={
                        spirit?.recommendedTiming
                          ? spirit?.recommendedTiming
                          : []
                      }
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) => {

                      }}
                    />
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{root: classes.recommendedTextLabel}}
                    >
                      {I18n.t('spirit.spirit_card.recommended_cuisines_label')}:
                    </Typography>
                  </Box>
                  <Box minHeight="38px">
                    <Multiselect
                      disabled={true}
                      label={``}
                      name="recommendedCuisines"
                      optionsList={
                        recommendedCuisineList ? recommendedCuisineList : []
                      }
                      value={
                        spirit?.recommendedCuisines
                          ? spirit?.recommendedCuisines
                          : []
                      }
                      selectionBasedOn="id" //name or id
                      handleChange={(event, value, name) => {

                      }}
                    />
                  </Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <Typography
                      classes={{root: classes.recommendedTextLabel}}
                    >
                      {I18n.t('spirit.spirit_card.recommended_mood_label')}:
                    </Typography>
                  </Box>
                  <Box minHeight="38px">
                    <Multiselect
                      disabled={true}
                      label={``}
                      name="recommendedForMood"
                      optionsList={
                        recommendedForMoodList ? recommendedForMoodList : []
                      }
                      value={
                        spirit?.recommendedForMood
                          ? spirit?.recommendedForMood
                          : []
                      }
                      selectionBasedOn="name" //name or id
                      handleChange={(event, value, name) => {

                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </ShowMore>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SpiritCard;
