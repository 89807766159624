import {Box, Icon, IconButton, TablePagination} from '@mui/material';
import {useTheme} from '@mui/styles';
import React from 'react';

/**
 * function to return table pagination actions
 * @param {*} props: props
 * @returns
 */
function TablePaginationActions(props) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        style={{color: 'inherit'}}
      >
        {theme.direction === 'rtl' ? (
          <Icon>last_page</Icon>
        ) : (
          <Icon>first_page</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        style={{color: 'inherit'}}
      >
        {theme.direction === 'rtl' ? (
          <Icon>keyboard_arrow_right</Icon>
        ) : (
          <Icon>keyboard_arrow_left</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        style={{color: 'inherit'}}
      >
        {theme.direction === 'rtl' ? (
          <Icon>keyboard_arrow_left</Icon>
        ) : (
          <Icon>keyboard_arrow_right</Icon>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        style={{color: 'inherit'}}
      >
        {theme.direction === 'rtl' ? (
          <Icon>first_page</Icon>
        ) : (
          <Icon>last_page</Icon>
        )}
      </IconButton>
    </Box>
  );
}

/**
 * function to render custom pagination
 * @param {*} rowsPerPageOptions: rows per page options
 * @param {*} count: count
 * @param {*} rowsPerPage: rows per page
 * @param {*} page: page
 * @param {*} onPageChange: function to handle page change
 * @param {*} onRowsPerPageChange: function to handle rows per page change
 * @returns
 */
const CustomPagination = ({
                            rowsPerPageOptions,
                            count,
                            rowsPerPage,
                            page,
                            onPageChange,
                            onRowsPerPageChange,
                          }) => {
  return (
    <>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={count ? count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Rows"
        onPageChange={(event, page) => onPageChange(page)}
        onRowsPerPageChange={(e) =>
          onRowsPerPageChange(parseInt(e.target.value, 10))
        }
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default CustomPagination;
