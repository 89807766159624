import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams, useRouteMatch} from 'react-router-dom';
import {MessageModal} from '../../../../utils/components';
import {ROUTE_ACTIONS} from '../../../../utils/Constants';
import {deleteSurveyQuestionRequest, getSurveyQuestionsRequest, getSurveyRequest,} from '../../SurveyApiActions';
import {QuestionForm, QuestionItem} from '../index';
import {useStyles} from './styles';

/**
 * function to render event detailed view
 * @param {*} history: history object
 * @param getSurvey
 * @param getSurveyQuestions
 * @param deleteSurveyQuestion
 * @returns
 */
const SurveyDetails = ({
                         history,
                         getSurvey,
                         getSurveyQuestions,
                         deleteSurveyQuestion,
                       }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const {vendorId, surveyId} = useParams();
  const modalRef = useRef();

  const [surveyInfo, setSurveyInfo] = useState(null);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showQuestionFormDialog, setShowQuestionFormDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionList, setQuestionList] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  const getSurveyDetails = async (surveyId) => {
    const {data} = await getSurvey(surveyId);
    setSurveyInfo(data);
  };

  useEffect(() => {
    getSurveyDetails(surveyId).then(r => {});

  }, [surveyId]);

  const getQuestionList = async (surveyId) => {
    const {data} = await getSurveyQuestions(surveyId);
    setQuestionList(data);
  };

  useEffect(() => {
    getQuestionList(surveyId).then(r => {});

  }, [surveyId]);

  useEffect(() => {
    if (refreshList) {
      getQuestionList(surveyId).then(r => {});
      setRefreshList(false);
    }

  }, [surveyId, refreshList]);

  /**
   * function to handle edit icon click
   */
  const onEditEvent = () => {
    history.push(`${match.url}/update`);
  };

  /**
   * function to handle form close
   */
  const handleCloseQuestionForm = () => {
    setShowQuestionForm(false);
  };

  /**
   * function to handle delete question
   * @param {*} questionId: question id
   * @param {*} questionTitle: question title
   */
  const onDeleteQuestion = (questionId, questionTitle) => {
    const title = I18n.t('survey.confirmation_dialog.delete_question');
    const message = I18n.t(
      'survey.confirmation_dialog.delete_question_message',
      {title: questionTitle}
    );
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          flag: true,
        };
        await deleteSurveyQuestion(questionId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle question form dialog open
   */
  const handleQuestionFormDialogOpen = () => {
    setShowQuestionFormDialog(true);
  };

  /**
   * function to handle form dialog close
   */
  const handleCloseQuestionFormDialog = () => {
    setShowQuestionFormDialog(false);
  };

  /**
   * function to handle edit question icon click
   * @param {*} questionInfo: question info
   */
  const handleEditIconClick = (questionInfo) => {
    setSelectedQuestion(questionInfo);
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center">
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title="Back">
                    <Icon
                      onClick={() => {
                        if (vendorId) {
                          history.replace(
                            `${ROUTE_ACTIONS.VENDORS}/${vendorId}`
                          );
                        } else {
                          history.replace(ROUTE_ACTIONS.SURVEYS);
                        }
                      }}
                    >
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant="h6">
                    {I18n.t('survey.survey_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip title="Survey Responses">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Icon>poll</Icon>{' '}
                    <Typography variant="body2">
                      {surveyInfo?.responseCount}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box>
                  <Tooltip
                    placement="top"
                    title={I18n.t(
                      'survey.survey_details.survey_edit_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{root: classes.actionIcon}}
                      onClick={() => onEditEvent(surveyId)}
                    >
                      edit_rounded
                    </Icon>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('survey.survey_details.field_name_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{surveyInfo?.name}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('survey.survey_details.field_vendor_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Chip
                    variant="outlined"
                    avatar={
                      <Avatar
                        alt="vendor"
                        src={surveyInfo?.Vendor?.profileImgUrl}
                      />
                    }
                    label={surveyInfo?.Vendor?.name}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('survey.survey_details.field_duration_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {moment(surveyInfo?.startDate).format('DD-MM-YYYY')} to{' '}
                    {moment(surveyInfo?.endDate).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box mt={2} display="flex" alignItems="center">
        <Box>
          <Typography>Questions</Typography>
        </Box>
        {!showQuestionForm && (
          <Tooltip title="Add Question" placement="right">
            <Box>
              <IconButton onClick={() => setShowQuestionForm(true)}>
                <Icon>add_circle</Icon>
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>

      {showQuestionForm && (
        <Box mt={2}>
          <QuestionForm
            handleCloseQuestionForm={handleCloseQuestionForm}
            setRefreshList={setRefreshList}
          />
        </Box>
      )}

      {questionList?.rows?.map((question) => (
        <Box key={question.id} mt={2}>
          <QuestionItem
            question={question}
            onDeleteQuestion={onDeleteQuestion}
            handleQuestionFormDialogOpen={handleQuestionFormDialogOpen}
            handleEditIconClick={handleEditIconClick}
          />
        </Box>
      ))}

      {showQuestionFormDialog && (
        <Dialog fullWidth maxWidth="md" open={showQuestionFormDialog}>
          <DialogTitle>Update Question</DialogTitle>
          <QuestionForm
            handleCloseQuestionForm={handleCloseQuestionFormDialog}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            setRefreshList={setRefreshList}
          />
        </Dialog>
      )}

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSurvey: async (id) => {
      try {
        return await dispatch(getSurveyRequest(id));
      } catch (error) {
        throw error;
      }
    },

    getSurveyQuestions: async (surveyId) => {
      try {
        return await dispatch(getSurveyQuestionsRequest(surveyId));
      } catch (error) {
        throw error;
      }
    },

    deleteSurveyQuestion: async (questionId, data) => {
      try {
        return await dispatch(deleteSurveyQuestionRequest(questionId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails);
