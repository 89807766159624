const MuiTimeline = {
  styleOverrides: {
    root: {
      marginTop: '0px',
      fontFamily: 'Lato',
    },
  },
};

export default MuiTimeline;
