import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {CustomErrorComponent} from 'custom-error';
import React, {useEffect, useRef, useState} from 'react';
import FileViewer from 'react-file-viewer';
import 'react-image-gallery/styles/css/image-gallery.css';
import ReactPlayer from 'react-player';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useRouteMatch} from 'react-router-dom';
import {CustomFilePreviewSA} from '../../../../utils/components';
import {MessageModal} from '../../../../utils/components';
import {
  activateDeactivateProductRequest,
  archiveUnarchiveProductRequest,
  getProductRequest,
  inOutProductStockRequest,
} from '../../ProductApiActions';
import './ProductDetails.css';
import {useStyles} from './styles';

/**
 * function to render product detailed view
 * @param {*} history: history object
 * @param {*} getProduct: function to get product details
 * @param activateDeactivateProduct
 * @param archiveUnarchiveProduct
 * @param inOutProductStock
 * @returns
 */
const ProductDetails = ({
                          history,
                          getProduct,
                          activateDeactivateProduct,
                          archiveUnarchiveProduct,
                          inOutProductStock,
                        }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const modalRef = useRef();
  const productId = match.params.productId;
  const [product, setProduct] = useState(null);

  const getProductDetails = async (productId) => {
    const res = await getProduct(productId);

    let data = {
      id: res.data.id,
      soldCount: res.data.soldCount,
      saveCount: res.data.savedCount,
      enabled: res.data.enabled,
      archive: res.data.archive,
      outOfStock: res.data.outOfStock,
      name: res.data.name,
      cost: res.data.cost,
      category: res.data?.ProductCategory?.name,
      vendor: res.data.Vendor,
      isProductAvailableInSizes: res.data?.isProductAvailableInSizes
        ? 'yes'
        : 'no',
      size: {
        S: res.data?.size ? res.data?.size['S'] : false,
        M: res.data?.size ? res.data?.size['M'] : false,
        L: res.data?.size ? res.data?.size['L'] : false,
        XL: res.data?.size ? res.data?.size['XL'] : false,
        XXL: res.data?.size ? res.data?.size['XXL'] : false,
        'Free Size': res.data?.size ? res.data?.size['Free Size'] : false,
      },
      isAvailable: {
        S: res.data?.isAvailable ? res.data?.isAvailable['S'] : '',
        M: res.data?.isAvailable ? res.data?.isAvailable['M'] : '',
        L: res.data?.isAvailable ? res.data?.isAvailable['L'] : '',
        XL: res.data.isAvailable ? res.data?.isAvailable['XL'] : '',
        XXL: res.data?.isAvailable ? res.data?.isAvailable['XXL'] : '',
        'Free Size': res.data?.isAvailable
          ? res.data?.isAvailable['Free Size']
          : '',
      },
      description: res.data.description,
      mediaUrls: [],
    };

    res.data?.media.forEach((mediaItem) => {
      const fileExtension = mediaItem.type.split('.').pop();
      let tempObj = {
        type: fileExtension,
        path: mediaItem.path,
        fileName: mediaItem.fileName,
        fileType: mediaItem.type,
        url: mediaItem.readSignedURL,
        original: mediaItem.readSignedURL,
        thumbnail: mediaItem.readSignedURL,
      };

      if (fileExtension === 'pdf') {
        tempObj.renderItem = (props) => {
          return (
            <FileViewer
              fileType={props.type}
              filePath={props.url}
              errorComponent={CustomErrorComponent}
            />
          );
        };
        tempObj.renderThumbInner = () => {
          return 'pdf';
        };
      } else if (fileExtension === 'mp4') {
        tempObj.renderItem = (props) => {
          return <ReactPlayer playing={true} url={props.url}/>;
        };
        tempObj.renderThumbInner = () => {
          return <Icon>movie</Icon>;
        };
      } else {
      }
      data.mediaUrls.push(tempObj);
    });

    setProduct(data);
  };

  useEffect(() => {
    getProductDetails(productId);

  }, [productId]);

  /**
   * function to handle edit click
   */
  const onEditProduct = () => {
    const url = `${match.url}/update`;
    history.push(url);
  };

  /**
   * function to activate deactivate product
   * @param productId
   * @param {*} enabled: enabled
   * @param {*} name: name
   */
  const onActivateDeactivate = (productId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} Product`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          enabled: !enabled,
        };
        await activateDeactivateProduct(productId, data);
        setProduct({
          ...product,
          enabled: !enabled,
        });
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle archive or unarchive
   * @param productId
   * @param archive
   * @param {*} name: name
   */
  const onArchiveUnarchive = (productId, archive, name) => {
    const title = `${!archive ? 'Archive' : 'Unarchive'} Product`;
    const message = `Are you sure you want to ${
      !archive ? 'archive' : 'unarchive'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          archive: !archive,
        };
        await archiveUnarchiveProduct(productId, data);
        setProduct({
          ...product,
          archive: !archive,
        });
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle mark out of stock
   * @param productId
   * @param outOfStock
   * @param {*} name: name
   */
  const onMarkOutOfStock = (productId, outOfStock, name) => {
    const title = `${!outOfStock ? 'Mark out of stock' : 'Mark in stock'}`;
    const message = `Are you sure you want to ${
      !outOfStock ? 'mark out of stock' : 'mark in stock'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          outOfStock: !outOfStock,
        };

        await inOutProductStock(productId, data);
        setProduct({
          ...product,
          outOfStock: !outOfStock,
        });
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center">
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title="Back" placement="top" arrow>
                    <Icon
                      onClick={() => history.goBack()}
                      classes={{root: classes.actionIcon}}
                    >
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant="h6">
                    {I18n.t('product.product_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip title="Sold">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Icon>inventory_2</Icon>{' '}
                    <Typography variant="body2">
                      {product?.soldCount}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Saved">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Icon>bookmark</Icon>{' '}
                    <Typography variant="body2">
                      {product?.saveCount}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box>
                  <Tooltip
                    placement="top"
                    arrow
                    title={I18n.t(
                      'product.product_details.event_edit_tooltip_title'
                    )}
                  >
                    <Icon
                      classes={{root: classes.actionIcon}}
                      onClick={() => onEditProduct(productId)}
                    >
                      edit_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement="top"
                    arrow
                    title={!product?.enabled ? 'Enable' : 'Disbale'}
                  >
                    <Icon
                      classes={{root: classes.actionIcon}}
                      onClick={() => {
                        onActivateDeactivate(
                          product?.id,
                          product?.enabled,
                          product?.name
                        );
                      }}
                    >
                      {!product?.enabled ? 'lock_open' : 'lock'}
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      !product?.archive
                        ? I18n.t(
                        'product.product_details.event_archive_tooltip_title'
                        )
                        : 'Unarchive'
                    }
                  >
                    <Icon
                      classes={{root: classes.actionIcon}}
                      onClick={() => {
                        onArchiveUnarchive(
                          product?.id,
                          product?.archive,
                          product?.name
                        );
                      }}
                    >
                      {!product?.archive ? 'archive' : 'unarchive'}
                    </Icon>
                  </Tooltip>
                </Box>

                <Box ml={1}>
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      !product?.outOfStock
                        ? I18n.t(
                        'product.product_details.event_out_of_stock_tooltip_title'
                        )
                        : 'Mark In Stock'
                    }
                  >
                    <Icon
                      classes={{root: classes.actionIcon}}
                      onClick={() => {
                        onMarkOutOfStock(
                          product?.id,
                          product?.outOfStock,
                          product?.name
                        );
                      }}
                    >
                      {!product?.outOfStock
                        ? 'remove_shopping_cart'
                        : 'shopping_cart'}
                    </Icon>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('product.product_details.product_field_name_label')}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{product?.name}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('product.product_details.product_field_cost_label')}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{product?.cost}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t(
                      'product.product_details.product_field_category_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Chip variant="outlined" label={product?.category}/>
                </Box>
              </Box>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t(
                      'product.product_details.product_field_vendor_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <Chip
                    variant="outlined"
                    avatar={
                      <Avatar
                        alt="vendor"
                        src={
                          product?.vendor?.profileImgUrl
                            ? product?.vendor?.profileImgUrl
                            : ''
                        }
                      />
                    }
                    label={product?.vendor?.name}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl component="fieldset" disabled>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="body1">
                      Is the product available in sizes?
                    </Typography>
                  </Box>
                </Box>

                {product && (
                  <>
                    <RadioGroup
                      row
                      value={product?.isProductAvailableInSizes}
                      defaultValue={product?.isProductAvailableInSizes}
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            color="secondary"
                            classes={{
                              disabled: classes.checkboxRadioDisabled,
                            }}
                          />
                        }
                        label="Yes"
                        classes={{
                          disabled: classes.checkboxRadioLabelDisabled,
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            color="secondary"
                            classes={{
                              disabled: classes.checkboxRadioDisabled,
                            }}
                          />
                        }
                        label="No"
                        classes={{
                          disabled: classes.checkboxRadioLabelDisabled,
                        }}
                      />
                    </RadioGroup>
                  </>
                )}
              </FormControl>
            </Grid>

            {product?.isProductAvailableInSizes === 'yes' && (
              <>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Box mb={2.5}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography>
                          {I18n.t(
                            'product.product_details.product_field_sizes_label'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography>
                          {I18n.t(
                            'product.product_details.product_field_is_available_label'
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                          borderBottom="1px solid #334e68"
                          width="76%"
                          mt={1.2}
                          mb={1}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <FormControl
                          component="fieldset"
                          variant="standard"
                          disabled
                        >
                          <FormGroup>
                            {product &&
                            Object.entries(product?.size).map(
                              ([key, value], index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      color="secondary"
                                      name={key}
                                      checked={value}
                                      classes={{
                                        disabled:
                                        classes.checkboxRadioDisabled,
                                      }}
                                    />
                                  }
                                  label={key}
                                  classes={{
                                    disabled:
                                    classes.checkboxRadioLabelDisabled,
                                  }}
                                />
                              )
                            )}
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <FormControl component="fieldset" disabled>
                          {product &&
                          Object.entries(product?.isAvailable).map(
                            ([key, value], index) => (
                              <RadioGroup key={index} row value={value}>
                                <FormControlLabel
                                  value="yes"
                                  control={
                                    <Radio
                                      color="secondary"
                                      classes={{
                                        disabled:
                                        classes.checkboxRadioDisabled,
                                      }}
                                    />
                                  }
                                  label={I18n.t(
                                    'product.product_details.product_field_is_available_yes_label'
                                  )}
                                  classes={{
                                    disabled:
                                    classes.checkboxRadioLabelDisabled,
                                  }}
                                />
                                <FormControlLabel
                                  value="no"
                                  control={
                                    <Radio
                                      color="secondary"
                                      classes={{
                                        disabled:
                                        classes.checkboxRadioDisabled,
                                      }}
                                    />
                                  }
                                  label={I18n.t(
                                    'product.product_details.product_field_is_available_no_label'
                                  )}
                                  classes={{
                                    disabled:
                                    classes.checkboxRadioLabelDisabled,
                                  }}
                                />
                              </RadioGroup>
                            )
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box mb={2.5}/>
                </Grid>
              </>
            )}

            {product?.description && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box mb={2.5}>
                  <Box>
                    <Typography variant="body1">
                      {I18n.t(
                        'product.product_details.product_field_description_label'
                      )}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="body2">
                      {product?.description}
                    </Typography>
                  </Box>
                  <Box>
                    <Box borderBottom="1px solid #334e68" mt={2.5}/>
                  </Box>
                </Box>
              </Grid>
            )}

            {product && product.mediaUrls && product.mediaUrls.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box mb={2.5}>
                  <Typography variant="body1">
                    {I18n.t(
                      'product.product_details.product_field_media_label'
                    )}
                  </Typography>
                </Box>

                <CustomFilePreviewSA files={product.mediaUrls}/>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      <MessageModal ref={modalRef}/>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: async (productId) => {
      try {
        return await dispatch(getProductRequest(productId));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateProduct: async (productId, data) => {
      try {
        return await dispatch(
          activateDeactivateProductRequest(productId, data)
        );
      } catch (error) {
        throw error;
      }
    },

    archiveUnarchiveProduct: async (productId, data) => {
      try {
        return await dispatch(archiveUnarchiveProductRequest(productId, data));
      } catch (error) {
        throw error;
      }
    },

    inOutProductStock: async (productId, data) => {
      try {
        return await dispatch(inOutProductStockRequest(productId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
