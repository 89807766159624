import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    customGridContainer: {},

    customGridItem: {
      display: 'flex !important',
      alignItems: 'flex-end !important',
      minWidth: 'fit-content !important',
    },

    avatarContainer: {
      cursor: 'pointer',
    },
  };
});
