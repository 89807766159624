import Auth from '../../utils/Auth';
import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import Constants, {API_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import UtilHelper from '../../utils/UtilHelper';
import {changePassword, signIn} from './AuthenticationActions';

/**
 * function to make server request to handle sign in
 * @param {*} credentials: user credentials object
 * @returns
 */
export const signInRequest = (credentials) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, API_ACTIONS.LOGIN, 'post', {
      username: credentials.username,
      password: credentials.password,
      browserVersion: credentials.browserVersion,
      appVersion: credentials.appVersion,
      OSVersion: credentials.OSVersion,
    })
      .send()
      .then(
        (res) => {
          let {user, access_token} = res.data.data;

          const userRoles = {
            isSystemSuperAdmin: UtilHelper.checkRoleAvalibility(
              user.Roles,
              Constants.USER_ROLES.SUPER_ADMIN
            ),
          };
          user.userRoles = userRoles;
          res.data.data.userRoles = userRoles;
          res.data.data.vendorId = user.VendorId;
          const userObj = {
            userRoles: userRoles,
            Roles: user.Roles,
            displayName: user.displayName,
            id: user.id,
            profileImageURL: user.profileImageURL,
            username: user.username,
            phone: user.phone,
            isSystemGeneratedPassword: user.isSystemGeneratedPassword,
            email: user.email,
            vendorId: user.VendorId
          };
          Auth.setToken(access_token);
          Auth.login(userObj);
          dispatch(signIn(res.data.data));
          dispatch(hideLoader());
          return Promise.resolve();
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to handle forgot password
 * @param {*} data: data for server request
 * @returns
 */
export const forgotPasswordRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, API_ACTIONS.FORGOT_PASSWORD, 'post', {
      email: data.email,
    })
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            res.data.data.message,
            Constants.SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((err) => {
        dispatch(hideLoader());
        return Promise.reject(err.response.data.error);
      });
  };
};

/**
 * function to make server request to handle reset password request
 * @param {*} password: password
 * @param {*} userId: user id
 * @param {*} token: token
 * @returns
 */
export const resetPasswordRequest = (password, userId, token) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.RESET_PASSWORD}/${userId}/${token}`,
      'post',
      {
        password,
      }
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            res.data.data.message,
            Constants.SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((err) => {
        dispatch(hideLoader());
        return Promise.reject(err.response.data.error);
      });
  };
};

/**
 * function to make server request to handle change password request
 * @param {*} data: data for server request
 * @returns
 */
export const changePasswordRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, API_ACTIONS.SET_PASSWORD, 'post', data)
      .send()
      .then((res) => {
        Auth.setSystemGeneratedPassword(false);
        dispatch(changePassword(res.data));
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(hideLoader());
      });
  };
};

/**
 * function to make server request to handle update password
 * @param {*} newPassword: new password
 * @param {*} currentPassword: current password
 * @returns
 */
export const updatePasswordRequest = (newPassword, currentPassword) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, API_ACTIONS.CHANGE_PASSWORD, 'post', {
      currentPassword,
      newPassword,
    })
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            res.data.data.message,
            Constants.SNACKBAR_VARIAINT.SUCCESS
          )
        );
        return Promise.resolve(res.data.data);
      })
      .catch((err) => {
        dispatch(hideLoader());
        return Promise.reject(err.response.data.error);
      });
  };
};

/**
 * function to make server request to handle logout user
 * @returns
 */
export const logoutUserRequest = () => {
  const data = {
    deviceToken: '',
  };
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, API_ACTIONS.LOGOUT, 'delete', data)
      .send()
      .then((res) => {
        return Promise.resolve(res.data.data);
      })
      .catch((err) => {
        return Promise.reject(err.response);
      });
  };
};
