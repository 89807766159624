const MuiPickersDay = {
  styleOverrides: {
    root: {
      color: `#fff !important`,
      backgroundColor: `#33393e !important`,
      '&.Mui-selected	': {
        color: `#fff !important`,
        backgroundColor: '#d0ac74 !important',
      },
      '&.Mui-disabled	': {
        color: `#7d8c9a !important`,
        // backgroundColor: '#cccccc !important',
      },
    },

    today: {
      // color: `${Palette.primary.main} !important`,
      // backgroundColor: `${Palette.primary.light} !important`,
      '&.Mui-selected	': {
        // backgroundColor: `#d0ac74 !important`,
      },
      '&.Mui-disabled	': {
        // color: `${Palette.common.fontColor} !important`,
        // backgroundColor: `#dcdcdc !important`,
      },
    },
  },
};

export default MuiPickersDay;
