const MuiListItemText = {
  styleOverrides: {
    root: {
      fontSize: '14px !important',
      fontFamily: 'Lato',
      fontWeight: '600 !important',
    },
  },
};

export default MuiListItemText;
