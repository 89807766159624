import {Avatar, Box, Grid, Icon, Paper, Tooltip, Typography,} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {useStyles} from './styles';

/**
 * function to render end user card
 * @param {*} user: user details
 * @param {*} onActivateDeactivate: function to activate or deactivate
 * @returns
 */
const EndUserCard = ({user, onActivateDeactivate}) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box display="inline-block">
              <Box display="flex" alignItems="center" width="300px">
                <Box>
                  <Avatar src={user?.profileImageURL} alt="avatar"/>
                </Box>
                <Box ml={1.5}>
                  <Typography>
                    {user?.displayName ? user?.displayName : 'NA'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="inline-block" width="350px">
              <Box display="flex" alignItems="center" textAlign="left">
                <Box mr={1} mt={0.5}>
                  <Icon>email</Icon>
                </Box>
                <Typography>{user?.email ? user?.email : 'NA'}</Typography>
              </Box>
            </Box>
            <Box display="inline-block">
              <Box display="flex" alignItems="center">
                <Box mr={1} mt={0.5}>
                  <Icon>phone</Icon>
                </Box>
                <Typography>{user?.phone}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{textAlign: 'right'}}
          >
            <Tooltip
              title={
                user.enabled
                  ? I18n.t('user.end_user_card.tooltip_deactivate')
                  : I18n.t('user.end_user_card.tooltip_activate')
              }
              arrow
              placement="top"
            >
              <Icon
                classes={{root: classes.actionIcon}}
                onClick={() =>
                  onActivateDeactivate(
                    user?.id,
                    user?.enabled,
                    user?.displayName
                  )
                }
              >
                {user.enabled ? 'lock' : 'lock_open'}
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box display="flex">
              <Tooltip title="Posts">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>post_add</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.postCount}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Comments">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>comment</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.commentCount}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Product Purchased">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>inventory_2</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.productPurchaseCount}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Event Attended">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>event</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.eventAttendCount}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Surveys Undertaken">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>poll</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.surveyCount}
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip title="Parties Planned">
                <Box display="flex" alignItems="center" mr={2}>
                  <Icon>liquor</Icon>{' '}
                  <Typography classes={{root: classes.countText}}>
                    {user?.partyPlannedCount}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Typography>
                  {I18n.t('user.end_user_card.joined_on')}{' '}
                  {moment(user?.createdAt).format('DD-MM-YYYY')}
                </Typography>
              </Box>
              <Box ml={5}>
                <Typography>
                  {I18n.t('user.end_user_card.last_active_on')}{' '}
                  {user?.lastLoginAt
                    ? moment(user?.lastLoginAt).format('DD-MM-YYYY')
                    : 'NA'}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default EndUserCard;
