import {Button, Grid, Typography} from '@mui/material';
import React from 'react';
import './UnAuthorized.css';

export default function UnAuthorized(props) {
  return (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        spacing={0}
        alignItems="center"
        style={{minHeight: '90vh'}}
      >
        <Grid key={'value'} item>
          <Typography className="number-container" color="primary">
            <span className="four">4</span>
            <span className="zero">0</span>
            <span className="three">3</span>
          </Typography>
          <div className="page-not-found">
            <Typography variant="h6">Access Denied</Typography>
            <Typography variant="h4">
              You dont have permission to view this url.
            </Typography>
          </div>
          <div className="page-not-found">
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              className={'text-transform-capitalize back-to-home-button'}
              onClick={() => (window.location = '/')}
            >
              Back to Home
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
