import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    checkboxRadioDisabled: {
      color: `${theme.palette.secondary.main} !important`,
    },
    checkboxRadioLabelDisabled: {
      color: `${theme.palette.text.primary} !important`,
    },
    modalImage: {
      height: '80px',
      objectFit: 'contain',
    },

    actionIcon: {
      cursor: 'pointer',
    },
  };
});
