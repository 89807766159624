import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel,} from '@mui/material';
import React from 'react';
import {useStyles} from './styles';

const Multiselect = ({
                       label = 'Multiselect',
                       name,
                       optionsList = [],
                       value = [],
                       selectionBasedOn = 'name',
                       handleChange,
                       disabled = false,
                     }) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControl component="fieldset" variant="standard" disabled={disabled}>
        <FormLabel classes={{root: classes.formLabelRoot}}>{label}</FormLabel>

        <FormGroup row>
          {optionsList.map((option) => {
            const checked = !!value.find((item) =>
              item === option[selectionBasedOn]
            );
            return (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    color="secondary"
                    defaultChecked={checked}
                    checked={checked}
                    onChange={(event) =>
                      handleChange(event, option[selectionBasedOn], name)
                    }
                    classes={{
                      disabled: disabled ? classes.checkboxRadioDisabled : '',
                    }}
                  />
                }
                label={option.name}
                classes={{
                  disabled: disabled ? classes.checkboxRadioLabelDisabled : '',
                }}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default Multiselect;
