import {
  Box,
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {AvatarCell, CustomTable, HtmlTooltip, MessageModal,} from '../../../../utils/components';
import {DateRangeFilter, InputFilter, RadioButtonFilter,} from '../../../../utils/components/Filter';
import Constants, {USER_ROLES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {activateDeactivateUserRequest, getUsersRequest, resendInvitationRequest,} from '../../UsersApiActions';
import {VendorAdminForm} from '../index';

/**
 * function to render vendor admin list
 * @param {*} getVendorAdminUsers: function to get vendor admin users
 * @param {*} activateDeactivateUser: function to activate deactivate user
 * @param {*} resendInvitation: function to resend invitation
 * @param vendorInfo
 * @returns
 */
const VendorAdminList = ({
                           getVendorAdminUsers,
                           activateDeactivateUser,
                           resendInvitation,
                           vendorInfo,
                         }) => {
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      vendors: vendorInfo ? [vendorInfo?.name] : [],
      roleName: USER_ROLES.VENDOR,
      searchFilter: '',
      enabled: '',
      joinedDate: null,
    },
  });
  const [vendorAdminList, setVendorAdminList] = useState(null);
  const [showVendorAdminForm, setShowVendorAdminForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const modalRef = useRef();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  /**
   * function to get vendor admin list
   * @param {*} requestData: request data
   */
  const getVendorAdminList = async (requestData) => {
    try {
      const {data} = await getVendorAdminUsers(requestData);
      setVendorAdminList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getVendorAdminList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getVendorAdminList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle activate / deactivate a user
   * @param {*} userId: user id
   * @param {*} enabled: active status boolean
   * @param {*} name: user name
   */
  const onActivateDeactivate = (userId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} User`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async (note) => {
        const data = {
          enabled: !enabled,
        };
        await activateDeactivateUser(userId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to resend invitaion to a user
   * @param {*} userId: user id
   * @param {*} name: user name
   */
  const onResendInvitation = (userId, name) => {
    const title = I18n.t(
      'user.vendor_admin_table.table_column_resend_invitation_dialog_title'
    );
    const message = `Are you sure you want to resend invitation to ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: (note) => {
        resendInvitation(userId);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * handle edit menu item click
   * @param {*} selectedUserData: selected user data
   */
  const handleEditMenuItemClick = (selectedUserData) => {
    setSelectedUser(selectedUserData);
    setShowVendorAdminForm(true);
  };

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  const columns = [
    {
      title: I18n.t('user.vendor_admin_table.table_column_name_label'),
      field: 'name',
      render: ({enabled, displayName, imageURL}) => {
        return (
          <AvatarCell
            isDisabled={enabled}
            heading={displayName ? displayName : ''}
            image={imageURL}
          />
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_page_name_label'),
      field: 'vendor',
      render: ({vendor}) => {
        return (
          <AvatarCell
            heading={vendor?.vendorName || ''}
            image={vendor?.profileImgUrl || ''}
          />
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_email_label'),
      field: 'email',
      sorting: false,
      render: ({email}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(email ? email : '', 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_phone_label'),
      field: 'phone',
      sorting: false,
      width: '13%',
      render: ({phone}) => {
        return <Typography variant="body2">{phone ? phone : ''}</Typography>;
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_status_label'),
      field: 'status',
      sorting: false,
      width: '12%',
      render: ({
                 id,
                 registrationComplete,
                 displayName,
                 enabled,
                 createdAt,
               }) => {
        if (registrationComplete) {
          return (
            <Typography variant="body2" className="status-success">
              {I18n.t('user.vendor_admin_table.table_column_accepted_label')}
            </Typography>
          );
        } else {
          if (enabled) {
            return (
              <>
                <span className="status-danger">
                  {I18n.t('user.vendor_admin_table.table_column_pending_label')}
                </span>
                <HtmlTooltip
                  arrow
                  placement="top"
                  interactive
                  title={
                    <Box>
                      <Typography variant="body1">
                        {I18n.t('user.vendor_admin_table.invite_sent_label')}
                      </Typography>
                      <Typography variant="caption">
                        <b>
                          {I18n.t('user.vendor_admin_table.invited_on_label')}{' '}
                        </b>
                        {moment(createdAt).format('dddd, MMM Do, YYYY')}
                      </Typography>
                    </Box>
                  }
                >
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() =>
                      onResendInvitation(id, displayName ? displayName : '')
                    }
                  >
                    <Icon>repeat</Icon>
                  </IconButton>
                </HtmlTooltip>
              </>
            );
          } else {
            return <></>;
          }
        }
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_joined_on_label'),
      field: 'createdAt',
      width: '10%',
      render: ({createdAt}) => {
        return (
          <Typography variant="body2">
            {moment(createdAt).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('user.vendor_admin_table.table_column_action_label'),
      field: '-',
      sorting: false,
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <Tooltip title="Actions">
              <IconButton
                color="inherit"
                aria-label="option"
                aria-controls={`customized-menu-${props.id}`}
                aria-haspopup="true"
                onClick={(event) => {
                  menuRef.current = props.id;
                  handleMenuClick(event);
                }}
                className="option-menu"
              >
                <Icon fontSize="small">more_vert_rounded</Icon>
              </IconButton>
            </Tooltip>

            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleEditMenuItemClick(props);
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="small">edit_rounded</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t(
                    'user.vendor_admin_table.table_action_column_edit_tooltip_title'
                  )}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onActivateDeactivate(
                    props.id,
                    props.enabled,
                    props?.displayName ? props?.displayName : ''
                  );
                }}
              >
                <ListItemIcon>
                  {props.enabled ? (
                    <Icon fontSize="small">lock_rounded</Icon>
                  ) : (
                    <Icon fontSize="small">lock_open_rounded</Icon>
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    props.enabled
                      ? I18n.t(
                      'user.vendor_admin_table.table_action_column_deactivate_tooltip_title'
                      )
                      : I18n.t(
                      'user.vendor_admin_table.table_action_column_active_tooltip_title'
                      )
                  }
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeFilter = (elementName, selectedvalue) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = selectedvalue;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box p={2} mt={-1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box display="flex" alignItems="flex-end">
            <Box width="220px" mr={1}>
              <InputFilter
                filterName=""
                placeholder={I18n.t(
                  'user.vendor_admin_table.table_filter_search_by_name_email_phone_label'
                )}
                onChangeFilter={(value) =>
                  handleChangeDebounceFilter('searchFilter', value)
                }
              />
            </Box>

            <Box mr={1}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'user.vendor_admin_table.filter_active_status_label'
                )}
                title={I18n.t(
                  'user.vendor_admin_table.filter_active_status_label'
                )}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Box>

            <Box>
              <DateRangeFilter
                filterName={I18n.t(
                  'user.vendor_admin_table.filter_joined_date_label'
                )}
                title={I18n.t(
                  'user.vendor_admin_table.filter_joined_date_label'
                )}
                value={paginationParams.searchData.joinedDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('joinedDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => setShowVendorAdminForm(true)}
            >
              {I18n.t('user.vendor_admin_table.add_vendor_admin_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableContainer>
      {renderTableTitle()}

      <CustomTable
        columns={columns}
        data={vendorAdminList?.rows}
        hidePaging={false}
        hideBorderShadow={true}
        rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
        rowsPerPage={paginationParams.rowsPerPage}
        count={vendorAdminList?.count}
        page={paginationParams.page}
        onPageChange={(page) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = page;
          setPaginationParams(pagination);
        }}
        onRowsPerPageChange={(rowPerPage) => {
          const pagination = Object.assign({}, paginationParams);
          pagination.page = 1;
          pagination.rowPerPage = rowPerPage;
          setPaginationParams(pagination);
        }}
      />

      {showVendorAdminForm && (
        <VendorAdminForm
          open={showVendorAdminForm}
          setOpen={setShowVendorAdminForm}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setRefreshList={setRefreshList}
        />
      )}

      <MessageModal ref={modalRef}/>
    </TableContainer>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorAdminUsers: async (data) => {
      try {
        return await dispatch(getUsersRequest(data));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateUser: async (userId, data) => {
      try {
        return await dispatch(activateDeactivateUserRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },

    resendInvitation: async (userId) => {
      try {
        return await dispatch(resendInvitationRequest(userId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAdminList);
