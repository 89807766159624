const MuiChip = {
  styleOverrides: {
    root: {
      // backgroundColor: '#1d2b37',
      fontFamily: 'Lato',
      // color: '#8b98a4',
      // '&.MuiChip-clickable:hover': {
      //   backgroundColor: '#1d2b37',
      // },
    },
  },
};

export default MuiChip;
