import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    titleText: {
      fontWeight: 'bold !important',
      marginRight: '0.5rem !important',
    },

    tagChip: {
      marginRight: '8px !important',
      marginBottom: '8px !important',
    },

    actionIcon: {
      marginLeft: '6px !important',
      cursor: 'pointer !important',
    },

    countText: {
      fontSize: '0.8rem !important',
      fontWeight: 'bold !important',
      marginLeft: '0.2rem !important',
    },

    viewsText: {
      fontSize: '0.8rem !important',
      fontWeight: 'bold !important',
    },

    createdAndUpdatedByText: {
      fontSize: '0.8rem !important',
      marginBottom: '5px !important',
    },

    nameAndDate: {
      fontWeight: 'bold !important',
    },

    showMoreLessText: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    showMoreLessButtonContainer: {
      padding: '0 !important',
      backgroundColor: '#33393E !important',
      height: '32px !important',
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      alignItems: 'flex-end !important',
    },
    showMoreLessButton: {
      background: 'transparent !important',
      cursor: 'pointer !important',
      outline: 'none !important',
      border: 'none !important',
      fontSize: '15.5px !important',
      fontWeight: 'bold !important',
      borderRadius: '0px !important',
      color: '#D0AC74 !important',
      textDecoration: 'underline !important',
      textAlign: 'left !important',
      padding: '0px !important',
    },
  };
});
