import {Box, Button, Dialog, DialogContent, FormHelperText, Grid, Icon, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {
  addCuisineRequest,
  getSignedUrlRequest,
  updateCuisineRequest,
  uploadToS3Request,
} from '../../CuisinesApiActions';
import {useStyles} from './styles';

/**
 * function to render super admin form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to set the open state
 * @param {*} selectedCuisine: selected user
 * @param {*} setSelectedCuisine: function to set the selected user
 * @param setRefreshList
 * @param addCuisine
 * @param updateCuisine
 * @param getSignedUrl
 * @param uploadToS3
 * @returns
 */
const CuisineForm = ({
                       open,
                       setOpen,
                       selectedCuisine,
                       setSelectedCuisine,
                       setRefreshList,
                       addCuisine,
                       updateCuisine,
                       getSignedUrl,
                       uploadToS3,
                     }) => {
  const classes = useStyles();

  const [user, setUser] = useState({
    name: '',
    description: '',
    imageUrl: '',
  });

  const [images, setImages] = useState([]);
  const [imagesError, setImagesError] = useState('');

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setImagesError('');
    }
  }, [images]);

  useEffect(() => {
    if (selectedCuisine) {
      setUser({
        name: selectedCuisine?.name,
        description: selectedCuisine?.description,
        imageUrl: selectedCuisine?.imageUrl,
        imagePath: selectedCuisine?.imagePath,
      });
    }
  }, [selectedCuisine]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form close
   */
  const handleCuisineFormClose = () => {
    setOpen(false);

    if (selectedCuisine) {
      setSelectedCuisine(null);
    }
  };

  /**
   * function to handle form validation
   */
  const handleFormValidation = () => {
    if (images.length === 0 && !user?.imageUrl) {
      setImagesError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    handleFormValidation();

    if (images.length > 0 || user?.imageUrl) {
      const data = {
        ...user,
      };

      if (images.length > 0) {
        const imageFileData = {
          fileName: images[0].file.name,
          fileType: `.${images[0].file.name.split('.').pop()}`,
        };
        const resSignedUrl = await getSignedUrl(imageFileData);
        await uploadToS3(resSignedUrl.urls, images[0].file);
        data.imageUrl = resSignedUrl.path;

        if (selectedCuisine) {
          delete data['imagePath'];

          await updateCuisine(selectedCuisine?.id, UtilHelper.trimObject(data));
        } else {
          await addCuisine(UtilHelper.trimObject(data));
        }

        handleCuisineFormClose();
        setRefreshList(true);
      } else {
        if (selectedCuisine) {
          const requestData = {
            ...data,
            imageUrl: user.imagePath,
          };

          delete requestData['imagePath'];

          await updateCuisine(
            selectedCuisine?.id,
            UtilHelper.trimObject(requestData)
          );
        } else {
          await addCuisine(UtilHelper.trimObject(data));
        }

        handleCuisineFormClose();
        setRefreshList(true);
      }
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleFormValidation();
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {!selectedCuisine
                ? `${I18n.t('cuisines.cuisine_form.form_title_add_label')}`
                : `${I18n.t('cuisines.cuisine_form.form_title_update_label')}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${user.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                    label={I18n.t(
                      'cuisines.cuisine_form.form_field_name_label'
                    )}
                    onChange={handleChangeInput}
                    name="name"
                    value={user.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`,
                      `matchRegexp:${Constants.REGEX.NAME}`,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_allowed_characters_for_text_field'
                      ),
                      I18n.t('error_messages.invalid_string'),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${user.description.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_250}`}
                    label={I18n.t(
                      'cuisines.cuisine_form.form_field_description_label'
                    )}
                    onChange={handleChangeInput}
                    name="description"
                    value={user.description}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_250}`,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_250_allowed_characters_for_text'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {images.length === 0 && !user?.imageUrl && (
                    <Box>
                      <ImageUploading
                        acceptType={['jpg', 'jpeg', 'png']}
                        maxFileSize={5242880}
                        value={images}
                        onChange={(imageFiles) => setImages(imageFiles)}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({
                            onImageUpload,
                            errors,
                          }) => (
                          <>
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={onImageUpload}
                            >
                              {I18n.t(
                                'spirit.spirit_form.form_add_image_label'
                              )}{' '}
                              *
                            </Button>

                            <Box>
                              {errors && (
                                <Box mt={0.5} ml={0.1}>
                                  {errors.acceptType && (
                                    <FormHelperText error>
                                      Please select only jpeg, jpg and png files
                                    </FormHelperText>
                                  )}

                                  {errors.maxFileSize && (
                                    <FormHelperText error>
                                      Please select max file size of 5MB
                                    </FormHelperText>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </ImageUploading>
                    </Box>
                  )}

                  {(images.length > 0 || user?.imageUrl) && (
                    <Box>
                      <Box display="inline-block" position="relative" mt={1}>
                        <Box position="absolute" right="-8px" top="-8px">
                          <Icon
                            fontSize="small"
                            classes={{root: classes.removeIcon}}
                            onClick={() => {
                              if (images.length > 0) {
                                setImages([]);
                              } else {
                                setUser({
                                  ...user,
                                  imageUrl: '',
                                  imagePath: '',
                                });
                              }
                            }}
                          >
                            cancel
                          </Icon>
                        </Box>
                        <Box>
                          <img
                            src={
                              images.length > 0
                                ? images[0].data_url
                                : user?.imageUrl
                            }
                            alt="file"
                            className={classes.spiritImage}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {imagesError && (
                    <FormHelperText
                      error
                      style={{marginTop: '7px', marginLeft: '14px'}}
                    >
                      {imagesError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleCuisineFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    addCuisine: async (data) => {
      try {
        return await dispatch(addCuisineRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateCuisine: async (userId, data) => {
      try {
        return await dispatch(updateCuisineRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },

    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CuisineForm);
