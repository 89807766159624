import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {loadTranslations, setLocale, syncTranslationWithStore,} from 'react-redux-i18n';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import translations from './localization/translations';
import rootReducer from './reducers';
import reportWebVitals from './reportWebVitals';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('en'));

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
