import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    formLabelRoot: {
      fontSize: '0.9rem !important',
      opacity: '0.7 !important',
      fontWeight: 'normal !important',
      marginTop: '0.7rem !important',
      marginBottom: '0.2rem !important',
      color: 'inherit !important',
    },
    tooltipMain: {
      fontWeight: 'bold',
    },

    questionImage: {
      height: '80px',
      objectFit: 'contain',
      width: 'auto',
    },

    removeIcon: {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      borderRadius: '50%',
    },
  };
});
