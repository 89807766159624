import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get users from DB
 * @param {*} data: data for server request
 * @returns
 */
export const getBrandsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.BRANDS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get brand options from DB
 * @param {*} data: data for server request
 * @returns
 */
export const getBrandsForOptionsRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.BRANDS}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add brand
 * @param {*} data: data for server request
 * @returns
 */
export const addBrandRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.BRAND}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              'Brand details saved successfully',
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              'Something went wrong, please try again',
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to update brand
 * @param {*} brandId: brand id
 * @param {*} data: data
 * @returns
 */
export const updateBrandRequest = (brandId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `/spirit/${brandId}/brand`, 'patch', data)
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            'Brand details saved successfully',
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            'Something went wrong, please try again',
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};
