import {Box, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {CustomPagination, MessageModal} from '../../../../utils/components';
import {DateRangeFilter, InputFilter, NumberRangeFilter, RadioButtonFilter,} from '../../../../utils/components/Filter';
import Constants, {USER_ROLES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {activateDeactivateUserRequest, getUsersRequest, resendInvitationRequest,} from '../../UsersApiActions';
import {EndUserCard} from '../index';
import {useStyles} from './styles';

/**
 * function to render end user list
 * @param {*} getEndUsers: function to get end users
 * @param {*} activateDeactivateUser: function to activate deactivate user
 * @returns
 */
const EndUserList = ({getEndUsers, activateDeactivateUser}) => {
  const classes = useStyles();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      roleName: USER_ROLES.CUSTOMER,
      searchFilter: '',
      enabled: '',
      joinedDate: null,
      lastActiveDate: null,
      postsCount: null,
      commentsCount: null,
      productsPurchasedCount: null,
      eventsAttendedCount: null,
      surveyUndertakenCount: null,
      partiesPlannedCount: null,
    },
  });

  const [endUserList, setEndUserList] = useState(null);
  const modalRef = useRef();
  const [refreshList, setRefreshList] = useState(false);

  /**
   * function to get end user list
   * @param {*} requestData: request data
   */
  const getEndUserList = async (requestData) => {
    try {
      const {data} = await getEndUsers(requestData);
      setEndUserList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getEndUserList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getEndUserList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle activate / deactivate a user
   * @param {*} userId: user id
   * @param {*} enabled: active status boolean
   * @param {*} name: user name
   */
  const onActivateDeactivate = (userId, enabled, name) => {
    const title = `${enabled ? 'Disable' : 'Enable'} User`;
    const message = `Are you sure you want to ${
      enabled ? 'disable' : 'enable'
    } ${name}?`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          enabled: !enabled,
        };
        await activateDeactivateUser(userId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeFilter = (elementName, selectedvalue) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = selectedvalue;
    setPaginationParams(data);
  };

  /**
   * function to handle filter change with debounce
   */
  const handleChangeDebounceFilter = UtilHelper.debounce(
    (name, selectedvalue) => {
      const data = Object.assign({}, paginationParams);
      data.searchData[name] = selectedvalue;
      setPaginationParams(data);
    },
    300
  );

  return (
    <>
      <Box>
        <Box p={2} mb={2} mt={-1} component={Paper} color="#fff">
          <Grid
            container
            display="flex"
            alignItems="flex-end"
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <Box width="220px">
                <InputFilter
                  filterName=""
                  placeholder={I18n.t(
                    'user.end_user_table.table_filter_search_by_name_email_phone_label'
                  )}
                  onChangeFilter={(value) =>
                    handleChangeDebounceFilter('searchFilter', value)
                  }
                />
              </Box>
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <RadioButtonFilter
                arrayList={Constants.ACTIVE_STATUS_OPTIONS_FOR_FILTER}
                filterName={I18n.t(
                  'user.end_user_table.table_filter_active_status_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_active_status_label'
                )}
                value={paginationParams.searchData.enabled}
                onChangeFilter={(value) => handleChangeFilter('enabled', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_joined_date_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_joined_date_label'
                )}
                value={paginationParams.searchData.joinedDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('joinedDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_last_active_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_last_active_label'
                )}
                value={paginationParams.searchData.lastActiveDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('lastActiveDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_post_label'
                )}
                title={I18n.t('user.end_user_table.table_filter_post_label')}
                value={paginationParams.searchData.postsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('postsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_comment_label'
                )}
                title={I18n.t('user.end_user_table.table_filter_comment_label')}
                value={paginationParams.searchData.commentsCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('commentsCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_product_purchased_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_product_purchased_label'
                )}
                value={paginationParams.searchData.productsPurchasedCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('productsPurchasedCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_event_attended_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_event_attended_label'
                )}
                value={paginationParams.searchData.eventsAttendedCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('eventsAttendedCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_survey_undertaken_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_survey_undertaken_label'
                )}
                value={paginationParams.searchData.surveyUndertakenCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('surveyUndertakenCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'user.end_user_table.table_filter_party_planned_label'
                )}
                title={I18n.t(
                  'user.end_user_table.table_filter_party_planned_label'
                )}
                value={paginationParams.searchData.partiesPlannedCount}
                onChangeFilter={(value) =>
                  handleChangeFilter('partiesPlannedCount', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          {endUserList?.rows.length === 0 && (
            <Typography variant="subtitle1" className={classes.noDataAvailable}>
              No data available
            </Typography>
          )}

          {endUserList?.rows?.map((user, index) => (
            <Box key={index}>
              <EndUserCard
                user={user}
                onActivateDeactivate={(userId, enabled, name) =>
                  onActivateDeactivate(userId, enabled, name)
                }
              />
            </Box>
          ))}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <CustomPagination
            rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
            count={endUserList?.count}
            rowsPerPage={paginationParams.rowsPerPage}
            page={paginationParams.page}
            onPageChange={(page) => {
              const pagination = Object.assign({}, paginationParams);
              pagination.page = page;
              setPaginationParams(pagination);
            }}
            onRowsPerPageChange={(rowsPerPage) => {
              const pagination = Object.assign({}, paginationParams);
              pagination.page = 0;
              pagination.rowsPerPage = rowsPerPage;
              setPaginationParams(pagination);
            }}
          />
        </Box>
      </Box>

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getEndUsers: async (data) => {
      try {
        return await dispatch(getUsersRequest(data));
      } catch (error) {
        throw error;
      }
    },

    activateDeactivateUser: async (userId, data) => {
      try {
        return await dispatch(activateDeactivateUserRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },

    resendInvitation: async (userId) => {
      try {
        return await dispatch(resendInvitationRequest(userId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndUserList);
