import {AttachFile} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import React from 'react';
import {connect} from 'react-redux';
import Constants, {SNACKBAR_VARIAINT} from '../../Constants';
import {showSnackbar} from '../AppLoader/AppLoaderActions';
import palette from '../Theme/palette';
import './FileUpload.css';

const styles = (theme) => ({
  normalDropzone: {
    minHeight: '100px !important',
    backgroundColor: palette.primary.main,
    border: '1px #334e68 solid',
  },
  smallDropzone: {
    minHeight: 'auto',
    border: 'none',
    width: '105px',
  },
  hideIcon: {
    display: 'none',
  },
  text: {
    margin: '0px !important',
  },
  textContainer: {
    padding: '0px !important',
    textAlign: 'left',
  },
});

/**
 * function to render file upload
 */
const FileUpload = React.memo(
  ({
     files,
     updateFileList,
     removeFileFromSelection,
     showSnackbarMessage,
     classes,
     acceptedFiles,
     filesLimit,
     dropzoneLabel,
     fieldLabel,
     showPreviewsInDropzone,
     initialFiles,
   }) => {
    const onUpdateFileList = (selectedFiles, updateError) => {
      const findZeroByteFileSizeIndex = selectedFiles.findIndex(
        (file) => file.size === 0
      );
      if (findZeroByteFileSizeIndex > -1) {
        selectedFiles.splice(findZeroByteFileSizeIndex, 1);
        showSnackbarMessage(
          'You can not upload empty file.',
          SNACKBAR_VARIAINT.ERROR
        );
      } else {
      }
      updateFileList(selectedFiles, updateError);
    };

    return (
      <>
        <Typography variant="h6" className="dropzone-label">
          {fieldLabel ? fieldLabel : 'Upload Image'}
        </Typography>

        <DropzoneArea
          Icon={AttachFile}
          acceptedFiles={acceptedFiles ? acceptedFiles : []}
          showPreviewsInDropzone={
            showPreviewsInDropzone ? showPreviewsInDropzone : false
          }
          showFileNamesInPreview={false}
          useChipsForPreview
          dropzoneText={dropzoneLabel}
          showAlerts={['error']}
          maxFileSize={
            Constants.REACT_APP_ALLOWED_MAX_ATTACHMENT_SIZE_IN_BYTES
          }
          filesLimit={filesLimit}
          onChange={(selectedFiles) =>
            onUpdateFileList(selectedFiles, true)
          }
          onDelete={() => updateFileList([], false)}
          classes={{
            root: classes.normalDropzone,
          }}
          previewChipProps={{
            color: 'primary',
          }}
          initialFiles={initialFiles}
        />
      </>
    );
  }
);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showSnackbarMessage: (message, variant) => {
      return dispatch(showSnackbar(message, variant));
    },
  };
};

const FileUploadConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUpload);
export default withStyles(styles)(FileUploadConnect);
