import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import ModalImage from 'react-modal-image';
import {I18n} from 'react-redux-i18n';
import {useStyles} from './styles';

/**
 * function to render question item
 * @param {*} question: question info
 * @param {*} onDeleteQuestion: function to delete question
 * @param {*} handleQuestionFormDialogOpen: function to handle question form dialog open
 * @param {*} handleEditIconClick: function to handle edit icon click
 * @returns
 */
const QuestionItem = ({
                        question,
                        onDeleteQuestion,
                        handleQuestionFormDialogOpen,
                        handleEditIconClick,
                      }) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={3}>
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Box>
            <Typography>{question?.title}</Typography>
          </Box>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <Box display="flex" justifyContent="flex-end">
            <Box ml={1}>
              <Tooltip
                title={I18n.t('survey.question_item.edit_tooltip')}
                placement="top"
              >
                <Icon
                  classes={{root: classes.actionIcon}}
                  onClick={() => {
                    handleQuestionFormDialogOpen();
                    handleEditIconClick(question);
                  }}
                >
                  edit
                </Icon>
              </Tooltip>
            </Box>
            <Box ml={1}>
              <Tooltip
                title={I18n.t('survey.question_item.delete_tooltip')}
                placement="top"
              >
                <Icon
                  classes={{root: classes.actionIcon}}
                  onClick={() =>
                    onDeleteQuestion(question?.id, question?.title)
                  }
                >
                  delete
                </Icon>
              </Tooltip>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <FormControl disabled>
              <RadioGroup row value={question?.type}>
                <FormControlLabel
                  classes={{
                    disabled: classes.checkboxRadioLabelDisabled,
                  }}
                  value="single"
                  control={
                    <Radio
                      color="secondary"
                      classes={{
                        disabled: classes.checkboxRadioDisabled,
                      }}
                    />
                  }
                  label={I18n.t('survey.question_item.single_select_label')}
                />
                <FormControlLabel
                  classes={{
                    disabled: classes.checkboxRadioLabelDisabled,
                  }}
                  value="multi"
                  control={
                    <Radio
                      color="secondary"
                      classes={{
                        disabled: classes.checkboxRadioDisabled,
                      }}
                    />
                  }
                  label={I18n.t('survey.question_item.multi_select_label')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <Typography>{`1) ${question?.option1}`}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <Typography>{`2) ${question?.option2}`}</Typography>
          </Box>
        </Grid>

        {question?.option3 && (
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <Typography>{`3) ${question?.option3}`}</Typography>
            </Box>
          </Grid>
        )}

        {question?.option4 && (
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <Typography>{`4) ${question?.option4}`}</Typography>
            </Box>
          </Grid>
        )}

        {question?.imageUrl && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <ModalImage
                showRotate={false}
                hideDownload
                className={classes.modalImage}
                small={question?.imageUrl}
                medium={question?.imageUrl}
                large={question?.imageUrl}
                alt=""
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default QuestionItem;
