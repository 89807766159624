import {Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {ProfileViewVendor} from '../../../../utils/components';
import {showSnackbar} from '../../../../utils/components/AppLoader/AppLoaderActions';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import {SNACKBAR_VARIAINT} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getSignedUrlRequest, uploadToS3Request} from '../../VendorApiActions';

/**
 * function to render video form
 * @param {*} submitFormData: function to handle submit form data
 * @param {*} isUpdate: boolean for is update
 * @param {*} formData: form data
 * @param {*} onCancelInviteVendor: function to cancel invite vendor
 * @param {*} onCancelUpdateVendor: functon to cancel update vendor
 * @param {*} getSignedUrl: function to get signed url
 * @param {*} uploadToS3: function to upload to signed url
 * @param {*} showSnackbarMessage: function to show snackbar message
 * @returns
 */
const VendorForm = ({
                      submitFormData,
                      isUpdate,
                      formData,
                      onCancelInviteVendor,
                      onCancelUpdateVendor,
                      getSignedUrl,
                      uploadToS3,
                      showSnackbarMessage,
                    }) => {

  const [formSubmit, setFormSubmit] = useState(false);
  const [vendor, setVendor] = useState({
    name: '',
    title: '',
    description: '',
    profileImage: [],
    profileImgUrl: '',
    icon: '',
    coverImage: [],
    coverPhoto: '',
    coverImgUrl: '',
  });
  // const [files, setFiles] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (isUpdate && formData) {
      setVendor({
        name: formData?.name,
        title: formData?.tagline,
        description: formData?.description,
        profileImage: [],
        profileImgUrl: '',
        icon: '',
        coverImage: [],
        coverPhoto: '',
        coverImgUrl: '',
      });
    }
  }, [isUpdate, formData]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: velue
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, vendor);
    data[event.target.name] = event.target.value;
    setVendor(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = () => {
    setFormSubmit(true);
    const data = Object.assign({}, vendor);
    submitFormData(UtilHelper.trimObject(data));
  };

  /**
   * function to handle file change
   * @param {*} event: event object
   * @param {*} elementName: element name
   */
  const onFileChange = (event, elementName) => {
    const file = event.target.files[0];
    const fileName = file?.name;

    const extensionList =
      Constants.REACT_APP_PROFILE_IMAGE_SUPPORTED.split(',');

    const checkFileSize =
      file?.size < Constants.REACT_APP_ALLOWED_MAX_ATTACHMENT_SIZE_IN_BYTES;
    const checkFileExtension = UtilHelper.checkAllowedExtension(
      fileName,
      extensionList
    );

    if (checkFileExtension && checkFileSize) {
      const data = Object.assign({}, vendor);

      let reader = new FileReader();

      reader.onload = function (e) {
        let tempUrl = '';
        tempUrl = e.target.result;
        if (elementName === 'profileImgUrl') {
          data.icon = tempUrl;
          data.profileImage = event.target.files;
        } else {
          data.coverPhoto = tempUrl;
          data.coverImage = event.target.files;
        }
        setVendor(data);
      };

      reader.readAsDataURL(file);
    } else {
      let message = '';
      if (!checkFileExtension) {
        message = I18n.t('error_messages.invalid_file_type');
        showSnackbarMessage(message, SNACKBAR_VARIAINT.ERROR);
      } else if (!checkFileSize) {
        message = 'Please select file size less than 5mb';
        showSnackbarMessage(message, SNACKBAR_VARIAINT.ERROR);
      }
    }
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      onError={(errors) => console.log(errors)}
    >
      <Grid container spacing={2}>
        {!isUpdate ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="margin-top-20 margin-bottom-10"
          >
            <ProfileViewVendor
              coverPhoto={vendor.coverPhoto}
              icon={vendor.icon}
              onFileChange={onFileChange}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="margin-top-20"
        >
          <TextValidator
            variant="standard"
            fullWidth
            required
            size="small"
            label={I18n.t('vendor.add_vendor.form_field_name_label')}
            onChange={handleChangeInput}
            helperText={`${vendor.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300}`}
            name="name"
            value={vendor.name || ''}
            validators={[
              'required',
              ONLY_BLANK_SPACES,
              'maxStringLength:' +
              Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300,
              `matchRegexp:${Constants.REGEX.NAME}`
            ]}
            errorMessages={[
              I18n.t('error_messages.field_required'),
              I18n.t('error_messages.blank_spaces_not_allowed'),
              I18n.t('error_messages.maximum_300_allowed_characters_for_text'),
              I18n.t('error_messages.invalid_string')
            ]}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextValidator
            variant="standard"
            fullWidth
            required
            size="small"
            label={I18n.t('vendor.add_vendor.form_field_title_label')}
            onChange={handleChangeInput}
            multiline
            rows={3}
            rowsMax={5}
            name="title"
            value={vendor.title || ''}
            helperText={`${vendor.title.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300}`}
            validators={[
              'required',
              ONLY_BLANK_SPACES,
              'maxStringLength:' +
              Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300,
            ]}
            errorMessages={[
              I18n.t('error_messages.field_required'),
              I18n.t('error_messages.blank_spaces_not_allowed'),
              I18n.t('error_messages.maximum_300_allowed_characters_for_text'),
            ]}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextValidator
            variant="standard"
            fullWidth
            size="small"
            multiline
            rows={5}
            rowsMax={7}
            label={I18n.t('vendor.add_vendor.form_field_description_label')}
            onChange={handleChangeInput}
            name="description"
            value={vendor.description || ''}
            validators={[ONLY_BLANK_SPACES]}
            errorMessages={[I18n.t('error_messages.blank_spaces_not_allowed')]}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            className="invite-add-btn text-transform-capitalize"
          >
            {isUpdate
              ? I18n.t('vendor.add_vendor.form_update_button_label')
              : I18n.t('vendor.add_vendor.form_add_button_label')}
          </Button>
          {onCancelInviteVendor || onCancelUpdateVendor ? (
            <Button
              color="inherit"
              variant="text"
              className="cancel-btn text-transform-capitalize"
              onClick={
                onCancelInviteVendor
                  ? onCancelInviteVendor
                  : onCancelUpdateVendor
              }
            >
              {I18n.t('vendor.add_vendor.form_cancel_button_label')}
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },

    showSnackbarMessage: (message, varient) => {
      dispatch(showSnackbar(message, varient));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm);
