import {ThemeProvider} from '@mui/material/styles';
import React, {useEffect} from 'react';
import './App.css';
import AppRoutes from './routes';
import {Theme} from './utils/components';
import {initializeGoogleScript} from './utils/googleScriptSetup';

/**
 * function to render as root component of the application
 * @returns
 */
const App = () => {
  useEffect(() => {
    initializeGoogleScript();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <AppRoutes/>
    </ThemeProvider>
  );
};

export default App;
