import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {isValidPhoneNumber} from 'react-phone-number-input';
import './MobileNumber.css';

/**
 * function to render mobile input
 * @param {*} phone: phone value
 * @param {*} updateMobileNumber: function to update mobile number
 * @param {*} customLabel: custom label
 * @returns
 */
const MobileNumber = React.memo(
  ({phone, updateMobileNumber, customLabel}) => {
    let defaultCountryCode = 91;

    return (
      <>
        <ReactPhoneInput
          defaultCountry="in"
          country="in"
          varient=""
          enableSearchField={true}
          disableAreaCodes={true}
          countryCodeEditable={false}
          value={phone}
          specialLabel={customLabel ? customLabel : 'Phone *'}
          onChange={(phone, data) => {
            if (phone) {
              let countryCode = `+${
                data?.dialCode ? data?.dialCode : defaultCountryCode
              }`;

              let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
              updateMobileNumber(
                phoneNumber,
                countryCode,
                isValidPhoneNumber(phoneNumber)
              );
            }
          }}
        />
      </>
    );
  }
);

export default MobileNumber;
