import {Box, Button, Grid, Icon, InputAdornment, Typography,} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Redirect} from 'react-router-dom';
import {appStoreIcon, googlePlayIcon, logo, logoIcon} from '../../../../assets';
import {AppLoader, MessageModal} from '../../../../utils/components';
import {ROUTE_ACTIONS} from '../../../../utils/Constants';
import Constants from '../../../../utils/Constants';
import {signInRequest} from '../../AuthenticationApiActions';
import './SignIn.css';

/**
 * function to render sign in
 * @param {*} history: history object
 * @param {*} onLogin: function to handle login
 * @param {*} loggedInUser: logged in user info
 * @returns
 */
const SignIn = ({history, onLogin, loggedInUser}) => {
  const [user, setUser] = useState({
    username: '',
    password: '',
    browserVersion: '',
    appVersion: '',
    ip: '',
    OSVersion: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const modalRef = useRef();
  const [isInvalidCred, setIsInvalidCred] = useState(false);

  useEffect(() => {
    document.title = I18n.t('global.app_name');

    const data = Object.assign({}, user);
    data.browserVersion = navigator.userAgent;
    data.appVersion = process.env.REACT_APP_VERSION;
    data.ip = '';
    let OSName = '';

    if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows OS';
    if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh';
    if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux OS';
    if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android OS';
    if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS';

    data.OSVersion = OSName;
    setUser(data);

  }, []);

  useEffect(() => {
    if (isInvalidCred) {
      setIsInvalidCred(false);
    }

  }, [user]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChange = (event) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    try {
      await onLogin(user);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsInvalidCred(true);
      }
    }
  };

  /**
   * function to handle link redirect
   * @param {*} url: url to redirect
   */
  const redirectToUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  /**
   * function to redirect to android app
   */
  const redirectToAndroidApp = () => {
    if (process.env.REACT_APP_PLAY_STORE_URL) {
      window.location.href = process.env.REACT_APP_PLAY_STORE_URL;
    }
  };

  /**
   * function to redirect to iso app
   */
  const redirectToIOSApp = () => {
    if (process.env.REACT_APP_APPLE_STORE_URL) {
      window.location.href = process.env.REACT_APP_APPLE_STORE_URL;
    }
  };

  const currentYear = new Date().getFullYear();

  if (loggedInUser) {
    return loggedInUser.isSystemGeneratedPassword ? (
      <Redirect to={ROUTE_ACTIONS.FORCE_RESET_PASSWORD}/>
    ) : (
      <Redirect to={ROUTE_ACTIONS.HOME}/>
    );
  } else {
    return (
      <Box>
        <Grid container className="main-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="child-grid-one"
          >
            <Box>
              <img className="application-logo-img" src={logo} alt="logo"/>
              <Typography variant="h4">
                {I18n.t('global.message_labels.moving_spirit_pedia_forward')}
              </Typography>
              <Box className="store-icons-container">
                <img
                  src={appStoreIcon}
                  alt="appStoreIcon"
                  className="cursor-pointer app-store-icon"
                  onClick={redirectToIOSApp}
                />

                <img
                  src={googlePlayIcon}
                  alt="googlePlayIcon"
                  className="cursor-pointer play-store-icon"
                  onClick={redirectToAndroidApp}
                />
              </Box>
              <Typography variant="h6" className="learn-more-label">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    redirectToUrl(process.env.REACT_APP_HOME_URL_REDIRECT)
                  }
                >
                  {I18n.t('global.message_labels.learn_more')}
                  <Icon className="forward-icon">arrow_forward_rounded</Icon>
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="child-grid-two"
          >
            <Grid item className="login-form-inner-div">
              <ValidatorForm noValidate onSubmit={handleSubmit}>
                <Box className="form-header">
                  <img className="application-logo-img" src={logo} alt="logo"/>
                  <Typography variant="h5">
                    {I18n.t(
                      'global.message_labels.moving_spirit_pedia_forward'
                    )}
                  </Typography>
                  <Typography variant="body2" className="learn-more-label">
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        redirectToUrl(process.env.REACT_APP_HOME_URL_REDIRECT)
                      }
                    >
                      {I18n.t('global.message_labels.learn_more')}
                      <Icon className="forward-icon">
                        arrow_forward_rounded
                      </Icon>
                    </span>
                  </Typography>
                </Box>

                <TextValidator
                  variant="standard"
                  fullWidth
                  margin="dense"
                  required
                  autoFocus
                  className="login-field"
                  name="username"
                  label={I18n.t('sign_in.sign_in_form_username_label')}
                  type="text"
                  onChange={handleChange}
                  value={user.username}
                  validators={[
                    'required',
                    'isEmail',
                    `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.email_invalid_error'),
                    I18n.t(
                      'error_messages.maximum_100_allowed_characters_for_text'
                    ),
                  ]}
                />

                <TextValidator
                  variant="standard"
                  fullWidth
                  margin="dense"
                  required
                  name="password"
                  className="login-field"
                  label={I18n.t('sign_in.sign_in_form_password_label')}
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  value={user.password}
                  validators={['required']}
                  errorMessages={[I18n.t('error_messages.field_required')]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="input-adornment"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <Icon>visibility</Icon>
                        ) : (
                          <Icon>visibility_off</Icon>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {isInvalidCred ? (
                  <Typography className="text-align-center invalid-cred-text">
                    {I18n.t('error_messages.invalid_credentials')}
                  </Typography>
                ) : (
                  ''
                )}
                <Typography
                  variant="button"
                  component="div"
                  className="forgot-password text-align-center"
                  onClick={() => history.push(ROUTE_ACTIONS.FORGOT_PASSWORD)}
                  style={{color: 'red'}}
                >
                  {I18n.t('sign_in.forgot_password_label')}?
                </Typography>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className="login-form-button"
                >
                  {I18n.t('sign_in.sign_in_label')}
                </Button>
              </ValidatorForm>
              <Grid
                container
                spacing={0}
                className="app-icon-container-form-footer"
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-align-left"
                >
                  <img
                    src={appStoreIcon}
                    alt="appStoreIcon"
                    className="cursor-pointer"
                    onClick={redirectToIOSApp}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-align-right"
                >
                  <img
                    src={googlePlayIcon}
                    alt="googlePlayIcon"
                    className="cursor-pointer"
                    onClick={redirectToAndroidApp}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="footer-grid"
          >
            <img src={logoIcon} alt="community" className="community-icon"/>
            <Box>
              <Typography variant="body2" className="community-footer-link">
                <span
                  onClick={() =>
                    redirectToUrl(process.env.REACT_APP_HOME_URL_REDIRECT)
                  }
                  className="cursor-pointer"
                >
                  {I18n.t('global.message_labels.home')}
                </span>
              </Typography>
              <Typography variant="body2" className="community-footer-link">
                <span
                  onClick={() =>
                    redirectToUrl(process.env.REACT_APP_BLOG_URL_REDIRECT)
                  }
                  className="cursor-pointer"
                >
                  {I18n.t('global.message_labels.blog')}
                </span>
              </Typography>
              <Typography variant="body2" className="community-footer-link">
                <span
                  onClick={() =>
                    redirectToUrl(process.env.REACT_APP_TERMS_URL_REDIRECT)
                  }
                  className="cursor-pointer"
                >
                  {I18n.t('global.message_labels.terms_of_service')}
                </span>
              </Typography>
              <Typography variant="body2" className="community-footer-link">
                <span
                  onClick={() =>
                    redirectToUrl(process.env.REACT_APP_PRIVACY_URL_REDIRECT)
                  }
                  className="cursor-pointer"
                >
                  {I18n.t('global.message_labels.privacy_policy')}
                </span>
              </Typography>
              <Typography variant="body2" className="copyright-info">
                {I18n.t('global.message_labels.copyright')} {currentYear}{' '}
                {I18n.t('global.message_labels.spirit_pedia_inc')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <MessageModal ref={modalRef}/>
        <AppLoader/>
      </Box>
    );
  }
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: async (data) => {
      try {
        return await dispatch(signInRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
