import {Autocomplete, Button, Dialog, DialogContent, FormHelperText, Grid, TextField, Typography,} from '@mui/material';
import {ContentState, EditorState} from 'draft-js';
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, {useEffect, useState} from 'react';

import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsForOptionsRequest} from '../../../Vendors/VendorApiActions';
import {addVideoRequest, getTagsRequest, updateVideoRequest,} from '../../WhiskeyTubeApiActions';
import {useStyles} from './styles';

/**
 * function to render video form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to change the open state
 * @param {*} selectedVideo: selected video
 * @param {*} setSelectedVideo: function to set the selected video
 * @param getTags
 * @param getVendorsForOptions
 * @param addVideo
 * @param updateVideo
 * @param setRefreshList
 * @returns
 */
const VideoForm = ({
                     open,
                     setOpen,
                     selectedVideo,
                     setSelectedVideo,
                     getTags,
                     getVendorsForOptions,
                     addVideo,
                     updateVideo,
                     setRefreshList,
                   }) => {
  const classes = useStyles();

  const [video, setVideo] = useState({
    youtubeVideoLink: '',
    title: '',
    vendor: null,
    description: '',
    tags: [],
  });
  const [tagsError, setTagsError] = useState('');
  const [tagList, setTagList] = useState(null);
  const [vendorList, setVendorList] = useState(null);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  const getTagList = async () => {
    const {data} = await getTags();
    setTagList(data);
  };
  useEffect(() => {
    getTagList();

  }, []);

  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    getVendorsForOptionList();

  }, []);

  useEffect(() => {
    if (video.tags.length > 0) {
      setTagsError('');
    }
  }, [video.tags]);

  useEffect(() => {
    if (selectedVideo) {
      setVideo({
        youtubeVideoLink: selectedVideo.link,
        title: selectedVideo.title,
        vendor: selectedVideo?.Vendor ? selectedVideo?.Vendor : null,
        description: selectedVideo?.description
          ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(selectedVideo.description).contentBlocks,
              htmlToDraft(selectedVideo.description).entityMap
            )
          )
          : '',
        tags: selectedVideo?.tag ? selectedVideo?.tag : [],
      });
    }
  }, [selectedVideo]);

  /**
   * function to clear the form
   */
  const clearForm = () => {
    setVideo({
      youtubeVideoLink: '',
      title: '',
      vendor: null,
      description: '',
      tags: [],
    });
  };

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, video);
    data[event.target.name] = event.target.value;
    setVideo(data);
  };

  /**
   * function to handle dropdown input change
   * @param {*} name: name
   * @param {*} newValue: new value
   */
  const onDropdownInputChange = (name, newValue) => {
    setVideo((preVal) => ({
      ...preVal,
      [name]: newValue,
    }));
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    if (video.tags.length === 0) {
      setTagsError(I18n.t('error_messages.field_required'));
    }

    if (!tagsError && video.tags.length > 0) {
      const data = {
        ...video,
        link: video.youtubeVideoLink,
        VendorId: video?.vendor ? video?.vendor?.id : '',
        WhiskeyTagId: video?.tags?.map((tag) => tag.id),
      };

      if (video.description) {
        const editorContent = convertToRaw(
          video.description.getCurrentContent()
        );
        if (editorContent.blocks[0].text.length !== 0) {
          data.description = draftToHtml(
            convertToRaw(video?.description?.getCurrentContent())
          );
        } else {
          data.description = '';
        }
      }

      delete data['youtubeVideoLink'];
      delete data['vendor'];
      delete data['tags'];

      if (selectedVideo) {
        await updateVideo(selectedVideo.id, UtilHelper.trimObject(data));
      } else {
        await addVideo(UtilHelper.trimObject(data));
      }

      handleVideoFormClose();
      setRefreshList(true);
    }
  };

  /**
   * function to handle form close
   */
  const handleVideoFormClose = () => {
    setOpen(false);
    clearForm();

    if (selectedVideo) {
      setSelectedVideo(null);
    }
  };

  /**
   * function to handle editor state change
   * @param {*} value: value
   */
  const onEditorStateChange = (value) => {
    const data = Object.assign({}, video);
    data.description = value;
    setVideo(data);
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    if (video.tags.length === 0) {
      setTagsError(I18n.t('error_messages.field_required'));
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {!selectedVideo
                ? `${I18n.t(
                  'whiskey_tube.add_video.form_title_add_video_label'
                )}`
                : `${I18n.t(
                  'whiskey_tube.add_video.form_title_update_video_label'
                )}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    label={I18n.t(
                      'whiskey_tube.add_video.form_field_youtube_video_link_label'
                    )}
                    onChange={handleChangeInput}
                    name="youtubeVideoLink"
                    value={video.youtubeVideoLink}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'maxStringLength:' +
                      Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_50_allowed_characters_for_text'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${video.title.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                    label={I18n.t(
                      'whiskey_tube.add_video.form_field_title_label'
                    )}
                    onChange={handleChangeInput}
                    name="title"
                    value={video.title}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      'maxStringLength:' +
                      Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_allowed_characters_for_text_field'
                      ),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={vendorList ? vendorList : []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    value={video.vendor}
                    onChange={(event, newValue) =>
                      onDropdownInputChange('vendor', newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={I18n.t(
                          'whiskey_tube.add_video.form_field_vendor_label'
                        )}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    fullWidth
                    size="small"
                    options={tagList ? tagList : []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    value={video.tags}
                    onChange={(event, newValue) =>
                      onDropdownInputChange('tags', newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={I18n.t(
                          'whiskey_tube.add_video.form_field_tags_label'
                        )}
                        variant="standard"
                      />
                    )}
                  />

                  {tagsError && (
                    <FormHelperText
                      error
                      style={{marginTop: '7px', marginLeft: '14px'}}
                    >
                      {tagsError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography classes={{root: classes.label}}>
                    {`${I18n.t(
                      'whiskey_tube.add_video.form_field_Description_label'
                    )}`}
                  </Typography>
                  <Editor
                    editorState={video.description}
                    onEditorStateChange={onEditorStateChange}
                    wrapperStyle={{
                      borderBottom: '1px solid #334e68',
                    }}
                    toolbarStyle={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      padding: '0px',
                      margin: '0px',
                      marginLeft: '-3px',
                    }}
                    editorStyle={{
                      heigth: '300px !important',
                    }}
                    toolbar={{
                      options: [
                        'inline',
                        'textAlign',
                        'link',
                        'emoji',
                        'remove',
                        'history',
                      ],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic'],
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleVideoFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getTags: async () => {
      try {
        return await dispatch(getTagsRequest());
      } catch (error) {
        throw error;
      }
    },

    addVideo: async (data) => {
      try {
        return await dispatch(addVideoRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateVideo: async (id, data) => {
      try {
        return await dispatch(updateVideoRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoForm);
