import {Card, Grid, Typography} from '@mui/material';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {addProductRequest} from '../../ProductApiActions';
import {ProductForm} from '../index';
import './AddProduct.css';

/**
 * function to render add product
 * @param {*} history: history object
 * @param {*} match: match object
 * @param {*} addProduct: function to add product
 * @returns
 */
const AddProduct = ({history, match, addProduct}) => {
  const vendorId = match.params.vendorId;

  /**
   * function to handle add product
   * @param {*} product: product
   */
  const handleAddProduct = async (product) => {
    const requestData = {
      ...product,
      ProductCategoryId: product?.category?.id,
      VendorId: product?.vendor?.id,
      isProductAvailableInSizes:
        product?.isProductAvailableInSizes === 'yes',
      isAvailable:
        product?.isProductAvailableInSizes === 'no'
          ? null
          : product?.isAvailable,
      size: product?.isProductAvailableInSizes === 'no' ? null : product?.sizes,
    };

    delete requestData['category'];
    delete requestData['vendor'];
    delete requestData['sizes'];

    await addProduct(requestData);

    history.goBack();
  };

  /**
   * function to handle cancel add product
   */
  const onCancelAddProduct = () => {
    history.goBack();
  };

  return (
    <Card className="product-card-container">
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="h6"
            className="product-dialog-title"
            style={{marginBottom: '1rem'}}
          >
            {I18n.t('product.add_product.form_title_add_product_label')}
          </Typography>

          <ProductForm
            onCancelAddProduct={onCancelAddProduct}
            submitFormData={(product) => handleAddProduct(product)}
            formData={null}
            isUpdate={false}
            vendorId={vendorId}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: async (data) => {
      try {
        return await dispatch(addProductRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
