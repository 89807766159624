import {createTheme} from '@mui/material/styles';
import ThemeOne from '../theme.json';

const theme = createTheme();

const MuiDialogActions = {
  styleOverrides: {
    root: {
      padding: '0px 32px 32px 32px',
      fontFamily: ThemeOne.fontFamily,
      [theme.breakpoints.down('md')]: {
        padding: '16px 16px 16px 24px !important',
      },
    },
  },
};

export default MuiDialogActions;
