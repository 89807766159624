import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get product category
 * @param {*} data: data for server request
 * @returns
 */
export const getProductCategoryRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.PRODUCT_CATEGORY}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res?.data?.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get products
 * @param {*} data: data for server request
 * @returns
 */
export const getProductsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add product
 * @param {*} data: data
 * @returns
 */
export const addProductRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.PRODUCT}`;
    return new HttpRequest(dispatch, url, 'post', data).send().then(
      (res) => {
        dispatch(
          showSnackbar(
            `Product details saved successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            `Something went wrong, please try again`,
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to update product
 * @param {*} productId: productId
 * @param {*} data: data
 * @returns
 */
export const updateProductRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            `Product details saved successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            `Something went wrong, please try again`,
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to get product
 * @param {*} id: id
 * @returns
 */
export const getProductRequest = (id) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.PRODUCT}/${id}`, 'get')
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to activate deactivate product
 * @param {*} productId: productId
 * @param {*} data: data
 * @returns
 */
export const activateDeactivateProductRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              `Product activated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `Product deactivated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to mark out of stock and in stock product
 * @param {*} productId: productId
 * @param {*} data: data
 * @returns
 */
export const inOutProductStockRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}/stock`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.outOfStock) {
          dispatch(
            showSnackbar(
              `Product marked out of stock successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `Product marked in stock successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to archive unarchive product
 * @param {*} productId: productId
 * @param {*} data: data
 * @returns
 */
export const archiveUnarchiveProductRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}/archiveProduct`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.archive) {
          dispatch(
            showSnackbar(
              `Product archived successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `Product unarchived successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to get signed urls
 * @param {*} data: data
 * @returns
 */
export const getSignedUrlsRequest = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/files/signedURLs`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to upload file to signed url
 * @param {*} data: data
 * @returns
 */
export const uploadToS3Request = (url, file) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const getAllProductsRequest = (vendorId, data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getAllProductsWithoutPaginationRequest = (vendorId, data) => {
  let offset = null;
  let limit = null;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getProductWithAssociatedUserRequest = (userId) => {
  const url = `${API_ACTIONS.PRODUCT}/associate/user/${userId}`;
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};
