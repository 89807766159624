import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  FormHelperText,
  Grid,
  Icon,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import {DateRangePicker} from 'materialui-daterange-picker';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';

const DateRangeFilter = React.memo(
  ({
     filterName,
     title,
     saveButtonText,
     clearButtonText,
     onChangeFilter,
     value,
   }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [rangeValue, setRangeValue] = useState({});
    const [error, setError] = useState('');

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    useEffect(() => {
      if (value) {
        setRangeValue(value);
      }
    }, [value]);

    const onApplyFilter = () => {
      if (
        rangeValue.startDate !== undefined &&
        rangeValue.endDate !== undefined
      ) {
        onChangeFilter(rangeValue);
        setAnchorEl(null);
        setOpen(false);
      } else {
        setError('Please select start and end date both');
      }
    };

    const onClearFilter = (event) => {
      if (event) {
        event.stopPropagation();
      } else {
      }
      onChangeFilter(null);
      setRangeValue({});
      setAnchorEl(null);
      setOpen(false);
      setError('');
    };

    const clickAwayHandler = () => {
      const rangeVal = value ? value : {};
      setRangeValue(rangeVal);
      setAnchorEl(null);
      setOpen(false);
      setError('');
    };

    return (
      <>
        {open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              transition
              className={classes.popperContainer}
            >
              {({TransitionProps}) => (
                <Fade {...TransitionProps}>
                  <Paper classes={{root: classes.paperRoot}}>
                    <Typography variant="h6">{title}</Typography>
                    <Box mt={2}>
                      <DateRangePicker
                        open={open}
                        toggle={() => setOpen(!open)}
                        onChange={(range) => {
                          setError('');
                          setRangeValue(range);
                        }}
                        initialDateRange={rangeValue}
                        wrapperClassName="date-range-container"
                      />
                    </Box>

                    {error && (
                      <Box mt={1} ml={1}>
                        <FormHelperText error>{error}</FormHelperText>
                      </Box>
                    )}

                    <Grid container spacing={1}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        classes={{item: classes.actionsGrid}}
                      >
                        <Button
                          variant="text"
                          color="inherit"
                          onClick={() => onClearFilter()}
                        >
                          {clearButtonText}
                        </Button>
                        <Button
                          classes={{root: classes.applyButton}}
                          variant="contained"
                          color="secondary"
                          onClick={() => onApplyFilter()}
                        >
                          {saveButtonText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}
        <Button
          size="small"
          variant={value ? 'contained' : 'outlined'}
          color={value ? 'secondary' : 'inherit'}
          onClick={handleClickMenu}
        >
          {`${filterName} ${
            value
              ? '(' +
              moment(value['startDate']).format('DD MMM YYYY') +
              ' - ' +
              moment(value['endDate']).format('DD MMM YYYY') +
              ')'
              : ''
          }`}
          {value && (
            <Icon onClick={(e) => onClearFilter(e)}>close_rounded</Icon>
          )}
        </Button>
      </>
    );
  }
);

export default DateRangeFilter;
