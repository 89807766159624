import ThemeOne from '../theme.json';

const MuiDialogTitle = {
  styleOverrides: {
    root: {
      padding: '24px 24px 5px 24px',
      fontFamily: ThemeOne.fontFamily,
    },
  },
};

export default MuiDialogTitle;
