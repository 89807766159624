import {createTheme} from '@mui/material/styles';
import ThemeOne from '../theme.json';

const theme = createTheme();

const MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: '24px 24px 26px 24px',
      fontFamily: ThemeOne.fontFamily,
      [theme.breakpoints.down('md')]: {
        padding: '0px 16px !important',
      },
    },
  },
};

export default MuiDialogContent;
