import ThemeOne from '../theme.json';

const MuiTypography = {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8,
    },

    button: {
      color: `${ThemeOne.secondaryColor} !important`,
    },
  },
};

export default MuiTypography;
