import {Box, Button, Grid, Icon, Paper, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {CustomPagination, MessageModal} from '../../../../utils/components';
import {DateRangeFilter, MultiselectFilter, NumberRangeFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import {archiveUnarchiveVideoRequest, getTagsRequest, getWhiskeyTubesRequest,} from '../../WhiskeyTubeApiActions';
import {VideoForm, WhiskeyTubeCard} from '../index';
import {useStyles} from './styles';

/**
 * function to render whiskey tube list
 * @param {*} getWhiskeyTubes: function to get whiskey tubes
 * @param archiveUnarchiveVideo
 * @param getTags
 * @returns
 */
const WhiskeyTubeList = ({
                           getWhiskeyTubes,
                           archiveUnarchiveVideo,
                           getTags,
                         }) => {
  const classes = useStyles();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      creationDate: null,
      tags: [],
      comments: null,
      shares: null,
      likes: null,
      saves: null,
    },
  });
  const [whiskeyTubeList, setWhiskeyTubeList] = useState(null);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const modalRef = useRef();

  const [tagList, setTagList] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [clickedVideoCommentList, setClickedVideoCommentList] = useState('');

  const getTagList = async () => {
    const {data} = await getTags();
    setTagList(data);
  };
  useEffect(() => {
    getTagList();

  }, []);

  /**
   * function to get whiskey tube list
   * @param {*} requestData: request data
   */
  const getWhiskeyTubeList = async (requestData) => {
    try {
      const {data} = await getWhiskeyTubes(requestData);
      setWhiskeyTubeList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getWhiskeyTubeList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getWhiskeyTubeList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle video form open
   */
  const handleVideoFormOpen = () => {
    setShowVideoForm(true);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to handle edit click
   * @param {*} id: id
   */
  const handleCardEditButtonClick = (id) => {
    setShowVideoForm(true);
    const clickedVideoData = whiskeyTubeList?.rows?.find(
      (video) => video.id === id
    );
    setSelectedVideo(clickedVideoData);
  };

  const handleCommentListClick = (id) => {
    setClickedVideoCommentList(id);
  };

  /**
   * function to handle archive or unarchive
   * @param {*} videoId: video id
   * @param archive
   * @param {*} videoTitle: video title
   */
  const onArchiveUnarchive = (videoId, archive, videoTitle) => {
    const title = `${archive ? 'Unarchive' : 'Archive'} Video`;
    const message = `Are you sure you want to ${
      archive ? 'unarchive' : 'archive'
    } ${videoTitle}`;
    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async (note) => {
        const data = {
          archive: !archive,
        };
        await archiveUnarchiveVideo(videoId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  return (
    <>
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={2}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_creation_date_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_creation_date_label'
                )}
                value={paginationParams.searchData.creationDate}
                onChangeFilter={(value) =>
                  handleChangeFilter('creationDate', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <MultiselectFilter
                arrayList={tagList ? tagList : []}
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_tag_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_tag_label'
                )}
                filterOn="name"
                value={paginationParams.searchData.tags}
                onChangeFilter={(value) => handleChangeFilter('tags', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_comment_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_comment_label'
                )}
                value={paginationParams.searchData.comments}
                onChangeFilter={(value) =>
                  handleChangeFilter('comments', value)
                }
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_like_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_like_label'
                )}
                value={paginationParams.searchData.likes}
                onChangeFilter={(value) => handleChangeFilter('likes', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_save_label'
                )}
                title={I18n.t(
                  'whiskey_tube.whiskey_tube_list.filter_save_label'
                )}
                value={paginationParams.searchData.saves}
                onChangeFilter={(value) => handleChangeFilter('saves', value)}
                saveButtonText={I18n.t('global.button_labels.apply')}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={handleVideoFormOpen}
            >
              {I18n.t('whiskey_tube.whiskey_tube_list.add_video_label')}
            </Button>
          </Box>
        </Box>
        <Box>
          {whiskeyTubeList?.rows.length === 0 && (
            <Typography variant="subtitle1" className={classes.noDataAvailable}>
              No data available
            </Typography>
          )}

          {whiskeyTubeList?.rows.map((video, index) => (
            <Box key={video?.id}>
              <WhiskeyTubeCard
                video={video}
                handleCardEditButtonClick={(id) =>
                  handleCardEditButtonClick(id)
                }
                onArchiveUnarchive={onArchiveUnarchive}
                handleCommentListClick={handleCommentListClick}
                clickedVideoCommentList={clickedVideoCommentList}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <CustomPagination
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          count={whiskeyTubeList?.count}
          rowsPerPage={paginationParams.rowsPerPage}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </Box>

      {showVideoForm && (
        <VideoForm
          open={showVideoForm}
          setOpen={setShowVideoForm}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          setRefreshList={setRefreshList}
        />
      )}

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getTags: async () => {
      try {
        return await dispatch(getTagsRequest());
      } catch (error) {
        throw error;
      }
    },

    getWhiskeyTubes: async (data) => {
      try {
        return await dispatch(getWhiskeyTubesRequest(data));
      } catch (error) {
        throw error;
      }
    },

    archiveUnarchiveVideo: async (id, data) => {
      try {
        return await dispatch(archiveUnarchiveVideoRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiskeyTubeList);
