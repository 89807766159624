import {colors} from '@mui/material';
import ThemeOne from './theme.json';

const white = '#ffffff';
const black = '#000000';
const primary = ThemeOne.primaryColor;
const secondary = ThemeOne.secondaryColor;

const palette = {
  common: {
    black: black,
    white: white,
  },
  type: 'light',
  primary: {
    contrastText: white,
    dark: primary,
    main: primary,
    light: primary,
  },
  secondary: {
    contrastText: white,
    dark: secondary,
    main: secondary,
    light: secondary,
  },
  error: {
    contrastText: white,
    dark: ThemeOne.errorColor,
    main: ThemeOne.errorColor,
    light: ThemeOne.errorColor,
  },
  warning: {
    contrastText: white,
    dark: ThemeOne.warningColor,
    main: ThemeOne.warningColor,
    light: ThemeOne.warningColor,
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  success: {
    contrastText: white,
    dark: ThemeOne.successColor,
    main: ThemeOne.successColor,
    light: ThemeOne.successColor,
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  text: {
    primary: ThemeOne.fontColor,
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#fafafa',
    paper: white,
  },
  icon: ThemeOne.fontColor,
  divider: 'rgba(0, 0, 0, 0.12)',
};

export default palette;
