import {Tooltip} from '@mui/material';
import React from 'react';
import './FileName.css';

/**
 * function to render file name
 * @param {*} text: text
 * @param {*} title: title
 * @returns
 */
const TextTooltip = ({text, title}) => {
  text = text || text === 0 ? text : '';
  return (
    <Tooltip title={text}>
      <div className={`sa-file file-name-tooltip`}>{text}</div>
    </Tooltip>
  );
};

export default TextTooltip;
