import {Dialog, DialogContent, Grid, Typography} from '@mui/material';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getVendorRequest, updateVendorRequest} from '../../VendorApiActions';
import {VendorForm} from '../index';
import './UpdateVendor.css';

/**
 * function to render update vendor
 * @param vendor
 * @param {*} getVendor: function to get vendor
 * @param {*} updateVendor: function to update vendor
 * @param {*} handleCloseDialog: function to handle dialog close
 * @param {*} vendorId: vendor id
 * @param {*} openDialog: boolean to open the dialog
 * @param setVendor
 * @param setRefreshDetails
 * @returns
 */
const UpdateVendor = ({
                        vendor,
                        getVendor,
                        updateVendor,
                        handleCloseDialog,
                        vendorId,
                        openDialog,
                        setVendor,
                        setRefreshDetails,
                      }) => {
  /**
   * function to handle update vendor
   * @param {*} vendor
   */
  const handleUpdateVendor = async (vendor) => {
    const requestData = {
      ...vendor,
      tagline: vendor.title,
    };

    delete requestData['title'];
    delete requestData['coverImage'];
    delete requestData['coverImgUrl'];
    delete requestData['coverPhoto'];
    delete requestData['icon'];
    delete requestData['profileImage'];
    delete requestData['profileImgUrl'];

    await updateVendor(vendorId, requestData);

    handleCloseDialog();
    setRefreshDetails(true);
  };

  /**
   * function to handle cancel update vendor
   */
  const onCancelUpdateVendor = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openDialog && vendor}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="vendor-dialog-form-content">
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" className="vendor-dialog-title">
              {I18n.t('vendor.add_vendor.form_title_update_vendor_label')}
            </Typography>
            <VendorForm
              onCancelUpdateVendor={onCancelUpdateVendor}
              submitFormData={handleUpdateVendor}
              formData={vendor}
              isUpdate={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateVendor: async (vendorId, vendor) => {
      try {
        return await dispatch(updateVendorRequest(vendorId, vendor));
      } catch (error) {
        throw error;
      }
    },

    getVendor: async (vendorId) => {
      try {
        return await dispatch(getVendorRequest(vendorId));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVendor);
