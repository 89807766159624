import {colors} from '@mui/material';
import palette from '../palette';

const MuiIconButton = {
  styleOverrides: {
    root: {
      color: palette.icon,
      fontFamily: 'Lato',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },
      '.Mui-disabled': {
        color: colors.blueGrey[600],
      },
    },
  },
};

export default MuiIconButton;
