import {Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getCommentsRequest} from '../../WhiskeyTubeApiActions';
import {CommentItem} from '../index';

/**
 * function to render comment list
 * @returns
 */
const CommentList = ({video, clickedVideoCommentList, getComments}) => {
  const [commentList, setCommentList] = useState(null);
  const [customExpanded, setCustomExpanded] = useState(false);

  const getCommentList = async (videoId) => {
    const {data} = await getComments(videoId);
    setCommentList(data);
  };

  useEffect(() => {
    if (video.id === clickedVideoCommentList) {
      getCommentList(clickedVideoCommentList);
    }
  }, [clickedVideoCommentList]);

  return (
    <Box>
      <Accordion
        expanded={video.id === clickedVideoCommentList && customExpanded}
        onChange={(e, expanded) => setCustomExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<Icon style={{color: '#bcccdc'}}>expand_more</Icon>}
        >
          <Typography>
            {I18n.t('whiskey_tube.whiskey_tube_list.comments_label')}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {commentList?.length === 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">No comments available</Typography>
            </Box>
          )}

          {commentList?.length > 0 && (
            <>
              {commentList?.map((comment) => (
                <Box key={comment.id}>
                  <CommentItem comment={comment}/>
                </Box>
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

/**
 * function to map redux state to props
 * @param {*} state: state object
 * @returns {Promise}
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map redux dispatch function to props
 * @param {*} dispatch: dispatch function
 * @returns {Promise}
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getComments: async (id) => {
      try {
        return await dispatch(getCommentsRequest(id));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
