import {DesktopDatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Button, Dialog, DialogContent, FormHelperText, Grid, TextField, Typography,} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import UtilHelper from '../../../../utils/UtilHelper';
import {downloadOrdersRequest} from '../../OrderApiActions';
import {useStyles} from './styles';

/**
 * function to render video form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to change the open state
 * @param {*} selectedVideo: selected video
 * @returns
 */
const ExportForm = ({open, setOpen, downloadOrders}) => {
  const classes = useStyles();

  const [exportInfo, setExportInfo] = useState({
    startDate: null,
    endDate: null,
  });
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [dateDiffError, setDateDiffError] = useState('');

  useEffect(() => {
    if (exportInfo.startDate && exportInfo.endDate) {
      const dateDiff = UtilHelper.differenceInDaysBetweenTwoDate(
        exportInfo.startDate,
        exportInfo.endDate
      );

      if (dateDiff > 30) {
        setDateDiffError('Date range can not be more than 30 days');
      } else if (dateDiff < 0) {
        setDateDiffError('End date can not be before start date');
      } else {
        setDateDiffError('');
      }
    }
  }, [exportInfo.startDate, exportInfo.endDate]);

  /**
   * function to clear the form
   */
  const clearForm = () => {
    setExportInfo({
      startDate: null,
      endDate: null,
    });
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, exportInfo);
    data[name] = value;
    setExportInfo(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    validateForm();

    if (exportInfo.startDate && exportInfo.endDate && dateDiffError === '' && startDateError === '' && endDateError === '') {
      try {
        await downloadOrders(
          moment(exportInfo.startDate).format('YYYY-MM-DD'),
          moment(exportInfo.endDate).format('YYYY-MM-DD')
        );
      } catch (error) {}

      handleVideoFormClose();
    }
  };

  /**
   * function to handle form close
   */
  const handleVideoFormClose = () => {
    setOpen(false);
    clearForm();
  };

  const validateForm = () => {
    if (exportInfo.startDate === null) {
      setStartDateError(I18n.t('error_messages.field_required'));
    }

    if (exportInfo.endDate === null) {
      setEndDateError(I18n.t('error_messages.field_required'));
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    validateForm();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {I18n.t('orders.export_form.form_heading')}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disableCloseOnSelect
                      label={I18n.t('orders.export_form.start_date_label')}
                      maxDate={new Date()}
                      inputFormat="dd/MM/yyyy"
                      value={exportInfo.startDate}
                      onChange={(date) =>
                        handleDateTimeInputChange('startDate', date)
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          fullWidth
                          {...params}
                          helperText={startDateError}
                          FormHelperTextProps={{
                            error: true,
                          }}
                        />
                      )}
                      onError={(reason, value) => {
                        if (reason === 'invalidDate') {
                          setStartDateError(
                            I18n.t('error_messages.invalid_date')
                          );
                        }

                        if (reason === 'maxDate') {
                          setStartDateError(
                            I18n.t('error_messages.future_date')
                          );
                        }

                        if (reason === null) {
                          setStartDateError('');
                        }
                      }}
                      onClose={() => {
                        setStartDateError('');
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disableCloseOnSelect
                      label={I18n.t('orders.export_form.end_date_label')}
                      maxDate={new Date()}
                      inputFormat="dd/MM/yyyy"
                      value={exportInfo.endDate}
                      onChange={(date) =>
                        handleDateTimeInputChange('endDate', date)
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          fullWidth
                          {...params}
                          helperText={endDateError}
                          FormHelperTextProps={{
                            error: true,
                          }}
                        />
                      )}
                      onError={(reason, value) => {
                        if (reason === 'invalidDate') {
                          setEndDateError(
                            I18n.t('error_messages.invalid_date')
                          );
                        }

                        if (reason === 'maxDate') {
                          setEndDateError(I18n.t('error_messages.future_date'));
                        }

                        if (reason === null) {
                          setEndDateError('');
                        }
                      }}
                      onClose={() => {
                        setEndDateError('');
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {dateDiffError && (
                    <FormHelperText
                      error
                      style={{marginTop: '-10px', marginLeft: '14px'}}
                    >
                      {dateDiffError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.export')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleVideoFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    downloadOrders: async (startDate, endDate) => {
      try {
        return await dispatch(downloadOrdersRequest(startDate, endDate));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportForm);
