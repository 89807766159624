import {DesktopDatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Button, Dialog, DialogContent, Grid, MenuItem, TextField, Typography,} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {SelectValidator, ValidatorForm,} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {updateOrderRequest} from '../../OrderApiActions';
import {useStyles} from './styles';

/**
 * function to render video form
 * @param formSection
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to change the open state
 * @param {*} selectedOrder: selected video
 * @param {*} setSelectedOrder: function to set the selected video
 * @param setRefreshList
 * @param updateOrder
 * @returns
 */
const OrderStatusForm = ({
                           formSection,
                           open,
                           setOpen,
                           selectedOrder,
                           setSelectedOrder,
                           setRefreshList,
                           updateOrder,
                         }) => {
  const classes = useStyles();

  const [orderDetails, setOrderDetails] = useState({
    orderStatus: '',
    deliveryDate: null,
    isPaymentMadeToVendor: 'no',
    paymentToVendorDate: null,
  });

  const [paymentToVendorDateError, setPaymentToVendorDateError] = useState('');
  const [deliveryDateError, setDeliveryDateError] = useState('');

  useEffect(() => {
    if (orderDetails.isPaymentMadeToVendor === 'no') {
      setOrderDetails({
        ...orderDetails,
        paymentToVendorDate: null,
      });
    }

  }, [orderDetails.isPaymentMadeToVendor]);

  useEffect(() => {
    if (selectedOrder) {
      setOrderDetails({
        orderStatus: selectedOrder?.status,
        deliveryDate: selectedOrder?.dateOfDelivery,
        isPaymentMadeToVendor: selectedOrder?.vendorPaymentMade ? 'yes' : 'no',
        paymentToVendorDate: selectedOrder?.vendorPaymentDate,
      });
    }
  }, [selectedOrder]);

  /**
   * function to clear the form
   */
  const clearForm = () => {
    setOrderDetails({
      orderStatus: '',
      deliveryDate: null,
      isPaymentMadeToVendor: 'no',
      paymentToVendorDate: null,
    });
  };

  /**
   * function to handle text input change
   * @param {*} event: event object
   */
  const handleChangeSelect = (event) => {
    const data = Object.assign({}, orderDetails);
    data[event.target.name] = event.target.value;
    setOrderDetails(data);
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, orderDetails);
    data[name] = value;
    setOrderDetails(data);
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    const data = {
      status: orderDetails.orderStatus,
      dateOfDelivery: orderDetails.deliveryDate
        ? moment(orderDetails.deliveryDate).format('YYYY-MM-DD')
        : null,
      vendorPaymentMade:
        orderDetails.isPaymentMadeToVendor === 'yes',
      vendorPaymentDate: orderDetails.paymentToVendorDate
        ? moment(orderDetails.paymentToVendorDate).format('YYYY-MM-DD')
        : null,
    };

    if (formSection === 'vendorSection') {
      delete data['status'];
      delete data['dateOfDelivery'];
    } else {
      delete data['vendorPaymentMade'];
      delete data['vendorPaymentDate'];
    }

    await updateOrder(selectedOrder?.id, data);

    handleVideoFormClose();
    setRefreshList(true);
  };

  /**
   * function to handle form close
   */
  const handleVideoFormClose = () => {
    setOpen(false);
    clearForm();

    if (selectedOrder) {
      setSelectedOrder(null);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {};

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {I18n.t('orders.order_status_form.form_heading')}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                {formSection === 'orderSection' && (
                  <>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <SelectValidator
                        variant="standard"
                        fullWidth
                        required
                        size="small"
                        label={I18n.t('orders.order_status_form.order_status_label')}
                        onChange={handleChangeSelect}
                        name="orderStatus"
                        value={orderDetails.orderStatus}
                        validators={['required']}
                        errorMessages={[
                          I18n.t('error_messages.field_required'),
                        ]}
                      >
                        <MenuItem value="Yet to confirm">
                          Yet to confirm
                        </MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                        <MenuItem value="Preparing order">
                          Preparing order
                        </MenuItem>
                        <MenuItem value="Shipped">Shipped</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </SelectValidator>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disableCloseOnSelect
                          label={I18n.t('orders.order_status_form.delevery_date_label')}
                          inputFormat="dd/MM/yyyy"
                          value={orderDetails.deliveryDate}
                          onChange={(date) =>
                            handleDateTimeInputChange('deliveryDate', date)
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              fullWidth
                              {...params}
                              helperText={deliveryDateError}
                              FormHelperTextProps={{
                                error: true,
                              }}
                            />
                          )}
                          onError={(reason, value) => {
                            if (reason === 'invalidDate') {
                              setDeliveryDateError(
                                I18n.t('error_messages.invalid_date')
                              );
                            }
                            if (reason === null) {
                              setDeliveryDateError('');
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}

                {formSection === 'vendorSection' && (
                  <>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <SelectValidator
                          variant="standard"
                          fullWidth
                          required
                          size="small"
                          label={I18n.t('orders.order_status_form.vendor_payment_made_label')}
                          onChange={handleChangeSelect}
                          name="isPaymentMadeToVendor"
                          value={orderDetails.isPaymentMadeToVendor}
                          validators={['required']}
                          errorMessages={[
                            I18n.t('error_messages.field_required'),
                          ]}
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </SelectValidator>
                      </Grid>
                    </Grid>

                    {orderDetails?.isPaymentMadeToVendor === 'yes' && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disableCloseOnSelect
                              label={I18n.t('orders.order_status_form.vendor_payment_date_label')}
                              maxDate={new Date()}
                              inputFormat="dd/MM/yyyy"
                              value={orderDetails.paymentToVendorDate}
                              onChange={(date) =>
                                handleDateTimeInputChange(
                                  'paymentToVendorDate',
                                  date
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  {...params}
                                  helperText={paymentToVendorDateError}
                                  FormHelperTextProps={{
                                    error: true,
                                  }}
                                />
                              )}
                              onError={(reason, value) => {
                                if (reason === 'invalidDate') {
                                  setPaymentToVendorDateError(
                                    I18n.t('error_messages.invalid_date')
                                  );
                                }

                                if (reason === 'maxDate') {
                                  setPaymentToVendorDateError(
                                    I18n.t('error_messages.future_date')
                                  );
                                }

                                if (reason === null) {
                                  setPaymentToVendorDateError('');
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleVideoFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateOrder: async (id, data) => {
      try {
        return await dispatch(updateOrderRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusForm);
