import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {addBrandRequest, updateBrandRequest} from '../../BrandApiActions';
import {useStyles} from './styles';

/**
 * function to render super admin form
 * @param {*} open: boolean to open the form
 * @param {*} setOpen: function to set the open state
 * @param {*} selectedCuisine: selected user
 * @param {*} setSelectedCuisine: function to set the selected user
 * @param setRefreshList
 * @param addCuisine
 * @param updateCuisine
 * @returns
 */
const BrandForm = ({
                     open,
                     setOpen,
                     selectedCuisine,
                     setSelectedCuisine,
                     setRefreshList,
                     addCuisine,
                     updateCuisine,
                   }) => {
  const classes = useStyles();

  const [user, setUser] = useState({
    name: '',
    active: 'yes',
  });

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (selectedCuisine) {
      setUser({
        name: selectedCuisine?.name,
        active: selectedCuisine?.active ? 'yes' : 'no',
      });
    }
  }, [selectedCuisine]);

  /**
   * function to handle text input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, user);
    data[event.target.name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle radio input change
   * @param {*} name: name
   * @param {*} event: event
   */
  const handleRadioInput = (name, event) => {
    const data = Object.assign({}, user);
    data[name] = event.target.value;
    setUser(data);
  };

  /**
   * function to handle form close
   */
  const handleCuisineFormClose = () => {
    setOpen(false);

    if (selectedCuisine) {
      setSelectedCuisine(null);
    }
  };

  /**
   * function to handle form submit
   */
  const handleSubmit = async () => {
    try {
      const data = {
        ...user,
        active: user.active === 'yes',
      };

      if (selectedCuisine) {
        await updateCuisine(selectedCuisine?.id, UtilHelper.trimObject(data));

        handleCuisineFormClose();
        setRefreshList(true);
      } else {
        await addCuisine(UtilHelper.trimObject(data));

        handleCuisineFormClose();
        setRefreshList(true);
      }
    } catch (error) {}
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {};

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" classes={{root: classes.headingText}}>
              {!selectedCuisine
                ? `${I18n.t('brands.brand_form.form_title_add_label')}`
                : `${I18n.t('brands.brand_form.form_title_update_label')}`}
            </Typography>

            <ValidatorForm
              onSubmit={handleSubmit}
              noValidate
              onError={onFormError}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextValidator
                    variant="standard"
                    fullWidth
                    required
                    size="small"
                    helperText={`${user.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`}
                    label={I18n.t(
                      'cuisines.cuisine_form.form_field_name_label'
                    )}
                    onChange={handleChangeInput}
                    name="name"
                    value={user.name}
                    validators={[
                      'required',
                      ONLY_BLANK_SPACES,
                      `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_50}`,
                      `matchRegexp:${Constants.REGEX.LETTERS_SPACE_NUMBERS}`,
                    ]}
                    errorMessages={[
                      I18n.t('error_messages.field_required'),
                      I18n.t('error_messages.blank_spaces_not_allowed'),
                      I18n.t(
                        'error_messages.maximum_50_allowed_characters_for_text'
                      ),
                      I18n.t('error_messages.only_letters_numbers'),
                    ]}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <FormLabel classes={{root: classes.formLabelRoot}}>
                          {I18n.t('brands.brand_form.is_shown_label')}
                        </FormLabel>
                      </Box>
                    </Box>

                    <RadioGroup
                      row
                      value={user.active}
                      onChange={(event) => handleRadioInput('active', event)}
                    >
                      <FormControlLabel
                        value={'yes'}
                        control={<Radio color="secondary"/>}
                        label={I18n.t('brands.brand_form.yes_label')}
                      />
                      <FormControlLabel
                        value={'no'}
                        control={<Radio color="secondary"/>}
                        label={I18n.t('brands.brand_form.no_label')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="invite-add-btn text-transform-capitalize"
                  >
                    {I18n.t('global.button_labels.save')}
                  </Button>

                  <Button
                    color="inherit"
                    variant="text"
                    className="cancel-btn text-transform-capitalize"
                    onClick={handleCuisineFormClose}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    addCuisine: async (data) => {
      try {
        return await dispatch(addBrandRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateCuisine: async (userId, data) => {
      try {
        return await dispatch(updateBrandRequest(userId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
