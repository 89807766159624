import {Avatar, Box, Chip, Grid, Icon, Paper, Tooltip, Typography,} from '@mui/material';
import {CustomErrorComponent} from 'custom-error';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player';
import {connect} from 'react-redux';
// import { useStyles } from './styles';
import {I18n} from 'react-redux-i18n';
import {useParams,} from 'react-router-dom';
import {CustomFilePreviewSA, MessageModal,} from '../../../../utils/components';
import UtilHelper from '../../../../utils/UtilHelper';
import {cancelUncancelEventRequest, getEventRequest,} from '../../EventsApiActions';

/**
 * function to render event detailed view
 * @param {*} history: history object
 * @param getEvent
 * @param cancelUncancelEvent
 * @returns
 */
const EventDetails = ({history, getEvent, cancelUncancelEvent}) => {
  const {eventId} = useParams();
  const modalRef = useRef();
  const [event, setEvent] = useState(null);

  const getEventDetails = async (id) => {
    const res = await getEvent(id);

    const data = {...res?.data, mediaUrls: []};

    res.data?.media.forEach((mediaItem) => {
      const fileExtension = mediaItem.type.split('.').pop();
      let tempObj = {
        type: fileExtension,
        path: mediaItem.path,
        fileName: mediaItem.fileName,
        fileType: mediaItem.type,
        url: mediaItem.readSignedURL,
        original: mediaItem.readSignedURL,
        thumbnail: mediaItem.readSignedURL,
      };

      if (fileExtension === 'pdf') {
        tempObj.renderItem = (props) => {
          return (
            <FileViewer
              fileType={props.type}
              filePath={props.url}
              errorComponent={CustomErrorComponent}
            />
          );
        };
        tempObj.renderThumbInner = () => {
          return 'pdf';
        };
      } else if (fileExtension === 'mp4') {
        tempObj.renderItem = (props) => {
          return <ReactPlayer playing={true} url={props.url}/>;
        };
        tempObj.renderThumbInner = () => {
          return <Icon>movie</Icon>;
        };
      } else {
      }

      data.mediaUrls.push(tempObj);
    });

    setEvent(data);
  };

  useEffect(() => {
    getEventDetails(eventId).then(r => {});
  }, [eventId]);

  return (
    <>
      <Paper component={Box} p={3}>
        <Box mb={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center">
                <Box mt={0.4} mr={1} ml={-0.5}>
                  <Tooltip title="Back">
                    <Icon onClick={() => history.goBack()}>
                      arrow_back_rounded
                    </Icon>
                  </Tooltip>
                </Box>

                <Box>
                  <Typography variant="h6">
                    {I18n.t('event.event_details.page_header_label')}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Tooltip title="Total Tickets">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Icon>credit_card</Icon>{' '}
                    <Typography variant="body2">{event?.tickets}</Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Ticket Cost">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Typography variant="body1">
                      {UtilHelper.convertNumberToMoney(event?.cost)}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Sold Tickets">
                  <Box display="flex" alignItems="center" mr={4}>
                    <Icon>inventory_2</Icon>{' '}
                    <Typography variant="body2">{event?.soldTicket}</Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Booked Ticket Amount">
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">
                      {UtilHelper.convertNumberToMoney(event?.soldTicket * event?.cost)}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box>
                </Box>
                <Box ml={1}>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_name_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{event?.name}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_vendor_label')}:
                  </Typography>
                </Box>
                <Box>
                  {event?.Vendor ? (
                    <Chip
                      variant="outlined"
                      avatar={
                        <Avatar
                          alt="vendor"
                          src={event?.Vendor?.profileImgUrl}
                        />
                      }
                      label={event?.Vendor?.name}
                    />
                  ) : (
                    'NA'
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_timing_label')}:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {moment(event?.date).format('DD/MM/YYYY')},{' '}
                    {`${moment(event?.startTime).format('hh:mm a')} - ${moment(
                      event?.endTime
                    ).format('hh:mm a')}`}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" alignItems="center" mb={2.5}>
                <Box mr={1}>
                  <Typography variant="body1">
                    {I18n.t(
                      'event.event_details.event_field_contact_details_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="body2">
                      {event?.personName},{' '}
                    </Typography>
                  </Box>
                  <Box ml={1}>
                    <Typography variant="body2">
                      {event?.personPhone}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_address_label')}:
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="body2">{event?.address}</Typography>
                </Box>
                <Box borderBottom="1px solid #334e68" mt={2.5}/>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant="body1">
                    {I18n.t(
                      'event.event_details.event_field_about_event_label'
                    )}
                    :
                  </Typography>
                </Box>
                <Box>
                  <div
                    dangerouslySetInnerHTML={{__html: event?.description}}
                  />
                </Box>
                <Box borderBottom="1px solid #334e68" mt={2.5}/>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box mb={2.5}>
                <Box>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_terms_label')}:
                  </Typography>
                </Box>
                <Box>
                  <div dangerouslySetInnerHTML={{__html: event?.terms}}/>
                </Box>
                <Box borderBottom="1px solid #334e68" mt={2.5}/>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {event?.mediaUrls && event?.mediaUrls.length > 0 && (
                <Box mb={2.5}>
                  <Typography variant="body1">
                    {I18n.t('event.event_details.event_field_media_label')}:
                  </Typography>
                </Box>
              )}

              {event?.mediaUrls && event?.mediaUrls.length > 0 && (
                <CustomFilePreviewSA files={event?.mediaUrls}/>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getEvent: async (id) => {
      try {
        return await dispatch(getEventRequest(id));
      } catch (error) {
        throw error;
      }
    },

    cancelUncancelEvent: async (id, data) => {
      try {
        return await dispatch(cancelUncancelEventRequest(id, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
