import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    label: {
      fontSize: '15px !important',
      fontFamily: 'lato !important',
      fontWeight: 'normal !important',
      lineHeight: 'normal !important',
      letterSpacing: '0.25px !important',
      color: '#8b98a4 !important',
      marginBottom: '10px !important',
      marginTop: '10px !important',
    },

    dateTimeHealperText: {
      color: 'red',
    },
  };
});
