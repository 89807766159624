const MuiInputLabel = {
  styleOverrides: {
    root: {
      fontFamily: 'Lato',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.25px',
      textAlign: 'left',
      color: '#8b98a4',
      '&.Mui-focused': {
        color: '#8b98a4',
      },
      '&.Mui-marginDense': {
        transform: 'translate(0, 28px) scale(1)',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 0.2px) scale(1)',
      },
      '&.Mui-error': {
        color: '#dc545c !important',
      },
      '&.Mui-disabled': {
        color: '#8b98a4 !important',
      },
    },
  },
};

export default MuiInputLabel;
