import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    formLabelRoot: {
      fontSize: '0.9rem !important',
      opacity: '0.7 !important',
      fontWeight: 'normal !important',
      color: 'inherit !important',
    },
  };
});
