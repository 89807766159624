import {I18n} from 'react-redux-i18n';
import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get surveys
 * @param {*} data: data for server request
 * @returns
 */
export const getSurveysRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.SURVEY
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to delete survey
 * @param {*} surveyId: survey id
 * @param {*} data: data
 * @returns
 */
export const deleteSurveyRequest = (surveyId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY}/${surveyId}/delete`,
      'delete',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(`Survey deleted successfully`, SNACKBAR_VARIAINT.SUCCESS)
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        if (error?.response?.data?.error) {
          dispatch(
            showSnackbar(error?.response?.data?.error, SNACKBAR_VARIAINT.ERROR)
          );
        } else {
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
        }

        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to add survey
 * @param {*} data: data
 * @returns
 */
export const addSurveyRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.SURVEY}`;
    return new HttpRequest(dispatch, url, 'post', data).send().then(
      (res) => {
        dispatch(
          showSnackbar(
            `Survey details saved successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        dispatch(showSnackbar(
          error.response.data.error,
          SNACKBAR_VARIAINT.ERROR
        ));
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to update survey
 * @param {*} data: data
 * @returns
 */
export const updateSurveyRequest = (surveyId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.SURVEY}/${surveyId}`;
    return new HttpRequest(dispatch, url, 'patch', data).send().then(
      (res) => {
        dispatch(
          showSnackbar(
            `Survey details saved successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get survey
 * @param {*} surveyId
 * @returns
 */
export const getSurveyRequest = (surveyId) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.SURVEY}/${surveyId}`, 'get')
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to get survey
 * @param {*} surveyId
 * @returns
 */
export const getSurveyQuestionsRequest = (surveyId) => {
  let searchData = {
    SurveyId: surveyId,
  };

  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY_QUESTION}?searchData=${JSON.stringify(searchData)}`,
      'get'
    )
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to get survey
 * @param {*} surveyId
 * @returns
 */
export const addSurveyQuestionRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY_QUESTION}`,
      'post',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Question added successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          return Promise.resolve(res.data.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to get survey
 * @param {*} surveyId
 * @returns
 */
export const updateSurveyQuestionRequest = (questionId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY_QUESTION}/${questionId}`,
      'patch',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Question updated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          return Promise.resolve(res.data.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to delete survey question
 * @param {*} questionId: question id
 * @param {*} data: data
 * @returns
 */
export const deleteSurveyQuestionRequest = (questionId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY_QUESTION}/${questionId}/delete`,
      'delete',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            `Question deleted successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            `Something went wrong, please try again`,
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to get signed url
 * @param {*} data: data
 * @returns
 */
export const getSignedUrlRequest = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(
      dispatch,
      `${API_ACTIONS.SURVEY_QUESTION}/files/signedURL`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to upload file to signed url
 * @param {*} url: url
 * @param {*} file: file
 * @returns
 */
export const uploadToS3Request = (url, file) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const getAllProductsRequest = (vendorId, data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getAllProductsWithoutPaginationRequest = (vendorId, data) => {
  let offset = null;
  let limit = null;
  let searchData = data.searchData;
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.PRODUCT
    }/${vendorId}/all?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
      searchData
    )}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const getProductWithAssociatedUserRequest = (userId) => {
  const url = `${API_ACTIONS.PRODUCT}/associate/user/${userId}`;
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const addProductRequest = (vendorId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.PRODUCT}/${vendorId}`;
    return new HttpRequest(dispatch, url, 'post', data).send().then(
      (res) => {
        dispatch(
          showSnackbar(
            I18n.t('product.add_product.add_product_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

export const updateProductRequest = (productId, product) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}`,
      'patch',
      product
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('product.add_product.update_product_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

export const activateDeactivateProductRequest = (productId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.PRODUCT}/${productId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              I18n.t('product.product_table.product_enable_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('product.product_table.product_disable_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
