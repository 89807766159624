import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    checkboxRadioDisabled: {
      color: `${theme.palette.secondary.main} !important`,
    },
    checkboxRadioLabelDisabled: {
      color: `${theme.palette.text.primary} !important`,
    },

    actionIcon: {
      cursor: 'pointer',
    },

    formLabelRoot: {
      fontSize: '0.9rem !important',
      opacity: '0.7 !important',
      fontWeight: 'normal !important',
      color: 'inherit !important',
    },
  };
});
