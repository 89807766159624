const MuiAlert = {
  styleOverrides: {
    root: {
      width: '400px',
      fontFamily: 'Lato',
    },
    message: {
      fontSize: '15px',
    },
    icon: {
      fontSize: '20px',
    },
  },
};

export default MuiAlert;
