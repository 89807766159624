import {I18n} from 'react-redux-i18n';
import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get tags
 * @param {*} data: data for server request
 * @returns
 */
export const getTagsRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.WHISKEY_TUBE_TAGS}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get whiskey tubes
 * @param {*} data: data for server request
 * @returns
 */
export const getWhiskeyTubesRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.WHISKEY_TUBE_VIDEO
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add video
 * @param {*} data
 * @returns
 */
export const addVideoRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.WHISKEY_TUBE_VIDEO}`,
      'post',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Video details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to update video
 * @param {*} id: id
 * @param {*} data: data
 * @returns
 */
export const updateVideoRequest = (id, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.WHISKEY_TUBE_VIDEO}/${id}`,
      'patch',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Video details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to archive unarchive video
 * @param {*} id: id
 * @param {*} data: data
 * @returns
 */
export const archiveUnarchiveVideoRequest = (id, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.WHISKEY_TUBE_VIDEO}/${id}/archive`,
      'patch',
      data
    )
      .send()
      .then(
        (res) => {
          if (data.archive) {
            dispatch(
              showSnackbar(
                `Video archived successfully`,
                SNACKBAR_VARIAINT.SUCCESS
              )
            );
          } else {
            dispatch(
              showSnackbar(
                `Video unarchived successfully`,
                SNACKBAR_VARIAINT.SUCCESS
              )
            );
          }
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to get comments
 * @param {*} data: data for server request
 * @returns
 */
export const getCommentsRequest = (videoId) => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.WHISKEY_TUBE_VIDEO}/${videoId}/comment`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

export const addUserRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.USERS}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              I18n.t('user.add_user.add_user_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to update user in DB
 * @param {*} userId: user id for server request
 * @param {*} data: data for server request
 * @returns {Promise}
 */
export const updateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('user.add_user.update_user_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to activate / deactivate user in DB
 * @param {*} userId: user id for server request
 * @param {*} data: data for server request
 * @returns {Promise}
 */
export const activateDeactivateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              I18n.t('user.user_table.user_activate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              I18n.t('user.user_table.user_deactivate_success_message'),
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to resend invitaion to a user in DB
 * @param {*} userId: user id for server request
 * @returns {Promise}
 */
export const resendInvitationRequest = (userId) => {
  const data = {
    id: userId,
  };

  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.RESEND_INVITATION}`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('user.user_table.user_resend_invitation_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
