import {Card, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getProductRequest, updateProductRequest,} from '../../ProductApiActions';
import {ProductForm} from '../index';
import './UpdateProduct.css';

/**
 * function to render update product
 * @param {*} updateProduct: function to handle update product
 * @param {*} getProduct: function to get product details
 * @param {*} match: match
 * @param {*} history: history
 * @returns
 */
const UpdateProduct = ({updateProduct, getProduct, match, history}) => {
  const [product, setProduct] = useState(null);
  const productId = match.params.productId;
  const vendorId = match.params.vendorId;

  const getProductDetails = async (productId) => {
    const res = await getProduct(productId);

    let data = {
      name: res.data.name,
      cost: res.data.cost ? res.data.cost.toString() : '',
      category: res.data?.ProductCategory || null,
      vendor: res.data.Vendor,
      isProductAvailableInSizes: res.data.isProductAvailableInSizes
        ? 'yes'
        : 'no',
      sizes: res.data.size
        ? {
          S: res.data?.size['S'] ? res.data?.size['S'] : false,
          M: res.data?.size['M'] ? res.data?.size['M'] : false,
          L: res.data?.size['L'] ? res.data?.size['L'] : false,
          XL: res.data?.size['XL'] ? res.data?.size['L'] : false,
          XXL: res.data?.size['XXL'] ? res.data?.size['XXL'] : false,
          'Free Size': res.data?.size['Free Size']
            ? res.data?.size['Free Size']
            : false,
        }
        : {
          S: true,
          M: true,
          L: true,
          XL: true,
          XXL: true,
          'Free Size': true,
        },
      isAvailable: res.data.isAvailable
        ? {
          S: res.data?.isAvailable['S'] ? res.data?.isAvailable['S'] : 'no',
          M: res.data?.isAvailable['M'] ? res.data?.isAvailable['M'] : 'no',
          L: res.data?.isAvailable['L'] ? res.data?.isAvailable['L'] : 'no',
          XL: res.data?.isAvailable['XL']
            ? res.data?.isAvailable['XL']
            : 'no',
          XXL: res.data?.isAvailable['XXL']
            ? res.data?.isAvailable['XXL']
            : 'no',
          'Free Size': res.data?.isAvailable['Free Size']
            ? res.data?.isAvailable['Free Size']
            : 'no',
        }
        : {
          S: 'no',
          M: 'no',
          L: 'no',
          XL: 'no',
          XXL: 'no',
          'Free Size': 'no',
        },
      description: res.data.description,
      uploadedFiles: [],
      media: [],
    };

    res.data?.media.forEach((mediaItem) => {
      const fileExtension = mediaItem.type.split('.').pop();
      data.uploadedFiles.push({
        type: fileExtension,
        url: mediaItem.readSignedURL,
        path: mediaItem.path,
        fileName: mediaItem.fileName,
        fileType: mediaItem.type,
      });
    });

    setProduct(data);
  };

  useEffect(() => {
    getProductDetails(productId).then(r => {});

  }, [productId]);

  /**
   * function to handle update product
   * @param {*} data: data
   */
  const handleUpdateProduct = async (data) => {
    if (data.uploadedFiles.length > 0) {
      data.uploadedFiles.forEach((singleFile) => {
        data.media.push({
          path: singleFile.path,
          fileName: singleFile.fileName,
          fileType: singleFile.fileType,
        });
      });
    }

    const requestData = {
      ...data,
      ProductCategoryId: data?.category?.id,
      VendorId: data?.vendor?.id,
      isProductAvailableInSizes:
        data?.isProductAvailableInSizes === 'yes',
      isAvailable:
        data?.isProductAvailableInSizes === 'no' ? null : data?.isAvailable,
      size: data?.isProductAvailableInSizes === 'no' ? null : data?.sizes,
    };

    delete requestData['category'];
    delete requestData['vendor'];
    delete requestData['sizes'];
    delete requestData['uploadedFiles'];

    await updateProduct(productId, requestData);

    history.goBack();
  };

  /**
   * function to handle cancel update product
   */
  const onCancelUpdateProduct = () => {
    history.goBack();
  };

  return (
    <Card className="product-card-container">
      <Grid container spacing={0}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="h6"
            className="product-dialog-title"
            style={{marginBottom: '1rem'}}
          >
            {I18n.t('product.add_product.form_title_update_product_label')}
          </Typography>
          <ProductForm
            onCancelUpdateProduct={onCancelUpdateProduct}
            submitFormData={handleUpdateProduct}
            formData={product}
            isUpdate={true}
            vendorId={vendorId}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getProduct: async (productId) => {
      try {
        return await dispatch(getProductRequest(productId));
      } catch (error) {
        throw error;
      }
    },

    updateProduct: async (productId, data) => {
      try {
        return await dispatch(updateProductRequest(productId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
