import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import UtilHelper from '../../../UtilHelper';
import {useStyles} from './styles';

const MultiselectFilter = React.memo(
  ({
     arrayList,
     filterName,
     title,
     saveButtonText,
     clearButtonText,
     onChangeFilter,
     value,
     filterOn,
   }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [checkedArray, setCheckedArray] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    useEffect(() => {
      setCheckedArray(value);
    }, [value]);

    const handleChangeInput = (event) => {
      setSearchQuery(event.target.value);
    };

    const handleChange = (event) => {
      const data = Object.assign([], checkedArray);
      if (event.target.checked) {
        data.push(event.target.name);
      } else {
        const findIndex = data.findIndex((item) => item === event.target.name);
        if (findIndex > -1) {
          data.splice(findIndex, 1);
        }
      }

      setCheckedArray(data);
    };

    const onApplyFilter = () => {
      onChangeFilter(checkedArray);
      setAnchorEl(null);
      setOpen(false);
    };

    const onClearFilter = (event) => {
      if (event) {
        event.stopPropagation();
      } else {
      }
      onChangeFilter([]);
      setAnchorEl(null);
      setOpen(false);
    };

    const clickAwayHandler = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    const search = new RegExp(searchQuery, 'i');
    const filterArray =
      arrayList && arrayList.length > 0
        ? arrayList.filter((item) => search.test(item.name))
        : [];

    return (
      <>
        {open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              transition
              className={classes.popperContainer}
            >
              {({TransitionProps}) => (
                <Fade {...TransitionProps}>
                  <Paper classes={{root: classes.paperRoot}}>
                    <Box>
                      <Typography variant="h6">{title}</Typography>

                      <Box my={1}>
                        <TextField
                          fullWidth
                          variant="standard"
                          size="small"
                          placeholder="Search"
                          name="searchQuery"
                          value={searchQuery}
                          onChange={handleChangeInput}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>search_rounded</Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <Grid container>
                        {filterArray.map((item, index) => (
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  checked={
                                    !!checkedArray.find(
                                      (checkedItem) =>
                                        checkedItem === item[filterOn]
                                    )
                                  }
                                  onChange={handleChange}
                                  name={item[filterOn]}
                                />
                              }
                              label={UtilHelper.ellipsisRender(item.name, 50)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>

                    <Grid container spacing={1}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        classes={{item: classes.actionsGrid}}
                      >
                        <Button
                          variant="text"
                          color="inherit"
                          onClick={() => onClearFilter()}
                        >
                          {clearButtonText}
                        </Button>
                        <Button
                          classes={{root: classes.applyButton}}
                          variant="contained"
                          color="secondary"
                          onClick={() => onApplyFilter()}
                        >
                          {saveButtonText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}

        <Button
          size="small"
          variant={value.length > 0 ? 'contained' : 'outlined'}
          color={value.length > 0 ? 'secondary' : 'inherit'}
          onClick={handleClickMenu}
        >
          {`${filterName} ${value.length > 0 ? '(' + value.length + ')' : ''}`}
          {value.length > 0 && (
            <Icon onClick={(e) => onClearFilter(e)}>close_rounded</Icon>
          )}
        </Button>
      </>
    );
  }
);

export default MultiselectFilter;
