import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    headingText: {
      marginBottom: '18px !important',
    },

    label: {
      fontSize: '15px !important',
      fontFamily: 'lato !important',
      fontWeight: 'normal !important',
      lineHeight: 'normal !important',
      letterSpacing: '0.25px !important',
      color: '#8b98a4 !important',
      marginBottom: '10px !important',
      marginTop: '10px !important',
    },

    formLabelRoot: {
      fontSize: '0.9rem !important',
      opacity: '0.7 !important',
      fontWeight: 'normal !important',
      color: 'inherit !important',
      textDecoration: 'underline !important',
    },

    checkboxRadioDisabled: {
      color: `${theme.palette.secondary.main} !important`,
    },
    checkboxRadioLabelDisabled: {
      color: `${theme.palette.text.primary} !important`,
    },
  };
});
