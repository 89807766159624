import {Dialog, DialogContent, Grid, Typography} from '@mui/material';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {addVendorRequest, getSignedUrlRequest, uploadToS3Request,} from '../../VendorApiActions';
import {VendorForm} from '../index';
import './AddVendor.css';

/**
 * function to render add vendor
 * @param setRefreshList
 * @param {*} addVendor: function to handle invite vendor
 * @param {*} handleCloseDialog: function to handle dialog close
 * @param {*} openDialog: boolean to open form dialog
 * @param getSignedUrl
 * @param uploadToS3
 * @returns
 */
const AddVendor = ({
                     openDialog,
                     handleCloseDialog,
                     setRefreshList,
                     addVendor,
                     getSignedUrl,
                     uploadToS3,
                   }) => {
  const handleAddVendor = async (vendor) => {
    const requestData = {...vendor, tagline: vendor.title};

    delete requestData['title'];
    delete requestData['coverPhoto'];
    delete requestData['icon'];
    delete requestData['coverImage'];
    delete requestData['profileImage'];

    try {
      if (vendor.coverImage.length > 0 && vendor.profileImage.length === 0) {
        const coverImageFileData = {
          fileName: vendor.coverImage[0].name,
          fileType: `.${vendor.coverImage[0].name.split('.').pop()}`,
        };

        const resCoverImageSignUrl = await getSignedUrl(coverImageFileData);
        await uploadToS3(resCoverImageSignUrl.urls, vendor.coverImage[0]);
        requestData.coverImgUrl = resCoverImageSignUrl.path;
      }

      if (vendor.profileImage.length > 0 && vendor.coverImage.length === 0) {
        const profileImageFileData = {
          fileName: vendor.profileImage[0].name,
          fileType: `.${vendor.profileImage[0].name.split('.').pop()}`,
        };

        const resProfileImageSignUrl = await getSignedUrl(profileImageFileData);
        await uploadToS3(resProfileImageSignUrl.urls, vendor.profileImage[0]);
        requestData.profileImgUrl = resProfileImageSignUrl.path;
      }

      if (vendor.coverImage.length > 0 && vendor.profileImage.length > 0) {
        const coverImageFileData = {
          fileName: vendor.coverImage[0].name,
          fileType: `.${vendor.coverImage[0].name.split('.').pop()}`,
        };

        const resCoverImageSignUrl = await getSignedUrl(coverImageFileData);
        await uploadToS3(resCoverImageSignUrl.urls, vendor.coverImage[0]);
        requestData.coverImgUrl = resCoverImageSignUrl.path;

        const profileImageFileData = {
          fileName: vendor.profileImage[0].name,
          fileType: `.${vendor.profileImage[0].name.split('.').pop()}`,
        };

        const resProfileImageSignUrl = await getSignedUrl(profileImageFileData);
        await uploadToS3(resProfileImageSignUrl.urls, vendor.profileImage[0]);
        requestData.profileImgUrl = resProfileImageSignUrl.path;
      }

      await addVendor(requestData);
      handleCloseDialog();
      setRefreshList(true);
    } catch (error) {}
  };

  /**
   * function to cancel invite vendor
   */
  const onCancelInviteVendor = () => {
    handleCloseDialog();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="vendor-dialog-form-content">
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" className="vendor-dialog-title">
              {I18n.t('vendor.add_vendor.form_title_add_vendor_label')}
            </Typography>
            <VendorForm
              onCancelInviteVendor={onCancelInviteVendor}
              submitFormData={(vendor) => handleAddVendor(vendor)}
              formData={null}
              isUpdate={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },

    addVendor: async (data) => {
      try {
        return await dispatch(addVendorRequest(data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
