import {Avatar, Box, Icon, Typography} from '@mui/material';
import React from 'react';
import {useStyles} from './styles';

/**
 * function to render comment item
 * @param {*} imageUrl: image url
 * @returns
 */
const CommentItem = ({comment}) => {
  const classes = useStyles();

  return (
    <Box mt={3.5}>
      <Box display="flex">
        <Box mr={1.2}>
          <Avatar src={comment?.profileImageURL}/>
        </Box>
        <Box>
          <Box>
            <Typography>
              <span className={classes.nameText}>{comment?.name}</span>{' '}
              <span className={classes.commentText}>{comment?.text}</span>
            </Typography>
            <Box display="flex" mt={1}>
              <Icon fontSize="small">favorite_border</Icon>
              <span className={classes.countText}>{comment?.likeCount}</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentItem;
