import {DesktopDatePicker, DesktopTimePicker, LocalizationProvider,} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {CustomErrorComponent} from 'custom-error';
import {convertToRaw} from 'draft-js';
import {ContentState, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FileViewer from 'react-file-viewer';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams} from 'react-router-dom';
import {ReactComponent as File} from '../../../../assets/File.svg';
import {FileUpload, MobileNumber} from '../../../../utils/components';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorRequest, getVendorsForOptionsRequest,} from '../../../Vendors/VendorApiActions';
import {
  addEventRequest,
  getEventRequest,
  getSignedUrlsRequest,
  updateEventRequest,
  uploadToS3Request,
} from '../../EventsApiActions';
import {useStyles} from './styles';

/**
 * function to render event form
 * @param {*} history: history object
 * @param getVendorsForOptions
 * @param addEvent
 * @param getEvent
 * @param updateEvent
 * @param getVendor
 * @param getSignedUrls
 * @param uploadToS3
 * @returns
 */
const EventForm = ({
                     history,
                     getVendorsForOptions,
                     addEvent,
                     getEvent,
                     updateEvent,
                     getVendor,
                     getSignedUrls,
                     uploadToS3,
                   }) => {
  const classes = useStyles();
  const {eventId, vendorId} = useParams();

  const [eventInfo, setEventInfo] = useState({
    name: '',
    date: null,
    startTime: null,
    endTime: null,
    personName: '',
    personPhone: '',
    vendor: null,
    tickets: '',
    cost: '',
    address: null,
    city: '',
    about: '',
    terms: '',
    media: [],
  });
  const [files, setFiles] = useState([]);

  const [dateError, setDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [personPhoneError, setPersonPhoneError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [aboutError, setAboutError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [mediaError, setMediaError] = useState('');

  const [openFileViewer, setOpenFileViewer] = useState(false);

  const [selectedFile, setSelectedFile] = useState(undefined);
  const [vendorList, setVendorList] = useState(null);

  /**
   * function to get vendor option list
   */
  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    getVendorsForOptionList();

  }, []);

  const getVendorDetails = async (id) => {
    const {data} = await getVendor(id);
    setEventInfo({
      ...eventInfo,
      vendor: data,
    });
  };

  useEffect(() => {
    if (vendorId && eventId === undefined) {
      getVendorDetails(vendorId).then(r => {});
    }

  }, [vendorId, eventId]);

  const getEventDetails = async (id) => {
    const res = await getEvent(id);

    let data = {
      name: res.data?.name ? res.data?.name : '',
      date: res.data?.date ? res.data?.date : null,
      startTime: res.data?.startTime ? res.data?.startTime : null,
      endTime: res.data?.endTime ? res.data?.endTime : null,
      personName: res.data?.personName ? res.data?.personName : '',
      personPhone: res.data?.personPhone ? res.data?.personPhone : '',
      vendor: res.data?.Vendor ? res.data?.Vendor : null,
      tickets: res.data?.tickets ? res.data.tickets.toString() : '',
      soldTicket: res.data?.soldTicket,
      cost: res.data.cost ? res.data.cost.toString() : '',
      address: res.data?.address
        ? {
          label: res.data?.address,
          value: {},
        }
        : null,
      city: res?.data?.city ? res?.data?.city : '',
      about: res.data?.description
        ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(res.data?.description).contentBlocks,
            htmlToDraft(res.data?.description).entityMap
          )
        )
        : '',
      terms: res.data?.terms
        ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(res.data?.terms).contentBlocks,
            htmlToDraft(res.data?.terms).entityMap
          )
        )
        : '',
      uploadedFiles: [],
      media: [],
    };

    res.data?.media.forEach((mediaItem) => {
      const fileExtension = mediaItem.type.split('.').pop();
      data.uploadedFiles.push({
        type: fileExtension,
        url: mediaItem.readSignedURL,
        path: mediaItem.path,
        fileName: mediaItem.fileName,
        fileType: mediaItem.type,
      });
    });

    setEventInfo(data);
  };

  useEffect(() => {
    if (eventId) {
      getEventDetails(eventId).then(r => {});
    }

  }, [eventId]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      setMediaError('');
    }
  }, [files]);

  useEffect(() => {
    if (eventInfo.address) {
      setAddressError('');
    }
  }, [eventInfo.address]);

  useEffect(() => {
    if (eventInfo.about) {
      setAboutError('');
    }
  }, [eventInfo.about]);

  useEffect(() => {
    if (eventInfo.terms) {
      setTermsError('');
    }
  }, [eventInfo.terms]);

  useEffect(() => {
    if (eventInfo.startTime && eventInfo.endTime && eventInfo.date) {
      if (
        UtilHelper.isValidStartTimeEndTime(
          eventInfo.startTime,
          eventInfo.endTime,
          eventInfo.date
        ) &&
        endTimeError === ''
      ) {
        setStartTimeError('Please select valid time interval');
      } else {
        setStartTimeError('');
      }
    }
  }, [eventInfo.startTime, eventInfo.endTime, eventInfo.date, endTimeError]);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, eventInfo);
    data[event.target.name] = event.target.value;
    setEventInfo(data);
  };

  /**
   * function to handle date time input change
   * @param {*} name: name
   * @param {*} value: value
   */
  const handleDateTimeInputChange = (name, value) => {
    const data = Object.assign({}, eventInfo);
    data[name] = value;
    setEventInfo(data);
  };

  /**
   * function to handle autocomplete input change
   * @param {*} elementName: element name
   * @param {*} selectedvalue: selected value
   */
  const handleChangeAutocomplete = (elementName, selectedvalue) => {
    const data = Object.assign({}, eventInfo);
    data[elementName] = selectedvalue;
    setEventInfo(data);
  };

  /**
   * function to handle mobile number change
   * @param {*} phone: phone value
   */
  const updateMobileNumber = (phone) => {
    const data = Object.assign({}, eventInfo);
    data['personPhone'] = phone;
    setEventInfo(data);

    if (!UtilHelper.validatePhone(phone)) {
      setPersonPhoneError(I18n.t('error_messages.mobile_number_invalid_error'));
    } else {
      setPersonPhoneError('');
    }
  };

  /**
   * function to handle on file change
   * @param {*} selectedFiles
   * @param {*} updateError
   */
  const onChangeFile = (selectedFiles, updateError) => {
    if (selectedFiles.length > 0) {

    }
    setFiles(selectedFiles);
  };

  /**
   * function to handle file remove
   * @param {*} index
   */
  const onRemoveFile = (index) => {
    let fileList = Object.assign([], files);
    fileList.splice(index, 1);
    setFiles(fileList);
  };

  /**
   * function to handle file preview
   * @param {*} file: file
   */
  const onShowFilePreview = (file) => {
    setSelectedFile(file);
    setOpenFileViewer(true);
  };

  /**
   * function to hide file preview
   */
  const onHideFilePreview = () => {
    setSelectedFile(null);
    setOpenFileViewer(false);
  };

  /**
   * function to handle editor state change
   * @param {*} name: name
   * @param {*} value: value
   */
  const onEditorStateChange = (name, value) => {
    const data = Object.assign({}, eventInfo);
    data[name] = value;
    setEventInfo(data);
  };

  const editorContentAbout = eventInfo?.about && convertToRaw(eventInfo?.about?.getCurrentContent());
  const editorContentTerms = eventInfo?.terms && convertToRaw(eventInfo?.terms?.getCurrentContent());


  /**
   * function to handle validation
   */
  const handleValidation = () => {
    if (files.length === 0 && !eventId) {
      setMediaError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.personPhone.length === 0) {
      setPersonPhoneError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.date === null) {
      setDateError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.startTime === null) {
      setStartTimeError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.endTime === null) {
      setEndTimeError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.address === null) {
      setAddressError(I18n.t('error_messages.field_required'));
    }

    if (eventInfo.about.length === 0) {
      setAboutError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.about) {
      const editorContent = convertToRaw(eventInfo.about.getCurrentContent());
      const about = editorContent.blocks[0].text;
      if (about.length === 0) {
        setAboutError(I18n.t('error_messages.field_required'));
      } else if (about.trim().length === 0) {
        setAboutError(I18n.t('error_messages.blank_spaces_not_allowed'));
      }
    }

    if (eventInfo.terms.length === 0) {
      setTermsError(I18n.t('error_messages.field_required'));
    }
    if (eventInfo.terms) {
      const editorContent = convertToRaw(eventInfo.terms.getCurrentContent());
      const text = editorContent.blocks[0].text;
      if (text.length === 0) {
        setTermsError(I18n.t('error_messages.field_required'));
      } else if (text.trim().length === 0) {
        setTermsError(I18n.t('error_messages.blank_spaces_not_allowed'));
      }
    }
  };

  /**
   * function to handle form submit
   */
  const onSubmit = async () => {
    handleValidation();

    if (
      !mediaError &&
      !personPhoneError &&
      !dateError &&
      eventInfo.date !== null &&
      !startTimeError &&
      eventInfo.startTime !== null &&
      !endTimeError &&
      eventInfo.endTime !== null &&
      !aboutError &&
      !termsError &&
      eventInfo.address !== null &&
      !addressError && (files.length > 0 || eventInfo?.uploadedFiles?.length > 0) && (editorContentAbout.blocks[0].text.trim().length !== 0) && (editorContentTerms.blocks[0].text.trim().length !== 0)
    ) {
      const data = {
        ...eventInfo,
        date: moment(eventInfo?.date).format('YYYY-MM-DD'),
        startTime:
          typeof eventInfo?.startTime === 'string'
            ? eventInfo?.startTime
            : eventInfo?.startTime?.toISOString(),
        endTime:
          typeof eventInfo?.endTime === 'string'
            ? eventInfo?.endTime
            : eventInfo?.endTime?.toISOString(),
        description: eventInfo.about
          ? draftToHtml(convertToRaw(eventInfo.about.getCurrentContent()))
          : '',
        terms: eventInfo.terms
          ? draftToHtml(convertToRaw(eventInfo.terms.getCurrentContent()))
          : '',
        VendorId: eventInfo.vendor ? eventInfo.vendor.id : '',
        address: eventInfo?.address ? eventInfo?.address?.label : '',
      };

      delete data['vendor'];
      delete data['about'];

      if (files.length > 0) {
        const filesData = [];

        files.forEach((file) => {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop();
          const fileData = {
            fileName: fileName,
            fileType: '.' + fileExtension,
          };

          filesData.push(fileData);
        });

        const resSignedUrls = await getSignedUrls({
          media: filesData,
        });

        for (let i = 0; i < files.length; i++) {
          await uploadToS3(resSignedUrls[i].urls, files[i]);
        }

        data.media = filesData.map((fileData, index) => {
          return {
            ...fileData,
            path: resSignedUrls[index].path,
          };
        });

        if (eventId) {
          if (data.uploadedFiles.length > 0) {
            data.uploadedFiles.forEach((singleFile) => {
              data.media.push({
                path: singleFile.path,
                fileName: singleFile.fileName,
                fileType: singleFile.fileType,
              });
            });
          }

          delete data['uploadedFiles'];
          delete data['soldTicket'];

          await updateEvent(eventId, UtilHelper.trimObject(data));
          history.goBack();
        } else {
          await addEvent(UtilHelper.trimObject(data));
          history.goBack();
        }
      } else {
        if (eventId) {
          if (data.uploadedFiles.length > 0) {
            data.uploadedFiles.forEach((singleFile) => {
              data.media.push({
                path: singleFile.path,
                fileName: singleFile.fileName,
                fileType: singleFile.fileType,
              });
            });
          }

          delete data['uploadedFiles'];
          delete data['soldTicket'];

          await updateEvent(eventId, UtilHelper.trimObject(data));
          history.goBack();
        } else {
          await addEvent(UtilHelper.trimObject(data));
          history.goBack();
        }
      }
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {
    handleValidation();
  };

  return (
    <>
      <Paper component={Box} p={3}>
        <Box marginBottom="1rem">
          <Typography variant="h6">
            {eventId
              ? I18n.t('event.event_form.form_title_update_event_label')
              : I18n.t('event.event_form.form_title_add_event_label')}
          </Typography>
        </Box>

        <Box>
          <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextValidator
                  variant="standard"
                  fullWidth
                  required
                  size="small"
                  label={I18n.t('event.event_form.form_field_name_label')}
                  onChange={handleChangeInput}
                  helperText={`${eventInfo.name.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`}
                  name="name"
                  value={eventInfo.name}
                  validators={[
                    'required',
                    ONLY_BLANK_SPACES,
                    `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`,
                    `matchRegexp:${Constants.REGEX.NAME}`,
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.blank_spaces_not_allowed'),
                    I18n.t(
                      'error_messages.maximum_100_allowed_characters_for_text'
                    ),
                    I18n.t('error_messages.invalid_string'),
                  ]}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Autocomplete
                  disabled={!!vendorId}
                  size="small"
                  name="vendor"
                  required
                  getOptionLabel={(option) => (option ? option?.name : '')}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  options={vendorList ? vendorList : []}
                  onChange={(e, value) =>
                    handleChangeAutocomplete('vendor', value)
                  }
                  value={eventInfo.vendor}
                  renderInput={(params) => {
                    return (
                      <TextValidator
                        required
                        variant="standard"
                        name="vendor"
                        {...params}
                        fullWidth
                        label={I18n.t(
                          'event.event_form.form_field_vendor_label'
                        )}
                        value={eventInfo.vendor}
                        validators={['required']}
                        errorMessages={[
                          I18n.t('error_messages.field_required'),
                        ]}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextValidator
                  disabled={eventInfo?.soldTicket > 0}
                  variant="standard"
                  fullWidth
                  required
                  size="small"
                  label={I18n.t(
                    'event.event_form.form_field_total_tickets_label'
                  )}
                  onChange={handleChangeInput}
                  name="tickets"
                  value={eventInfo.tickets}
                  validators={[
                    'required',
                    ONLY_BLANK_SPACES,
                    'matchRegexp:^[0-9]+$',
                    'minNumber:1',
                    `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_18}`,
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.blank_spaces_not_allowed'),
                    I18n.t('error_messages.only_digit_allow'),
                    I18n.t('error_messages.min_number_allow'),
                    I18n.t(
                      'error_messages.maximum_18_allowed_characters_for_text'
                    ),
                  ]}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextValidator
                  disabled={eventInfo?.soldTicket > 0}
                  variant="standard"
                  fullWidth
                  required
                  size="small"
                  label={I18n.t(
                    'event.event_form.form_field_cost_per_ticket_label'
                  )}
                  onChange={handleChangeInput}
                  name="cost"
                  value={eventInfo.cost}
                  validators={[
                    'required',
                    ONLY_BLANK_SPACES,
                    'matchRegexp:^([0-9]*[.])?[0-9]+$',
                    'maxFloat:' + Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                    'minNumber:1',
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.blank_spaces_not_allowed'),
                    I18n.t('error_messages.int_float_only'),
                    I18n.t('error_messages.maximum_allowed_float', {
                      value: Constants.VALIDATIONS.MAXIMUM_FLOAT_VALUE,
                    }),
                    'Please enter value more than 0',
                  ]}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disableCloseOnSelect
                    label={I18n.t('event.event_form.form_field_date_label')}
                    minDate={new Date()}
                    inputFormat="dd/MM/yyyy"
                    value={eventInfo.date}
                    onChange={(date) => handleDateTimeInputChange('date', date)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        required
                        {...params}
                        helperText={dateError}
                        FormHelperTextProps={{
                          error: true,
                        }}
                      />
                    )}
                    onError={(reason, value) => {
                      if (reason === 'invalidDate') {
                        setDateError(I18n.t('error_messages.invalid_date'));
                      }

                      if (reason === 'minDate') {
                        setDateError(I18n.t('error_messages.past_date'));
                      }

                      if (reason === 'maxDate') {
                        setDateError(`Can not be out of selection range`);
                      }

                      if (reason === null) {
                        setDateError('');
                      }
                    }}
                    onClose={() => {
                      setDateError('');
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    disableOpenPicker
                    disableCloseOnSelect
                    label={I18n.t(
                      'event.event_form.form_field_start_time_label'
                    )}
                    value={eventInfo.startTime}
                    onChange={(date) => {
                      handleDateTimeInputChange('startTime', date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        required
                        {...params}
                        helperText={startTimeError}
                        FormHelperTextProps={{
                          error: true,
                        }}
                      />
                    )}
                    onError={(reason, value) => {
                      if (reason === 'invalidDate') {
                        setStartTimeError(
                          I18n.t('error_messages.invalid_time')
                        );
                      } else {
                        setStartTimeError('');
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopTimePicker
                    disableOpenPicker
                    label={I18n.t('event.event_form.form_field_end_time_label')}
                    value={eventInfo.endTime}
                    onChange={(date) =>
                      handleDateTimeInputChange('endTime', date)
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        required
                        {...params}
                        helperText={endTimeError}
                        FormHelperTextProps={{
                          error: true,
                        }}
                      />
                    )}
                    onError={(reason, value) => {
                      if (reason === 'invalidDate') {
                        setEndTimeError(I18n.t('error_messages.invalid_time'));
                      } else {
                        setEndTimeError('');
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextValidator
                  variant="standard"
                  fullWidth
                  required
                  size="small"
                  label={I18n.t(
                    'event.event_form.form_field_person_name_label'
                  )}
                  onChange={handleChangeInput}
                  helperText={`${eventInfo.personName.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                  name="personName"
                  value={eventInfo.personName}
                  validators={[
                    'required',
                    ONLY_BLANK_SPACES,
                    `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`,
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.blank_spaces_not_allowed'),
                    I18n.t(
                      'error_messages.maximum_allowed_characters_for_text_field'
                    ),
                  ]}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <MobileNumber
                  customLabel={`${I18n.t(
                    'event.event_form.form_field_person_phone_label'
                  )} *`}
                  phone={eventInfo.personPhone}
                  updateMobileNumber={updateMobileNumber}
                />

                {personPhoneError && (
                  <FormHelperText
                    error
                    style={{marginTop: '7px', marginLeft: '14px'}}
                  >
                    {personPhoneError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}/>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography classes={{root: classes.label}}>
                  {`${I18n.t('event.event_form.form_field_address_label')} *`}
                </Typography>

                {window?.google && (
                  <Box>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        isClearable: true,
                        placeholder: 'Search Address',
                        value: eventInfo?.address,
                        onChange: (address) => {
                          const data = {...eventInfo, address: address};
                          setEventInfo(data);
                        },
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: 'inherit',
                            border: 'none',
                            borderBottom: '1px solid #334e68',
                            borderRadius: '0px',
                            boxShadow: 'none',
                            '&:hover': {
                              border: 'none',
                              borderBottom: '1px solid #334e68',
                            },
                          }),
                          input: (provided) => ({
                            ...provided,
                            padding: '0px',
                            color: 'inherit',
                            outline: 'none',
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: '0px',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#33393E !important',
                            fontWeight: '500',
                          }),
                          option: (provided) => ({
                            ...provided,
                            backgroundColor: 'inherit !important',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: 'inherit',
                            fontWeight: '500',
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            backgroundColor: 'inherit',
                          }),
                        },
                      }}
                    />
                  </Box>
                )}

                {addressError && (
                  <FormHelperText
                    error
                    style={{marginTop: '7px', marginLeft: '14px'}}
                  >
                    {addressError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextValidator
                  variant="standard"
                  fullWidth
                  required
                  size="small"
                  label={I18n.t('event.event_form.form_field_city_label')}
                  onChange={handleChangeInput}
                  helperText={`${eventInfo.city.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`}
                  name="city"
                  value={eventInfo.city}
                  validators={[
                    'required',
                    ONLY_BLANK_SPACES,
                    `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD}`,
                  ]}
                  errorMessages={[
                    I18n.t('error_messages.field_required'),
                    I18n.t('error_messages.blank_spaces_not_allowed'),
                    I18n.t(
                      'error_messages.maximum_allowed_characters_for_text_field'
                    ),
                  ]}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography classes={{root: classes.label}}>
                  {`${I18n.t(
                    'event.event_form.form_field_about_event_label'
                  )} *`}
                </Typography>

                <Editor
                  editorState={eventInfo.about}
                  onEditorStateChange={(value) =>
                    onEditorStateChange('about', value)
                  }
                  wrapperStyle={{
                    borderBottom: '1px solid #334e68',
                  }}
                  toolbarStyle={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0px',
                    margin: '0px',
                    marginLeft: '-3px',
                  }}
                  editorStyle={{
                    heigth: '300px !important',
                  }}
                  toolbar={{
                    options: [
                      'inline',
                      'textAlign',
                      'link',
                      'emoji',
                      'remove',
                      'history',
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic'],
                    },
                  }}
                />

                {aboutError && (
                  <FormHelperText
                    error
                    style={{marginTop: '7px', marginLeft: '14px'}}
                  >
                    {aboutError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography classes={{root: classes.label}}>
                  {`${I18n.t('event.event_form.form_field_terms_label')} *`}
                </Typography>
                <Editor
                  editorState={eventInfo.terms}
                  onEditorStateChange={(value) =>
                    onEditorStateChange('terms', value)
                  }
                  wrapperStyle={{
                    borderBottom: '1px solid #334e68',
                  }}
                  toolbarStyle={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0px',
                    margin: '0px',
                    marginLeft: '-3px',
                  }}
                  editorStyle={{
                    heigth: '300px !important',
                  }}
                  toolbar={{
                    options: [
                      'inline',
                      'textAlign',
                      'link',
                      'emoji',
                      'remove',
                      'history',
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic'],
                    },
                  }}
                />

                {termsError && (
                  <FormHelperText
                    error
                    style={{marginTop: '7px', marginLeft: '14px'}}
                  >
                    {termsError}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FileUpload
                  files={files}
                  acceptedFiles={[Constants.REACT_APP_PROFILE_IMAGE_SUPPORTED]}
                  filesLimit={Constants.REACT_APP_PRODUCT_UPLOAD_FILE_LIMIT_5}
                  showPreviewsInDropzone={true}
                  fieldLabel={`${I18n.t(
                    'event.event_form.form_field_upload_label'
                  )} *`}
                  dropzoneLabel={I18n.t(
                    'event.event_form.form_field_dropzon_label'
                  )}
                  updateFileList={(selectedFiles, updateError) =>
                    onChangeFile(selectedFiles, updateError)
                  }
                  removeFileFromSelection={(index) => onRemoveFile(index)}
                />

                {mediaError && (
                  <FormHelperText
                    error
                    style={{marginTop: '7px', marginLeft: '14px'}}
                  >
                    {mediaError}
                  </FormHelperText>
                )}

                {eventInfo.uploadedFiles &&
                eventInfo.uploadedFiles.length > 0 ? (
                  <>
                    <Typography
                      variant="h6"
                      className="dropzone-label margin-top-10"
                    >
                      {I18n.t(
                        'product.add_product.form_field_uploaded_media_label'
                      )}
                    </Typography>

                    {eventInfo.uploadedFiles.map((file, index) => (
                      <Grid container spacing={3} key={index}>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                          <div className="selected-file-container">
                            <ListItem className={'file-list-item'}>
                              <File className={'selected-file'}/>
                              <Typography
                                variant="h6"
                                className="selected-file-name"
                              >
                                {file.fileName}
                              </Typography>
                            </ListItem>
                          </div>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Button
                            size="large"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={() => onShowFilePreview(file)}
                          >
                            Preview
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    color="inherit"
                    variant="text"
                    onClick={() => history.goBack()}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                  <Box ml={1}>
                    <Button type="submit" color="secondary" variant="contained" className="event-save-button">
                      {I18n.t('global.button_labels.save')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Paper>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openFileViewer && selectedFile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
        >
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h6"
              >
                Preview
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="close-preview-btn text-transform-capitalize"
                  onClick={() => onHideFilePreview()}
                >
                  <Icon>close_rounded</Icon>
                </IconButton>
              </Typography>
              {selectedFile && (
                <FileViewer
                  fileType={selectedFile.type}
                  filePath={selectedFile.url}
                  errorComponent={CustomErrorComponent}
                  onError={(error) => {
                    console.log('file-error');
                  }}
                />
              )}
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}/>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getVendor: async (id) => {
      try {
        return await dispatch(getVendorRequest(id));
      } catch (error) {
        throw error;
      }
    },

    addEvent: async (data) => {
      try {
        return await dispatch(addEventRequest(data));
      } catch (error) {
        throw error;
      }
    },

    getEvent: async (id) => {
      try {
        return await dispatch(getEventRequest(id));
      } catch (error) {
        throw error;
      }
    },

    updateEvent: async (id, data) => {
      try {
        return await dispatch(updateEventRequest(id, data));
      } catch (error) {
        throw error;
      }
    },

    getSignedUrls: async (data) => {
      try {
        return await dispatch(getSignedUrlsRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
