import {CHANGE_TAB} from './VendorActions';

const initialState = {
  tabValue: '1',
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TAB:
      return {
        ...state,
        tabValue: action.tabValue,
      };

    default:
      return state;
  }
};

export default VendorReducer;
