import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {useParams} from 'react-router-dom';
import {HtmlTooltip} from '../../../../utils/components';
import Constants, {ONLY_BLANK_SPACES} from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {
  addSurveyQuestionRequest,
  getSignedUrlRequest,
  updateSurveyQuestionRequest,
  uploadToS3Request,
} from '../../SurveyApiActions';
import {useStyles} from './styles';

/**
 * function to render question form
 * @param {*} handleCloseQuestionForm: function to handle question form close
 * @param {*} selectedQuestion: selected question
 * @param getSignedUrl
 * @param uploadToS3
 * @param addSurveyQuestion
 * @param updateSurveyQuestion
 * @param setRefreshList
 * @returns
 */
const QuestionForm = ({
                        handleCloseQuestionForm,
                        selectedQuestion,
                        getSignedUrl,
                        uploadToS3,
                        addSurveyQuestion,
                        updateSurveyQuestion,
                        setRefreshList,
                      }) => {
  const classes = useStyles();
  const {surveyId} = useParams();
  const [question, setQuestion] = useState({
    title: '',
    type: 'single',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    imageUrl: '',
    SurveyId: surveyId,
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );

    return () => {
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
    };
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      setQuestion({
        title: selectedQuestion?.title,
        type: selectedQuestion?.type,
        option1: selectedQuestion?.option1,
        option2: selectedQuestion?.option2,
        option3: selectedQuestion?.option3,
        option4: selectedQuestion?.option4,
        imageUrl: selectedQuestion?.imageUrl,
        imagePath: selectedQuestion?.imagePath,
        SurveyId: surveyId,
      });
    }

  }, [selectedQuestion]);

  /**
   * function to handle input change
   * @param {*} event: event object
   * @param {*} value: value
   */
  const handleChangeInput = (event, value) => {
    const data = Object.assign({}, question);
    data[event.target.name] = event.target.value;
    setQuestion(data);
  };

  /**
   * function to handle radio input change
   * @param {*} name: name
   * @param {*} event: event
   */
  const handleRadioInput = (name, event) => {
    const data = Object.assign({}, question);
    data[name] = event.target.value;
    setQuestion(data);
  };

  /**
   * function to handle form submit
   */
  const onSubmit = async () => {
    const data = {
      ...question,
    };

    if (images.length > 0) {
      const imageFileData = {
        fileName: images[0].file.name,
        fileType: `.${images[0].file.name.split('.').pop()}`,
      };
      const resSignedUrl = await getSignedUrl(imageFileData);
      await uploadToS3(resSignedUrl.urls, images[0].file);
      data.imageUrl = resSignedUrl.path;

      if (selectedQuestion) {
        delete data['imagePath'];

        await updateSurveyQuestion(
          selectedQuestion?.id,
          UtilHelper.trimObject(data)
        );
      } else {
        await addSurveyQuestion(UtilHelper.trimObject(data));
      }

      handleCloseQuestionForm();
      setRefreshList(true);
    } else {
      if (selectedQuestion) {
        const requestData = {
          ...data,
          imageUrl: question.imagePath,
        };

        delete requestData['imagePath'];

        await updateSurveyQuestion(
          selectedQuestion?.id,
          UtilHelper.trimObject(requestData)
        );
      } else {
        await addSurveyQuestion(UtilHelper.trimObject(data));
      }

      handleCloseQuestionForm();
      setRefreshList(true);
    }
  };

  /**
   * function to handle form error
   */
  const onFormError = () => {};

  return (
    <Paper component={Box} p={3}>
      <Box>
        <ValidatorForm onSubmit={onSubmit} noValidate onError={onFormError}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextValidator
                variant="standard"
                fullWidth
                required
                size="small"
                label={I18n.t('survey.question_form.form_field_title_label')}
                onChange={handleChangeInput}
                helperText={`${question.title.length}/${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300}`}
                name="title"
                value={question.title}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_300}`,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_300_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl>
                <Box display="flex" alignItems="center">
                  <Box>
                    <FormLabel classes={{root: classes.formLabelRoot}}>
                      Type of Question
                    </FormLabel>
                  </Box>
                  <Box ml={0.5}>
                    <HtmlTooltip
                      arrow
                      placement="right"
                      interactive
                      title={
                        <Box m={1} width="100%">
                          <Box mb={1}>
                            <Typography variant="caption">
                              <span className={classes.tooltipMain}>
                                Single Select:
                              </span>{' '}
                              User will be able to choose only one option while
                              answering the question.
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">
                              <span className={classes.tooltipMain}>
                                Multi Select:
                              </span>{' '}
                              User will be able to choose multiple options while
                              answering the question.
                            </Typography>
                          </Box>
                        </Box>
                      }
                    >
                      <Icon fontSize="small">info</Icon>
                    </HtmlTooltip>
                  </Box>
                </Box>
                <RadioGroup
                  row
                  value={question.type}
                  onChange={(event) => handleRadioInput('type', event)}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio color="secondary"/>}
                    label={I18n.t(
                      'survey.question_form.form_single_select_label'
                    )}
                  />
                  <FormControlLabel
                    value="multi"
                    control={<Radio color="secondary"/>}
                    label={I18n.t(
                      'survey.question_form.form_multi_select_label'
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant="standard"
                fullWidth
                required
                size="small"
                label={I18n.t('survey.question_form.form_field_option_1_label')}
                onChange={handleChangeInput}
                name="option1"
                value={question.option1}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant="standard"
                fullWidth
                required
                size="small"
                label={I18n.t('survey.question_form.form_field_option_2_label')}
                onChange={handleChangeInput}
                name="option2"
                value={question.option2}
                validators={[
                  'required',
                  ONLY_BLANK_SPACES,
                  `maxStringLength:${Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100}`,
                ]}
                errorMessages={[
                  I18n.t('error_messages.field_required'),
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant="standard"
                fullWidth
                size="small"
                label={I18n.t('survey.question_form.form_field_option_3_label')}
                onChange={handleChangeInput}
                name="option3"
                value={question.option3}
                validators={[
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                  Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextValidator
                variant="standard"
                fullWidth
                size="small"
                label={I18n.t('survey.question_form.form_field_option_4_label')}
                onChange={handleChangeInput}
                name="option4"
                value={question.option4}
                validators={[
                  ONLY_BLANK_SPACES,
                  'maxStringLength:' +
                  Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_100,
                ]}
                errorMessages={[
                  I18n.t('error_messages.blank_spaces_not_allowed'),
                  I18n.t(
                    'error_messages.maximum_100_allowed_characters_for_text'
                  ),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box>
                {images.length === 0 && !question?.imageUrl && (
                  <Box>
                    <ImageUploading
                      acceptType={['jpg', 'jpeg', 'png']}
                      maxFileSize={5242880}
                      value={images}
                      onChange={(imageFiles) => setImages(imageFiles)}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                          errors,
                        }) => (
                        <>
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={onImageUpload}
                          >
                            {I18n.t(
                              'survey.question_form.form_add_image_label'
                            )}
                          </Button>

                          <Box>
                            {errors && (
                              <Box mt={0.5} ml={0.1}>
                                {errors.acceptType && (
                                  <FormHelperText error>
                                    Please select only jpeg, jpg and png files
                                  </FormHelperText>
                                )}

                                {errors.maxFileSize && (
                                  <FormHelperText error>
                                    Please select max file size of 5MB
                                  </FormHelperText>
                                )}
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </ImageUploading>
                  </Box>
                )}

                {(images.length > 0 || question?.imageUrl) && (
                  <Box>
                    <Box display="inline-block" position="relative" mt={1}>
                      <Box position="absolute" right="-8px" top="-8px">
                        <Icon
                          fontSize="small"
                          classes={{root: classes.removeIcon}}
                          onClick={() => {
                            if (images.length > 0) {
                              setImages([]);
                            } else {
                              setQuestion({
                                ...question,
                                imageUrl: '',
                                imagePath: '',
                              });
                            }
                          }}
                        >
                          cancel
                        </Icon>
                      </Box>
                      <Box>
                        <img
                          src={
                            images.length > 0
                              ? images[0].data_url
                              : question?.imageUrl
                          }
                          alt="file"
                          className={classes.questionImage}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box>
                  <Button
                    color="inherit"
                    variant="text"
                    onClick={handleCloseQuestionForm}
                  >
                    {I18n.t('global.button_labels.cancel')}
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button type="submit" color="secondary" variant="contained">
                    {I18n.t('global.button_labels.save')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Paper>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    addSurveyQuestion: async (data) => {
      try {
        return await dispatch(addSurveyQuestionRequest(data));
      } catch (error) {
        throw error;
      }
    },

    updateSurveyQuestion: async (questionId, data) => {
      try {
        return await dispatch(updateSurveyQuestionRequest(questionId, data));
      } catch (error) {
        throw error;
      }
    },

    getSignedUrl: async (data) => {
      try {
        return await dispatch(getSignedUrlRequest(data));
      } catch (error) {
        throw error;
      }
    },

    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionForm);
