import palette from '../palette';
import themeJSON from '../theme.json';

const MuiTabs = {
  styleOverrides: {
    root: {
      fontFamily: themeJSON.fontFamily,
      borderBottom: '1px solid rgba(98, 125, 152, .25)',
      backgroundColor: palette.primary.main,
    },
  },
};

export default MuiTabs;
