import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {CustomTable, MessageModal} from '../../../../utils/components';
import {DateRangeFilter, MultiselectFilter, NumberRangeFilter,} from '../../../../utils/components/Filter';
import Constants from '../../../../utils/Constants';
import UtilHelper from '../../../../utils/UtilHelper';
import {getVendorsForOptionsRequest} from '../../../Vendors/VendorApiActions';
import {deleteEventRequest, getEventsRequest} from '../../EventsApiActions';
import {useStyles} from './styles';

/**
 * function to render event list list
 * @param {*} history: history object
 * @param {*} getEvents: function to get events
 * @param {*} routeFrom: string value to manage dynamic route
 * @param getVendorsForOptions
 * @param deleteEvent
 * @param vendorInfo
 * @returns
 */
const EventList = ({
                     history,
                     getEvents,
                     routeFrom,
                     getVendorsForOptions,
                     deleteEvent,
                     vendorInfo,
                   }) => {
  const classes = useStyles();
  const {vendorId} = useParams();
  const match = useRouteMatch();
  const menuRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const modalRef = useRef();

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    rowsPerPage: Constants.ROW_PER_PAGE_10,
    searchData: {
      vendors: vendorInfo ? [vendorInfo?.name] : [],
      date: null,
      tickets: null,
      cost: null,
      sold: null,
    },
  });
  const [productList, setProductList] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  /**
   * function to get vendor option list
   */
  const getVendorsForOptionList = async () => {
    const {data} = await getVendorsForOptions();
    setVendorList(data?.rows);
  };
  useEffect(() => {
    if (!vendorId) {
      getVendorsForOptionList().then(r => {});
    }

  }, [vendorId]);

  /**
   * function to get event list
   * @param {*} requestData: request data object
   */
  const getEventList = async (requestData) => {
    try {
      const {data} = await getEvents(requestData);
      setProductList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getEventList(paginationParams);

  }, [paginationParams]);

  useEffect(() => {
    if (refreshList) {
      getEventList(paginationParams);
      setRefreshList(false);
    }

  }, [paginationParams, refreshList]);

  /**
   * function to handle menu click
   * @param {*} event: event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle menu close
   */
  const handleMenuClose = () => {
    menuRef.current = null;
    setAnchorEl(null);
  };

  /**
   * handle edit menu item click
   * @param props
   */
  const handleEditMenuItemClick = (props) => {
    const url = `${match?.url}${routeFrom ? routeFrom : ''}/${props.id}/update`;
    history.push(url);
  };

  /**
   * function to handle delete survey
   */
  const onDeleteEvent = (eventId, flag, name) => {
    const title = `Delete Event`;
    const message = `Are you sure you want to delete ${name}?`;

    modalRef.current.setState({
      showModal: true,
      message: message,
      title: title,
      showOkButton: true,
      showCancelButton: true,
      showNote: false,
      note: '',
      handleOk: async () => {
        const data = {
          flag: flag,
        };
        await deleteEvent(eventId, data);
        setRefreshList(true);
      },
      okText: I18n.t('global.button_labels.yes'),
      cancelText: I18n.t('global.button_labels.no'),
    });
  };

  const columns = [
    {
      title: I18n.t('event.event_table.table_column_name_label'),
      field: 'name',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({id, name}) => {
        return (
          <Box
            component={Link}
            to={`${match?.url}${routeFrom ? routeFrom : ''}/${id}`}
          >
            <Typography variant="body2">
              {UtilHelper.ellipsisRender(name, 30)}
            </Typography>
          </Box>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_address_label'),
      field: 'address',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({address}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(address, 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_vendor_label'),
      field: 'Vendor',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({Vendor}) => {
        return (
          <Typography variant="body2">
            {UtilHelper.ellipsisRender(Vendor?.name ? Vendor?.name : 'NA', 30)}
          </Typography>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_date_label'),
      field: 'date',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({date, startTime, endTime}) => {
        return (
          <>
            <Typography variant="body2">
              {moment(date).format('DD-MM-YYYY')}
            </Typography>
            <Typography variant="body2">
              {moment(startTime).format('hh:mm a')} -{' '}
              {moment(endTime).format('hh:mm a')}
            </Typography>
          </>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_contact_label'),
      field: 'personName',
      cellStyle: {
        width: '20%',
      },
      headerStyle: {
        width: '20%',
      },
      render: ({personName, personPhone}) => {
        return (
          <>
            <Typography variant="body2">{personName}</Typography>
            <Typography variant="body2">{personPhone}</Typography>
          </>
        );
      },
    },
    {
      title: I18n.t('event.event_table.table_column_tickets_label'),
      field: 'tickets',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({tickets}) => {
        return <Typography variant="body2">{tickets}</Typography>;
      },
    },
    {
      title: I18n.t('event.event_table.table_column_cost_label'),
      field: 'cost',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({cost}) => {
        return <Typography variant="body2">{cost}</Typography>;
      },
    },
    {
      title: I18n.t('event.event_table.table_column_sold_label'),
      field: 'soldTicket',
      width: '7%',
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      render: ({soldTicket}) => {
        return <Typography variant="body2">{soldTicket}</Typography>;
      },
    },
    {
      title: I18n.t('survey.survey_table.table_column_action_label'),
      field: '-',
      sorting: false,
      cellStyle: {
        width: '10%',
      },
      headerStyle: {
        width: '10%',
      },
      width: '5%',
      filtering: false,
      render: (props) => {
        return (
          <>
            <Tooltip title="Actions">
              <IconButton
                color="inherit"
                aria-label="option"
                aria-controls={`customized-menu-${props.id}`}
                aria-haspopup="true"
                onClick={(event) => {
                  menuRef.current = props.id;
                  handleMenuClick(event);
                }}
                className="option-menu"
              >
                <Icon fontSize="small">more_vert_rounded</Icon>
              </IconButton>
            </Tooltip>

            <Menu
              id={`customized-menu-${props.id}`}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              keepMounted
              open={menuRef.current === props.id}
              onClose={() => {
                handleMenuClose();
              }}
            >
              {UtilHelper.getDifferenceInHoursBetweenTwoDateTime(
                UtilHelper.combineDateAndTime(
                  new Date(props?.date),
                  new Date(props?.startTime)
                ),
                new Date()
              ) > Constants.EVENT_EDIT_ALLOWED_BEFORE_IN_HOURS && (
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleEditMenuItemClick(props);
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize="small">edit_rounded</Icon>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={I18n.t(
                      'user.super_admin_table.table_action_column_edit_tooltip_title'
                    )}
                  />
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  onDeleteEvent(props.id, true, props.name);
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="small">delete</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={I18n.t('survey.survey_table.table_delete_tooltip')}
                />
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  /**
   * function to handle add event
   */
  const onAddEvent = () => {
    history.push(`${match?.url}${routeFrom ? routeFrom : ''}/add`);
  };

  /**
   * function to handle filter change
   * @param {*} elementName: element name
   * @param {*} value: value
   */
  const handleChangeFilter = (elementName, value) => {
    const data = Object.assign({}, paginationParams);
    data.searchData[elementName] = value;
    setPaginationParams(data);
  };

  /**
   * function to return table title
   * @returns
   */
  const renderTableTitle = () => {
    return (
      <Box>
        <Box
          component={Paper}
          p={1.5}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          color="#fff"
        >
          <Grid
            container
            spacing={1}
            classes={{container: classes.customGridContainer}}
          >
            {!vendorId && (
              <Grid item classes={{item: classes.customGridItem}}>
                <MultiselectFilter
                  arrayList={vendorList ? vendorList : []}
                  filterName={I18n.t(
                    'event.event_table.table_filter_vendor_label'
                  )}
                  title={I18n.t('event.event_table.table_filter_vendor_label')}
                  filterOn="name"
                  saveButtonText={I18n.t('global.button_labels.apply')}
                  value={paginationParams.searchData.vendors}
                  onChangeFilter={(value) =>
                    handleChangeFilter('vendors', value)
                  }
                  clearButtonText={I18n.t('global.button_labels.clear')}
                />
              </Grid>
            )}

            <Grid item classes={{item: classes.customGridItem}}>
              <DateRangeFilter
                filterName={I18n.t('event.event_table.table_filter_date_label')}
                title={I18n.t('event.event_table.table_filter_date_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.date}
                onChangeFilter={(value) => handleChangeFilter('date', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t(
                  'event.event_table.table_filter_ticket_label'
                )}
                title={I18n.t('event.event_table.table_filter_ticket_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.tickets}
                onChangeFilter={(value) => handleChangeFilter('tickets', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t('event.event_table.table_filter_cost_label')}
                title={I18n.t('event.event_table.table_filter_cost_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.cost}
                onChangeFilter={(value) => handleChangeFilter('cost', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>

            <Grid item classes={{item: classes.customGridItem}}>
              <NumberRangeFilter
                filterName={I18n.t('event.event_table.table_filter_sold_label')}
                title={I18n.t('event.event_table.table_filter_sold_label')}
                saveButtonText={I18n.t('global.button_labels.apply')}
                value={paginationParams.searchData.sold}
                onChangeFilter={(value) => handleChangeFilter('sold', value)}
                clearButtonText={I18n.t('global.button_labels.clear')}
                maxValue={100}
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              startIcon={<Icon>add_circle</Icon>}
              color="secondary"
              onClick={() => onAddEvent()}
            >
              {I18n.t('event.event_table.add_event_button_label')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <TableContainer>
        {renderTableTitle()}

        <CustomTable
          columns={columns}
          data={productList?.rows}
          hidePaging={false}
          hideBorderShadow={true}
          rowsPerPageOptions={Constants.PAGE_SIZE_OPTIONS}
          rowsPerPage={paginationParams.rowsPerPage}
          count={productList?.count}
          page={paginationParams.page}
          onPageChange={(page) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = page;
            setPaginationParams(pagination);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            const pagination = Object.assign({}, paginationParams);
            pagination.page = 0;
            pagination.rowsPerPage = rowsPerPage;
            setPaginationParams(pagination);
          }}
        />
      </TableContainer>

      <MessageModal ref={modalRef}/>
    </>
  );
};

/**
 * function to map state to props
 * @param {*} state: state object
 * @returns
 */
const mapStateToProps = (state) => {
  return {};
};

/**
 * function to map dispatch function to prop
 * @param {*} dispatch: function to dispatch action to reducer
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorsForOptions: async () => {
      try {
        return await dispatch(getVendorsForOptionsRequest());
      } catch (error) {
        throw error;
      }
    },

    getEvents: async (data) => {
      try {
        return await dispatch(getEventsRequest(data));
      } catch (error) {
        throw error;
      }
    },

    deleteEvent: async (eventId, data) => {
      try {
        return await dispatch(deleteEventRequest(eventId, data));
      } catch (error) {
        throw error;
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
