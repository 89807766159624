import palette from '../palette';

const MuiTableRow = {
  styleOverrides: {
    root: {
      fontFamily: 'Lato',
      '&.Mui-selected': {
        backgroundColor: palette.background.default,
      },
      '&.MuiTableRow-hover': {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
  },
};

export default MuiTableRow;
