import {I18n} from 'react-redux-i18n';
import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';

/**
 * function to make server request to get whiskey tubes
 * @param {*} data: data for server request
 * @returns
 */
export const getSpiritConstantsRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.SPIRIT}/constant/list`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        const updatedRes = {};

        for (let key in res.data.data) {
          if (Array.isArray(res.data.data[key])) {
            const updatedData = res.data.data[key].map((item, index) => {
              return {
                id: index + 1,
                name: item,
              };
            });
            updatedRes[key] = updatedData;
          } else {
            updatedRes[key] = res.data.data[key];
          }
        }

        return Promise.resolve(updatedRes);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get cuisine for options
 * @returns
 */
export const getCuisineForOptionsRequest = () => {
  return (dispatch) => {
    dispatch(showLoader());
    const url = `${API_ACTIONS.CUISINE}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        const updatedResponse = res.data.data.rows.map((item) => {
          return {
            id: item.id,
            name: item.name
              ? item.name.charAt(0).toUpperCase() + item.name.slice(1)
              : '',
          };
        });
        return Promise.resolve(updatedResponse);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to get whiskey tubes
 * @param {*} data: data for server request
 * @returns
 */
export const getSpiritsRequest = (data) => {
  let offset = data.page * data.rowsPerPage;
  let limit = data.rowsPerPage;
  let searchData = data.searchData;

  return (dispatch) => {
    dispatch(showLoader());
    const url = `${
      API_ACTIONS.SPIRIT
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add spirit
 * @param {*} data: data
 * @returns
 */
export const addSpiritRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.SPIRIT}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Spirit details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to add spirit
 * @param {*} data: data
 * @returns
 */
export const updateSpiritRequest = (spiritId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SPIRIT}/${spiritId}`,
      'patch',
      data
    )
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              `Spirit details saved successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar(
              `Something went wrong, please try again`,
              SNACKBAR_VARIAINT.ERROR
            )
          );
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to activate / deactivate user in DB
 * @param {*} userId: user id for server request
 * @param {*} data: data for server request
 * @returns {Promise}
 */
export const activateDeactivateSpiritRequest = (spiritId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.SPIRIT}/${spiritId}/enabledDisabled`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              `Spirit enabled successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `Spirit disabled successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar(
            `Something went wrong, please try again`,
            SNACKBAR_VARIAINT.ERROR
          )
        );
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to get signed url
 * @param {*} data: data
 * @returns
 */
export const getSignedUrlRequest = (data) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(
      dispatch,
      `${API_ACTIONS.SPIRIT}/files/signedURL`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to upload file to signed url
 * @param {*} url: url
 * @param {*} file: file
 * @returns
 */
export const uploadToS3Request = (url, file) => {
  return async (dispatch) => {
    dispatch(showLoader());
    return await new HttpRequest(dispatch, url, 'put', file)
      .s3Upload()
      .then((res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * -------------------------------------------------------------------------------------------
 * bellows are only for code reusability during api integration, Please do not consider
 * for documentaion and code cleanup
 * -------------------------------------------------------------------------------------------
 */

/**
 * function to make server request to resend invitaion to a user in DB
 * @param {*} userId: user id for server request
 * @returns {Promise}
 */
export const resendInvitationRequest = (userId) => {
  const data = {
    id: userId,
  };

  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.RESEND_INVITATION}`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            I18n.t('user.user_table.user_resend_invitation_success_message'),
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
