import {i18nReducer} from 'react-redux-i18n';
import {combineReducers} from 'redux';
import auth from './modules/Authentication/AuthenticationReducer';
import vendors from './modules/Vendors/VendorReducer';

import showLoader from './utils/components/AppLoader/AppLoaderReducer';

export default combineReducers({
  i18n: i18nReducer,
  showLoader,
  auth,
  vendors,
});
