import palette from '../palette';

const MuiTableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: `${palette.primary.main} !important`,
    },
  },
};

export default MuiTableContainer;
