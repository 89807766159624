import palette from '../palette';

const MuiAvatar = {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.main,
      fontFamily: 'Lato',
    },

    colorDefault: {
      backgroundColor: palette.secondary.main,
    },
  },
};

export default MuiAvatar;
