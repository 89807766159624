import {Icon, InputAdornment, TextField} from '@mui/material';
import React, {useState} from 'react';

const InputFilter = React.memo(
  ({filterName, placeholder, onChangeFilter, minimumSearchCharacter = 2}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
      const inputValue = event.target.value;
      if (inputValue.length >= minimumSearchCharacter) {
        onChangeFilter(inputValue);
      } else {
        onChangeFilter('');
      }

      setInputValue(inputValue);
    };

    return (
      <TextField
        fullWidth
        variant="standard"
        size="small"
        label={filterName}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>search_rounded</Icon>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default InputFilter;
