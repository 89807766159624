import {hideLoader, showLoader, showSnackbar,} from '../../utils/components/AppLoader/AppLoaderActions';
import {API_ACTIONS, SNACKBAR_VARIAINT} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import UtilHelper from "../../utils/UtilHelper";

/**
 * function to make server request to get users from DB
 * @param {*} data: data for server request
 * @returns
 */
export const getUsersRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = Object.assign({}, data.searchData);

    if (searchData.joinedDate) {
      searchData.joinedDate = {
        startDate: UtilHelper.formatDateToISO(searchData.joinedDate.startDate),
        endDate: UtilHelper.formatDateToISO(searchData.joinedDate.endDate),
      }
    }

    const url = `${
      API_ACTIONS.USERS
    }?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`;

    return new HttpRequest(dispatch, url, 'get').send().then(
      (res) => {
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      },
      (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      }
    );
  };
};

/**
 * function to make server request to add user
 * @param {*} data: data for server request
 * @returns
 */
export const addUserRequest = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(dispatch, `${API_ACTIONS.USERS}`, 'post', data)
      .send()
      .then(
        (res) => {
          dispatch(
            showSnackbar(
              'User details saved successfully',
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
          dispatch(hideLoader());
          return Promise.resolve(res.data);
        },
        (error) => {
          dispatch(hideLoader());
          if (error?.response?.data?.error) {
            dispatch(
              showSnackbar(
                error?.response?.data?.error,
                SNACKBAR_VARIAINT.ERROR
              )
            );
          } else {
            dispatch(
              showSnackbar(
                'Something went wrong, please try again',
                SNACKBAR_VARIAINT.ERROR
              )
            );
          }
          return Promise.reject(error);
        }
      );
  };
};

/**
 * function to make server request to update user
 * @param {*} userId: user id
 * @param {*} data: data
 * @returns
 */
export const updateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            'User details saved successfully',
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        if (error?.response?.data?.error) {
          dispatch(
            showSnackbar(
              error?.response?.data?.error,
              SNACKBAR_VARIAINT.ERROR
            )
          );
        } else {
          dispatch(
            showSnackbar(
              'Something went wrong, please try again',
              SNACKBAR_VARIAINT.ERROR
            )
          );
        }
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to activate deactivate user
 * @param {*} userId: user id
 * @param {*} data: data
 * @returns
 */
export const activateDeactivateUserRequest = (userId, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.USERS}/${userId}/activateOrDeactivateAccount`,
      'patch',
      data
    )
      .send()
      .then((res) => {
        if (data.enabled) {
          dispatch(
            showSnackbar(
              `User activated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        } else {
          dispatch(
            showSnackbar(
              `User deactivated successfully`,
              SNACKBAR_VARIAINT.SUCCESS
            )
          );
        }
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};

/**
 * function to make server request to resend invitation
 * @param {*} userId: user id
 * @returns
 */
export const resendInvitationRequest = (userId) => {
  const data = {
    id: userId,
  };

  return (dispatch) => {
    dispatch(showLoader());
    return new HttpRequest(
      dispatch,
      `${API_ACTIONS.RESEND_INVITATION}`,
      'post',
      data
    )
      .send()
      .then((res) => {
        dispatch(
          showSnackbar(
            `Invitation sent successfully`,
            SNACKBAR_VARIAINT.SUCCESS
          )
        );
        dispatch(hideLoader());
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
      });
  };
};
