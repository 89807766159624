import {Box, Chip, Grid, Icon, Paper, Tooltip, Typography,} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {ProductList} from '../index';
import {useStyles} from './styles';

/**
 * function to render order card
 * @param {*} order: order
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @param {*} onCancelOrder: function to handle order cancel
 * @param {*} handleProductListClick: function to handle order product list click
 * @param {*} clickedProductList: clicked order product list
 * @returns
 */
const OrderCard = ({
                     order,
                     handleCardEditButtonClick,
                     onCancelOrder,
                     handleProductListClick,
                     clickedProductList,
                   }) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display="inline-block" pb={0.9}>
              <Typography classes={{root: classes.titleText}}>
                {I18n.t('orders.order_card.order_number_lable')}: {order?.orderNo}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{textAlign: 'right'}}
          >
            {(order?.status === 'Yet to confirm' ||
              order?.status === 'Confirmed') && (
              <Tooltip title={`Cancel Order`} arrow placement="top">
                <Icon
                  classes={{root: classes.actionIcon}}
                  onClick={() => onCancelOrder(order?.id, order?.orderNo)}
                >
                  cancel
                </Icon>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('orders.order_card.section_order_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.5}>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('orders.order_card.order_date_label')} -{' '}
                    {order?.orderDate
                      ? moment(order?.orderDate).format('DD/MM/YYYY')
                      : 'NA'}
                  </Typography>
                </Box>

                <Box pb={1.5}>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography classes={{root: classes.mainText}}>
                        {I18n.t('orders.order_card.order_status_label')} -{' '}
                        <Chip
                          label={order?.status}
                          size="small"
                          variant="outlined"
                          sx={{maxWidth: '110px'}}
                        />
                      </Typography>
                    </Box>

                    <Box>
                      <Tooltip
                        title={I18n.t(
                          'whiskey_tube.whiskey_tube_card.edit_tooltip'
                        )}
                        arrow
                        placement="top"
                      >
                        <Icon
                          classes={{root: classes.actionIcon}}
                          onClick={() =>
                            handleCardEditButtonClick(order?.id, 'orderSection')
                          }
                          fontSize="small"
                        >
                          edit
                        </Icon>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('orders.order_card.delevery_date_label')} -{' '}
                    {order?.dateOfDelivery
                      ? moment(order?.dateOfDelivery).format('DD/MM/YYYY')
                      : 'NA'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('orders.order_card.section_payment_lable')}
                </Typography>
              </Box>

              <Box>
                <Box display="flex" pb={1.5}>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.subTotal}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Base)
                    </Typography>
                  </Box>
                  <Box px={1}>
                    <Typography classes={{root: classes.mainText}}>
                      +
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.totalTax}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Tax %)
                    </Typography>
                  </Box>
                  <Box px={1}>
                    <Typography classes={{root: classes.mainText}}>
                      =
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.totalCost}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Total Cost)
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('orders.order_card.payment_mode_label')} -{' '}
                    <Chip
                      label={order?.paymentMode}
                      size="small"
                      variant="outlined"
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('orders.order_card.section_customer_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.7}>
                  <Typography classes={{root: classes.mainText}}>
                    {order?.userName} {`(${order?.phone})`}
                  </Typography>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {`${order?.userAddress?.address}, ${order?.userAddress?.city}, ${order?.userAddress?.state}, ${order?.userAddress?.pincode}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('orders.order_card.section_vendor_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.5}>
                  <Typography classes={{root: classes.mainText}}>
                    {order?.vendorName}
                  </Typography>
                </Box>

                <Box pb={1.5}>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography classes={{root: classes.mainText}}>
                        {I18n.t('orders.order_card.vendor_payment_made_label')} -{' '}
                        <Chip
                          label={order?.vendorPaymentMade ? 'Yes' : 'No'}
                          size="small"
                          variant="outlined"
                        />
                      </Typography>
                    </Box>

                    <Box>
                      <Tooltip
                        title={I18n.t(
                          'whiskey_tube.whiskey_tube_card.edit_tooltip'
                        )}
                        arrow
                        placement="top"
                      >
                        <Icon
                          classes={{root: classes.actionIcon}}
                          onClick={() =>
                            handleCardEditButtonClick(
                              order?.id,
                              'vendorSection'
                            )
                          }
                          fontSize="small"
                        >
                          edit
                        </Icon>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('orders.order_card.vendor_payment_date')} -{' '}
                    {order?.vendorPaymentDate
                      ? moment(order?.vendorPaymentDate).format('DD/MM/YYYY')
                      : 'NA'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} onClick={() => handleProductListClick(order?.id)}>
        <ProductList
          order={order}
          clickedProductList={clickedProductList}
        />
      </Box>
    </Paper>
  );
};

export default OrderCard;
