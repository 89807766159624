import axios from 'axios';
import {sessionExpired} from '../modules/Authentication/AuthenticationActions';
import Auth from './Auth';
import {hideLoader, showLoader, showSnackbar,} from './components/AppLoader/AppLoaderActions';
import {SNACKBAR_VARIAINT} from './Constants';

let cancel;

/**
 * Used to communicate with server
 */
class HttpRequest {
  /**
   *
   * @param {*} url Resource URL
   * @param {*} method HTTP method(GET | POST | PUT | PATCH | DELETE)
   * @param {*} headers HTTP request headers
   * @param {*} data HTTP request data (If applicable)
   */
  constructor(dispatch, url, method = 'get', data = {}, headers = {}, options) {
    this.url = url;
    this.method = method;
    this.data = data;
    this.headers = headers;
    this.options = options;
    this.dispatch = dispatch;
  }

  showHideLoader = (dispatch) => {
    let numberOfAjaxCAllPending = 0;
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        if (!config.isHideLoader) {
          numberOfAjaxCAllPending++;
          // show loader
          dispatch(showLoader());
        } else {
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        if (!response.config.isHideLoader) {
          numberOfAjaxCAllPending--;
          if (numberOfAjaxCAllPending === 0) {
            //hide loader
            dispatch(hideLoader());
          }
        } else {
        }
        return response;
      },
      function (error) {
        let originalRequest = Object.assign({}, error.config);
        if (
          originalRequest.isS3UploadRequest &&
          originalRequest.trialNumber < originalRequest.maxTrial
        ) {
          originalRequest.trialNumber = originalRequest.trialNumber + 1;
          originalRequest._retry = true;
          return axios(originalRequest);
        } else {
          dispatch(hideLoader());
          return Promise.reject(error);
        }
      }
    );
  };

  /**
   * Send http request to server to write data to / read data from server
   * axios library provides promise implementation to send request to server
   * Here we are using axios library for requesting a resource
   */
  send = (accessToken = null, isHideLoader = false) => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {...this.headers, 'Content-Type': 'application/json'};
    const token = accessToken === null ? Auth.getToken() : accessToken;

    if (token) {
      headers['access-token'] = `Bearer ${token}`;
    }

    return new Promise((resolve, reject) => {
      this.showHideLoader(this.dispatch);
      // Make server request using axios
      axios({
        baseURL: process.env.REACT_APP_SERVER_API_URL,
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: JSON.stringify(this.data),
        isHideLoader: isHideLoader,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // Users signature(JWT token) has been expired. Redirect user to Login page
          if (error.response === undefined) {
            // this.dispatch(
            //   showSnackbar(
            //     'Something went wrong. Please try again.',
            //     SNACKBAR_VARIAINT.ERROR
            //   )
            // );
          } else if (error.response.status === 401) {
            if (this.dispatch)
              this.dispatch(sessionExpired(error.response.data.error));
          } else if (error.response.status === 428) {
          } else {
            // let message = 'Something went wrong. Please try again.';
            // this.dispatch(
            //   showSnackbar(
            //     error.response && error.response.data.error
            //       ? error.response.data.error
            //       : message,
            //     SNACKBAR_VARIAINT.ERROR
            //   )
            // );
          }
          reject(error);
        });
    });
  };

  upload = (accessToken = null, isHideLoader = false) => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {...this.headers, 'Content-Type': 'multipart/form-data'};

    return new Promise((resolve, reject) => {
      this.showHideLoader(this.dispatch);
      // Make server request using axios
      axios({
        baseURL: process.env.REACT_APP_SERVER_API_URL,
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: this.data,
        isHideLoader: isHideLoader,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // Users signature(JWT token) has been expired. Redirect user to Login page
          if (error.response === undefined) {
            this.dispatch(
              showSnackbar(
                'Something went wrong. Please try again.',
                SNACKBAR_VARIAINT.ERROR
              )
            );
          } else if (error.response.status === 401) {
            if (this.dispatch)
              this.dispatch(sessionExpired(error.response.data.error));
          } else {
            let message = 'Something went wrong. Please try again.';
            this.dispatch(
              showSnackbar(
                error.response && error.response.data.error
                  ? error.response.data.error
                  : message,
                SNACKBAR_VARIAINT.ERROR
              )
            );
          }
          reject(error);
        });
    });
  };

  download = (accessToken = null, isHideLoader = false) => {
    let headers = {...this.headers, 'Content-Type': 'application/json'};
    const token = accessToken === null ? Auth.getToken() : accessToken;

    if (token) {
      headers['access-token'] = `Bearer ${token}`;
    }

    return new Promise((resolve, reject) => {
      axios({
        baseURL: process.env.REACT_APP_SERVER_API_URL,
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: JSON.stringify(this.data),
        isHideLoader: isHideLoader,
        responseType: 'blob',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  s3Upload = (
    accessToken = null,
    isHideLoader = false,
    uploadProgress = undefined,
    contentType = undefined
  ) => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {
      ...this.headers,
      'Content-Type': contentType ? contentType : 'multipart/form-data',
    };
    const CancelToken = axios.CancelToken;
    return new Promise((resolve, reject) => {
      this.showHideLoader(this.dispatch);
      // Make server request using axios
      axios({
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: this.data,
        isHideLoader: isHideLoader,
        isS3UploadRequest: true,
        trialNumber: 1,
        maxTrial: parseInt(
          process.env.REACT_APP_MAX_NUMBER_OF_TRIAL_S3_UPLOAD_REQUEST
        ),
        onUploadProgress: function (progressEvent) {
          if (uploadProgress) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progressEvent.percentCompleted = percentCompleted;
            uploadProgress(progressEvent);
          }
        },
        cancelToken: new CancelToken(function (c) {
          cancel = c;
        }),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response === undefined) {
            // this.dispatch(showSnackbar("Something went wrong. Please try again.", SNACKBAR_VARIAINT.ERROR));
          } else if (error.response.status === 401) {
            if (this.dispatch)
              this.dispatch(sessionExpired(error.response.data.error));
          } else if (error.response.status === 428) {
          } else {
            let message = 'Something went wrong. Please try again.';
            this.dispatch(
              showSnackbar(
                error.response && error.response.data.error
                  ? error.response.data.error
                  : message,
                SNACKBAR_VARIAINT.ERROR
              )
            );
          }
          if (axios.isCancel(error)) {
            error = {
              status: 499,
            };
          } else {
          }
          reject(error);
        });
    });
  };
}

export default HttpRequest;

export function cancelAxiosMethod() {
  return function (dispatch) {
    if (cancel !== undefined) {
      cancel();
    }

    return true;
  };
}
