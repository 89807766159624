import {Box, Chip, Grid, Paper, Tooltip, Typography,} from '@mui/material';
// import { ProductList } from '../index';
import moment from 'moment';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import UtilHelper from '../../../../utils/UtilHelper';
import {useStyles} from './styles';

/**
 * function to render order card
 * @param {*} order: order
 * @param {*} handleCardEditButtonClick: function to handle edit click
 * @returns
 */
const OrderCard = ({
                     order,
                   }) => {
  const classes = useStyles();

  return (
    <Paper component={Box} p={2} mt={2}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box display="inline-block" pb={0.9}>
              <Typography classes={{root: classes.titleText}}>
                {I18n.t('event_bookings.order_card.order_number_lable')}:{' '}
                {order?.orderNo}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{textAlign: 'right'}}
          />
        </Grid>
      </Box>

      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('event_bookings.order_card.section_order_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.5}>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('event_bookings.order_card.order_date_label')} -{' '}
                    {order?.bookedDate
                      ? moment(order?.bookedDate).format('DD/MM/YYYY')
                      : 'NA'}
                  </Typography>
                </Box>

                <Box pb={1.5}>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography classes={{root: classes.mainText}}>
                        {I18n.t('event_bookings.order_card.order_status_label')}{' '}
                        -{' '}
                        <Tooltip title={order?.status}>
                          <Chip
                            label={order?.status}
                            size="small"
                            variant="outlined"
                            sx={{maxWidth: '110px'}}
                          />
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('event_bookings.order_card.no_of_tickets_label')} -{' '}
                    {order?.bookedTicket || '0'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('event_bookings.order_card.section_payment_lable')}
                </Typography>
              </Box>

              <Box>
                <Box display="flex" pb={1.5}>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.subTotal}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Base)
                    </Typography>
                  </Box>
                  <Box px={1}>
                    <Typography classes={{root: classes.mainText}}>
                      +
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.totalTax}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Tax %)
                    </Typography>
                  </Box>
                  <Box px={1}>
                    <Typography classes={{root: classes.mainText}}>
                      =
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography classes={{root: classes.mainText}}>
                      {order?.grandTotal}
                    </Typography>
                    <Typography classes={{root: classes.costSubText}}>
                      (Total Cost)
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {I18n.t('event_bookings.order_card.payment_mode_label')} -{' '}
                    <Tooltip title={order?.paymentMode || ''}>
                      <Chip
                        label={order?.paymentMode}
                        size="small"
                        variant="outlined"
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('event_bookings.order_card.section_customer_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.7}>
                  <Typography classes={{root: classes.mainText}}>
                    {order?.user?.name} {`(${order?.user?.phone})`}
                  </Typography>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}/>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box>
              <Box borderBottom="1px solid #6c6c6c" pb={1} mb={1}>
                <Typography classes={{root: classes.labelText}}>
                  {I18n.t('event_bookings.order_card.section_vendor_lable')}
                </Typography>
              </Box>

              <Box>
                <Box pb={1.5}>
                  <Typography classes={{root: classes.mainText}}>
                    {order?.vendorName || ''}
                  </Typography>
                </Box>

                <Box pb={1.5}>
                  <Typography classes={{root: classes.mainText}}>
                    {UtilHelper.ellipsisRender(order?.name, 35)}
                  </Typography>
                </Box>

                <Box>
                  <Typography classes={{root: classes.mainText}}>
                    {moment(order?.date).format('DD/MM/YYYY')},{` `}
                    {moment(order?.startTime).format('hh:mm a')} - {` `}
                    {moment(order?.endTime).format('hh:mm a')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default OrderCard;
