import palette from '../palette';

const MuiInputAdornment = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
      fontFamily: 'Lato',
    },
  },
};

export default MuiInputAdornment;
